import { NgModule } from "@angular/core";
import { EnteroPositivoDirective } from "./entero-positivo.directive";
import { InfiniteScrollDirective } from "./infinite-scroll";
import { DatepickerDirective } from "./datepicker.directives";
import { RangedatepickerDirective } from "./rangedatepicker";
import { NumeroDecimalDirective } from "./numero_decimal.directiva";
import { ClockpickerDirective } from "./clockpicker.directives";
import { RolesShowDirective } from './roles-show.directive';
import { RolesHideDirective } from './roles-hide.directive';
import { Select2Directive } from "./select2.directive";
import { Select2AsyncDirective } from './select2-async.directive';
import { Select2MultiDirective } from './select2-multi.directive';
import { Select2AsyncV2Directive } from './select2-async-v2.directive';
import { showColumnBienDirective } from './show-column-bien.directive';
@NgModule({
    declarations: [
        EnteroPositivoDirective,
        NumeroDecimalDirective,
        InfiniteScrollDirective,
        DatepickerDirective,
        ClockpickerDirective,
        RangedatepickerDirective,
        RolesShowDirective,
        RolesHideDirective,
        Select2Directive,
        Select2AsyncDirective,
        Select2MultiDirective,
        Select2AsyncV2Directive,
        showColumnBienDirective
    ],
    imports: [

    ],
    exports: [
        EnteroPositivoDirective,
        NumeroDecimalDirective,
        InfiniteScrollDirective,
        DatepickerDirective,
        ClockpickerDirective,
        RangedatepickerDirective,
        RolesShowDirective,
        RolesHideDirective,
        Select2Directive,
        Select2AsyncDirective,
        Select2MultiDirective,
        Select2AsyncV2Directive,
        showColumnBienDirective
    ],
    providers: [
    ]
})

export class DirectivasModule { }
