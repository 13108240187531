export class ExpedienteConclucion {
    public static conclucionesAnalistaJefe: Opinion[] = [
        { id: "true", text: 'FAVORABLE' },
        { id: "false", text: 'NO FAVORABLE' },
    ];
    public static conclucionesJefeComite: Opinion[] = [
        { id: "true", text: 'APROBADO' },
        { id: "false", text: 'DESAPROBADO' },
    ];
    public static conclucionesJefeAdmision: Opinion[] = [
        { id: "true", text: 'FAVORABLE' },
        { id: "false", text: 'NO FAVORABLE' },
    ];

    public static conclucionAnalistaJefe(id: string): string {
        let conclucion: Opinion = this.conclucionesAnalistaJefe.find(value => value.id === id);
        if (conclucion) {
            return this.conclucionesAnalistaJefe.find(value => value.id === id).text;
        }
        return null;

    }
    public static conclucionJefeComite(id: string): string {
        let conclucion: Opinion = this.conclucionesJefeComite.find(value => value.id === id);
        if (conclucion) {
            return this.conclucionesJefeComite.find(value => value.id === id).text;
        }
        return null;
    }
    public static conclucionJefeAdmision(id: string): string {
        let conclucion: Opinion = this.conclucionesJefeAdmision.find(value => value.id === id);
        if (conclucion) {
            return this.conclucionesJefeAdmision.find(value => value.id === id).text;
        }
        return null;
    }
}
class Opinion {
    id: string;
    text: string;
}

