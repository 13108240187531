import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectDefaultModule } from '@common/selects/select-default/select-default.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonExpedienteModalOpinionComponent } from './expediente-modal-opinion.component';
@NgModule({
    declarations: [
        CommonExpedienteModalOpinionComponent
    ],
    imports: [
        CommonModule,
        SelectDefaultModule,
        ReactiveFormsModule
    ],
    exports: [
        CommonExpedienteModalOpinionComponent

    ]
})

export class CommonExpedienteModalOpinionModule { }
