import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVICIOS_OPERACIONES } from 'config/services/operaciones';
import { RUTAS_OPERACIONES } from '@routes/operaciones';

@Injectable({
  providedIn: 'root'
})

export class OrdenComprasService {

  constructor(
    private http: HttpClient
  ) {

  }

  listarOrdenCompras(expedienteId: number, data: {
    filtro_columna: string,
    filtro_tipo: string,
    filtro_valor: string,
    cabecera: boolean,
    pagina: number
    numfilas: number,
  }) {
    const params = `?filtro_columna=${data.filtro_columna}&filtro_tipo=${data.filtro_tipo}&filtro_valor=${data.filtro_valor}&numfilas=${data.numfilas}&pagina=${data.pagina}`;
    return this.http.get<any>(SERVICIOS_OPERACIONES.expediente.ordenCompras.listar(expedienteId) + params)
  }
  vistaConstanciaPdf(ordenesComprasId: number) {
    return this.http.get<any>(SERVICIOS_OPERACIONES.expediente.ordenCompras.vistaConstancia(ordenesComprasId))
  }
  ordenCompra(id: number, data) {
    return this.http.post(SERVICIOS_OPERACIONES.expediente.ordenCompras.oc(id), data)
  }
  verDocProveedor(id: number) {
    return this.http.get(SERVICIOS_OPERACIONES.expediente.ordenCompras.docProveedor(id))
  }
  verFactura(id: number) {
    return this.http.get(SERVICIOS_OPERACIONES.expediente.ordenCompras.factura(id))
  }
  guardarFactura(id: number, data) {
    return this.http.post(SERVICIOS_OPERACIONES.expediente.ordenCompras.factura(id), data)
  }
  vistaOrdenCompraPdf(ordenesComprasId: number) {
    return this.http.get<any>(SERVICIOS_OPERACIONES.expediente.ordenCompras.vistaOrdenCompra(ordenesComprasId))
  }
  enviarCorreo(ordenesComprasId: number, data: {
    correo: string
  }) {
    return this.http.post(SERVICIOS_OPERACIONES.expediente.ordenCompras.enviarCorreo(ordenesComprasId), data)
  }
  enviarCorreoGPS(ordenesComprasId: number, data: {
    correo: string
  }) {
    return this.http.post(SERVICIOS_OPERACIONES.expediente.ordenCompras.enviarCorreoGPS(ordenesComprasId), data)
  }
  agregarCartaAdelanto(expedienteId: number, data) {
    return this.http.post(SERVICIOS_OPERACIONES.expediente.ordenCompras.agregarCarta(expedienteId), data)
  }
  getCartaAdelanto(expedienteId: number) {
    return this.http.get(SERVICIOS_OPERACIONES.expediente.ordenCompras.getCartaAdelanto(expedienteId))
  }
}
