import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    OnChanges,
    SimpleChanges
} from '@angular/core';

declare var $: any;

@Directive({
    selector: '[select2Multi]'
})
export class Select2MultiDirective implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Output() selectChange = new EventEmitter();
    @Output() unselect = new EventEmitter();
    @Input() search: boolean;
    @Input() placeholder: any;
    @Input() clear: boolean;
    @Input() selected: Array<any>;
    @Input() parent: any;
    constructor(private el: ElementRef) {
        this.search = false;
        this.placeholder = "";
        this.clear = false;
        this.selected = [];
    }

    ngOnInit() { }
    ngAfterViewInit() {
        // setTimeout(() => {
        this.init();
        // }, 100);
    }
    ngOnChanges(simple: SimpleChanges) {
        if (simple.selected && simple.selected.currentValue) {
            this.init();
        }
    }
    ngOnDestroy() {
        $(this.el.nativeElement).select2('destroy');
    }
    private init() {
        $(this.el.nativeElement).select2({
            dropdownParent: $(`#${this.parent}`),
            theme: 'bootstrap4 select2-multi-custom',
            language: "es",
            width: '100%',
            multiple: true,
            placeholder: this.placeholder,
            allowClear: this.clear,
            templateResult: this.templateResult,
            templateSelection: this.templateSelection,
            closeOnSelect: false,
        })
            .on('select2:select', (event) => {
                let selected = $(this.el.nativeElement).select2('data');
                let selectedOption = `.select2-results__option[aria-selected=true] input[type=checkbox]`;
                $(this.el.nativeElement).parents(`#${this.parent}`).find(selectedOption).prop('checked', true);
                this.selectChange.emit(selected);
            })
            .on('select2:unselect', (event) => {
                let selected = $(this.el.nativeElement).select2('data');
                let selectedOption = `.select2-results__option[aria-selected=false] input[type=checkbox]`;
                $(this.el.nativeElement).parents(`#${this.parent}`).find(selectedOption).prop('checked', false);
                this.unselect.emit(selected);
            });
        this._selected();
    }
    private _selected() {
        if (
            this.selected.length > 0 &&
            this.selected !== null &&
            this.selected !== undefined
        ) {
            $(this.el.nativeElement).val(this.selected).trigger('change');
        }
    }
    private templateResult(state) {
        if (!state.id) {
            return state.text;
        }
        let $state = $(`
            <label class="container-checkbox">
                <input ${state.selected ? 'checked' : ''}  onclick="event.preventDefault();" type="checkbox" >
                <div class="checkmark"></div>
                <span class="ml-2">${state.text}</span>
            </label>
        `);
        return $state;
    }
    templateSelection(state) {
        if (!state.id) {
            return state.text;
        }


        return "";
    }
}
