import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ExpedienteHistorialDocumentosService } from './expediente-historial-documentos.service';
import { ExpedienteConclucion } from '@util/expediente-conclucion';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CondicionesService } from '../../../services/riesgos/condiciones/condiciones.service';
import { CondicionEvaluacion } from '@viewRiesgosAdmision/evaluacion/condicion-evaluacion.model';

@Component({
    selector: 'common-expediente-historial-documentos',
    templateUrl: 'expediente-historial-documentos.template.html',
    styleUrls: ['./expediente-historial-documentos.scss'],
})
export class ExpedienteHistorialComponent implements OnInit, OnChanges, OnDestroy {
    @Input() idExpediente: number;
    @Input() idTipoDocumento: number;
    @Input() showCondiciones: boolean = false;
    @Input() estadoActual: string;
    @Output() enviarMoneda = new EventEmitter();

    public datosBien: Array<any>;
    public comentariosBien: Array<any>;
    public comentarios: Array<any>;
    // public datosBienComentario: boolean;
    public historial: Array<any>;
    public moneda: string;
    public monedaOrigen: string;
    public condicionesArrend: CondicionesArrend;

    private unsubscribe = new Subject();
    public showModalCondiciones: boolean;
    public leasing: boolean;
    public condicionesEvaluacion: CondicionEvaluacion;
    public corridaGenerada: boolean;
    public subscription: Subscription;

    constructor(private historialService: ExpedienteHistorialDocumentosService, private condicionesService: CondicionesService) {
        this.condicionesArrend = {
            cantidad_garantia_comercial: 0,
            comentario_fianza: '',
            con_fianza_solidaria: false,
            cuota_inicial: 0,
            cuota_mensual: 0,
            garantias: [],
            idcotizacion: 0,
            incluye_garantia: false,
            moneda_simbolo: '',
            periodo_gracia: 0,
            periodo_latencia: 0,
            plazo: 0,
            porcentaje_inicial: 0,
            tea: 0,
            valor_bruto_total: 0,
            valor_financiar: 0,
        };
        this.estadoActual = '';
        this.idExpediente = 0;
        this.idTipoDocumento = 0;
        this.datosBien = [];
        this.comentariosBien = [];
        this.comentarios = [];
        this.historial = [];
        this.moneda = '';
        this.monedaOrigen = '';
        this.showModalCondiciones = false;
        this.leasing = false;
        this.condicionesEvaluacion = new CondicionEvaluacion({});
        this.corridaGenerada = false;
    }

    ngOnInit() {
        this.historialService.actualizarData.pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
            this.listar();
        });
        this.listar();
        this.listarCondiciones();
        this.subscription = this.condicionesService.refresh$.subscribe(() => {
            this.listarCondiciones();
        });
    }
    ngOnChanges(simple: SimpleChanges) {
        if (simple.idTipoDocumento && simple.idTipoDocumento.previousValue != undefined) {
            this.historialDocumentos(simple.idTipoDocumento.currentValue);
        }
    }
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.subscription.unsubscribe();
    }
    listar() {
        this.historialService
            .historial(this.idExpediente)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (response: any) => {
                    this.moneda = response.moneda;
                    this.monedaOrigen = response.monedaOrigen;
                    this.enviarMoneda.emit(this.monedaOrigen);
                    let datosBien = [];
                    this.leasing = true;
                    response.data.forEach((bien, index) => {
                        let exiteComentario = false;
                        bien.operaciones.forEach((operacion, indexOperacion) => {
                            exiteComentario = JSON.stringify(operacion.comentarios) === '{}' ? false : true;
                            if (operacion.comentarios.analista) {
                                response.data[index].operaciones[indexOperacion].comentarios.analista.validez_text =
                                    ExpedienteConclucion.conclucionAnalistaJefe(operacion.comentarios.analista.validez + '');
                            }
                            if (operacion.comentarios.jefe) {
                                response.data[index].operaciones[indexOperacion].comentarios.jefe.validez_text =
                                    ExpedienteConclucion.conclucionAnalistaJefe(operacion.comentarios.jefe.validez + '');
                            }

                            if (operacion.comentarios.comite) {
                                response.data[index].operaciones[indexOperacion].comentarios.comite.validez_text =
                                    ExpedienteConclucion.conclucionJefeComite(operacion.comentarios.comite.validez + '');
                            }
                        });

                        if (exiteComentario) {
                            this.comentariosBien.push(response.data[index]);
                        }
                    });
                    this.datosBien = response.data;
                    this.comentarios = response.comentario_estado;
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    historialDocumentos(idTipoDocumento: number) {
        this.historialService
            .historialDocumentos(this.idExpediente, idTipoDocumento)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (response) => {
                    this.historial = response.data;
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    listarCondiciones() {
        this.condicionesService.getCondicionesAnalista(this.idExpediente).subscribe(
            (response) => {
                this.condicionesArrend = response['propuesta_condiciones'];
                this.condicionesEvaluacion = new CondicionEvaluacion({
                    ...this.condicionesArrend,
                    id: this.condicionesArrend.idcotizacion,
                    moneda_simbolo: this.condicionesArrend.moneda_simbolo,
                });
            },
            (error) => {
                console.log(error);
            }
        );
    }
}

interface CondicionesArrend {
    cantidad_garantia_comercial: number;
    comentario_fianza: string;
    con_fianza_solidaria: boolean;
    cuota_inicial: number;
    cuota_mensual: number;
    garantias: Garantias[];
    idcotizacion: number;
    incluye_garantia: boolean;
    moneda_simbolo: string;
    periodo_gracia: number;
    periodo_latencia: number;
    plazo: number;
    porcentaje_inicial: number;
    tea: number;
    valor_bruto_total: number;
    valor_financiar: number;
}

interface Garantias {
    descripcion: string;
    id_garantia: number;
    id_propuesta: number;
    id_tipo_garantia: number;
}
