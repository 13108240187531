import { API_URL } from './comun';
import { environment } from '@environments/environment';
export const API_URL_PROVISIONAL: any = environment.API_URL + 'api/v1.0/';

export const SERVICIO_OPERACIONES = {
    expedientes: {
        enviarOperaicones: (expedienteId: number) => {
            return `${API_URL_PROVISIONAL}expediente/${expedienteId}/operaciones/enviar`;
        },
        historialCronograma: (expedienteId: number) => {
            return `${API_URL_PROVISIONAL}expediente/${expedienteId}/historial/cronogramas`;
        },
        ultimaCorrida: {
            ver: (expedienteId: number) => {
                return `${API_URL}expediente/${expedienteId}/ultima-corrida`;
            },
            perView: (expedienteId: number) => {
                return `${API_URL}expediente/${expedienteId}/ultima-corrida/preview`;
            },
            guardar: (expedienteId: number) => {
                return `${API_URL}expediente/${expedienteId}/ultima-corrida/guardar`;
            },
            guardarAprobados: (expedienteId: number) => {
                return `${API_URL}expediente/${expedienteId}/aprobado/editar`;
            },
            detalleDeGastos: (expedienteId: number) => {
                return `${API_URL}expediente/${expedienteId}/ultima-corrida/detalle-gastos`;
            },
        },
        checklist: {
            listar: (expedienteId: number) => {
                return `expediente/${expedienteId}/checklist`;
            },
            listarArchivos: (idExpediente: number, idDocumento: number, idSeccion: number, idSubseccion: number) => {
                return `expediente/${idExpediente}/documento/${idDocumento}/seccion/${idSeccion}/subseccion/${idSubseccion}/archivos`;
            },
            subirArchivos: `expediente/documento/checklist/`,
            historialArchivos: `expediente/historial/checklist/`,
            cheked: `expediente/checked/`,
            contactos: (idExpediente: number) => {
                return `riesgos-formularios/expediente/${idExpediente}/contactos`;
            },
            enviarSolicitudLT: (idExpediente: number): string => {
                return `riesgos-formularios/expediente/${idExpediente}/enviar`
            },
            enviarformularioLT: (idExpediente: number): string => {
                return `riesgos-formularios/expediente/${idExpediente}/formularios`
            }
        },
        // checklist: {
        //     listar: (expedienteId: number) => {
        //         return `expediente/${expedienteId}/checklist`;
        //     },
        //     listarArchivos: (idExpediente: number, idDocumento: number, idSeccion: number, idSubseccion: number) => {
        //         return `expediente/${idExpediente}/documento/${idDocumento}/seccion/${idSeccion}/subseccion/${idSubseccion}/archivos`;
        //     },
        //     subirArchivos: `expediente/documento/checklist/`,
        //     historialArchivos: `expediente/historial/checklist/`,
        //     cheked: `expediente/checked/`,
        // }
    }
}
