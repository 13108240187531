import { API_URL } from "./comun";

export const SERVICIOS_POLIZAS = {
  seguro: {
    listarPolizas: `operaciones/polizas-seguros`,
    listarDocumentos: {
      listar: (polizaSeguroId: number) => {
        return `operaciones/polizas-seguros/${polizaSeguroId}/archivos`;
      },
      guardarDocumento: (polizaSeguroId: number) => {
        return `operaciones/polizas-seguros/${polizaSeguroId}/archivos`;
      },
      generarCarta: {
        comboDireccionEntrega: (polizaSeguroId: number) => {
          return `operaciones/polizas-seguros/${polizaSeguroId}/archivos`;
        },
        preview: (polizaSeguroId: number) => {
          return `operaciones/polizas-seguros/${polizaSeguroId}/cartas-despachos/preview`;
        },
        guardarCarta: (polizaSeguroId: number) => {
          return `operaciones/polizas-seguros/${polizaSeguroId}/cartas-despachos`;
        },
      },
    },
    polizas: {
      nuevaPoliza: (cafId: number) => {
        return `operaciones/caf-operaciones/${cafId}/polizas-seguros/data`;
      },
      comboCAfs: `operaciones/caf-operacion/dropdown`,
      comboAseguradoras: `operaciones/aseguradoras/combo`,
      calcularPrima: `operaciones/polizas-seguros/calcular-prima`,
      guardar: `operaciones/polizas-seguros`,
    },
    anularPoliza: {
      anular: (polizaSeguroId: number) => {
        return `operaciones/polizas-seguros/${polizaSeguroId}/anular`;
      },
    },
    cuponesExtraordinarios: {
      dataParaNuevo: (polizaSeguroId: number) => {
        return `operaciones/polizas-seguros/${polizaSeguroId}/cupones-extraordinarios`;
      },
      agregarCupon: (polizaSeguroId: number) => {
        return `operaciones/polizas-seguros/${polizaSeguroId}/cupones`;
      },
    },
  },
  soat: {
    listar: `operaciones/soat`,
    generarSoat: {
      comboCafs: `operaciones/caf-operaciones/vehiculares`,
      dataNuevo: (cafOperacionId: number) => {
        return `operaciones/caf-operaciones/${cafOperacionId}/soat/nuevo`;
      },
      comboAseguradoras: `operaciones/aseguradoras/combo`,
      guardar: `operaciones/soat`,
    },
    archivos: {
      listar: (soatId: number) => {
        return `operaciones/soat/${soatId}/archivos`;
      },
      guardar: (soatId: number) => {
        return `operaciones/soat/${soatId}/archivos`;
      },
      eliminar: (soatId: number, archivoId) => {
        return `operaciones/soat/${soatId}/archivos/${archivoId}`;
      },
    },
  },
  solicitudesPoliza: {
    listarBrokers: `operaciones/solicitudes-polizas`,
    aprobar: (solicitudPolizaId: number) => {
      return `operaciones/solicitudes-polizas/${solicitudPolizaId}/aprobar`;
    },
    tramitar: (solicitudPolizaId: number) => {
      return `operaciones/solicitudes-polizas/${solicitudPolizaId}/tramitar`;
    },
    comboAseguradoras: `operaciones/aseguradoras/combo`,
    enviarCorreo: (solicitudPolizaId: number) => {
      return `operaciones/solicitudes-polizas/${solicitudPolizaId}/enviar`;
    },
    archivos: {
      listarArchivos: (solicitudPolizaId: number) => {
        return `operaciones/solicitudes-polizas/${solicitudPolizaId}/archivos`;
      },
      eliminar: (solicitudPolizaId: number, archivoId: number) => {
        return `operaciones/solicitudes-polizas/${solicitudPolizaId}/archivos/${archivoId}`;
      },
      guardarDocumento: (solicitudPolizaId: number) => {
        return `operaciones/solicitudes-polizas/${solicitudPolizaId}/archivos`;
      },
    },
    polizas: {
      nuevaPoliza: (solicitudPolizaId: number) => {
        return `operaciones/solicitudes-polizas/${solicitudPolizaId}/polizas-seguros`;
      },
      calcularPrima: `operaciones/polizas-seguros/calcular-prima`,
      guardar: `operaciones/polizas-seguros`,
    },
  },
  solicitudesReembolso: {
    listar: `operaciones/reembolsos`,
    recepcionarReembolso: (solicitudesReembolsoId: number) => {
      return `operaciones/reembolsos/${solicitudesReembolsoId}/recepcionar`;
    },

  },
};
