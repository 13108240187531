import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { ComercialExpedienteCheckListService } from '@serviceComercial/operaciones/expediente-checklist.service';
import { MensajeResponse } from '@util/mensaje-response';

declare var $: any;

@Component({
    selector: 'checklist-agregar-document-component',
    templateUrl: 'agregar-documentos.template.html',
    providers: [

    ]
})
export class AgregarDocumentoComponent implements OnInit, OnDestroy, AfterViewInit {
    public loadingSubirArchivo: boolean;
    public tipoDocumentos: Array<any>;
    @ViewChild('file', { static: true }) file: ElementRef
    @ViewChild('modalCargarArcgivo', { static: true }) modalCargarArcgivo: ElementRef
    public dataFile: Array<any>;
    public startDate = moment().format('YYYY-MM-DD');
    public selectItem: any;
    public titulo: string;
    public expedienteId: number;
    public maxDate: Date = new Date(moment().format('MM-DD-YYYY'));
    public save: boolean;
    @Output() saved = new EventEmitter();
    constructor(
        private expedienteCheckListService: ComercialExpedienteCheckListService,
        private mensajeResponse: MensajeResponse
    ) {
        this.loadingSubirArchivo = false;
        this.dataFile = [];
        this.tipoDocumentos = [];
        this.selectItem = {};
        this.titulo = "";
        this.expedienteId = 0;
    }
    ngOnInit() {
        // $('[data-toggle="tooltip"]').tooltip();
    }

    ngOnDestroy() {
        $('[data-toggle="tooltip"]').tooltip('dispose');
    }
    ngAfterViewInit() {
        $('[data-toggle="tooltip"]').tooltip();
    }
    init(expedienteId: number, data: any, save = true) {
        this.selectItem = data;
        this.titulo = data.documento.nombre_documento;
        this.expedienteId = expedienteId;
        this.save = save;
        $(this.modalCargarArcgivo.nativeElement).modal("show");

    }
    fileChangeEvent(fileInput: any) {

        let fileToUpload = <Array<File>>fileInput.target.files;
        for (let index = 0; index < fileToUpload.length; index++) {
            this.dataFile.push({
                nombre: fileToUpload[index].name,
                fecha_documento: this.startDate,
                arch: fileToUpload[index]
            });
        }
        this.file.nativeElement.value = "";
        setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
        }, 300);
    }
    eliminarArchivo(index) {
        this.dataFile.splice(index, 1)
    }
    selectedTipoDocumentos(index, event) {
        this.dataFile[index].tipo = event.id;
    }
    selectedFechaDocumento(index, event) {
        this.dataFile[index].fecha = moment(event).format('YYYY-MM-DD')
    }
    closeCargaArchivo() {
        this.dataFile = [];
        $(this.modalCargarArcgivo.nativeElement).modal("hide");
    }
    cargaArchivo() {
        this.mensajeResponse.action('¿Estás seguro de guardar los archivos?').subscribe(result => {
            if (result) {
                this.loadingSubirArchivo = true;
                let formData: FormData = new FormData();
                formData.append('idexpediente', String(this.expedienteId));
                formData.append('iddocumento', this.selectItem.documento.iddocumento);
                this.dataFile.forEach((value, index) => {
                    let arch = "file[" + index + "][arch]";
                    let fecha = "file[" + index + "][fecha_documento]";
                    let nombre = "file[" + index + "][nombre_documento]";
                    formData.append(arch, value.arch);
                    formData.append(nombre, this.dataFile[index].nombre);
                    formData.append(fecha, this.dataFile[index].fecha_documento);
                });
                if (this.save) {
                    this.expedienteCheckListService.uploadFile(formData)
                        .then((response: any) => {
                            this.loadingSubirArchivo = false;
                            this.dataFile = [];
                            $(this.modalCargarArcgivo.nativeElement).modal("hide");
                            this.file.nativeElement.value = "";
                            this.saved.emit();

                        }, error => {
                            this.loadingSubirArchivo = false;
                        });
                }
                else {
                    this.loadingSubirArchivo = false;
                    this.dataFile = [];
                    this.file.nativeElement.value = "";
                    $(this.modalCargarArcgivo.nativeElement).modal("hide");
                    this.saved.emit(formData);
                }
            }
        });
    }
    validarBtnAceptar() {
        let val = true;
        this.dataFile.forEach((value, index) => {
            if (value.fecha == "") {
                val = false;
            }
        });
        if (this.dataFile.length == 0) {
            val = false;
        }
        return val;
    }
}
