import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { of, Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, tap, switchMap } from 'rxjs/operators';
// import { Observable } from 'rxjs/Observable';
import { LoginService_v3 } from '../app/views/login/login.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public dataUser: any;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private loginService: LoginService_v3,
    private router: Router
  ) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isRefreshing) {
      request = this.addToken(request, this.loginService.getRefreshToken());
    }
    if (!this.isRefreshing && this.loginService.getAccessToken()) {
      request = this.addToken(request, this.loginService.getAccessToken());
    }
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && this.isRefreshing) {
        localStorage.removeItem('data_user');
        this.router.navigate(['/login']);
        return throwError(error);
      } if (error instanceof HttpErrorResponse && error.status === 502) {
        let err = { error: { login_attempts: 502 } }
        return throwError(err);
      } else
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // return this.handle401Error(request, next);
          let data = this.loginService.getdataUser();
          this.loginService.logout().subscribe(
            (response) => {
              this.loginService.dataUser(data);
            }
          );
          return throwError(error);
        } else {
          return throwError(error);
        }
    }));
    // if (this.loginService.getdataUser() !== null) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: 'Bearer ' + atob(atob(atob(this.loginService.getdataUser().access_token)))
    //     }
    //   });
    // }
    // return next.handle(request);
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${this.loginService.getToken()}`,
        'Token-Oauth': `Bearer ${token}`,
        'Token-Cobranza': `Bearer ${this.loginService.getCobranza()}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.loginService.refreshToken().pipe(
        tap((tokens: any) => {
          let data = this.loginService.getdataUser();
          const token = this.loginService.getToken();
          data.access_token = token;
          data.oauth_access_token = tokens.access_token;
          data.oauth_refresh_token = tokens.refresh_token;
          this.loginService.logout().subscribe(
            (response) => {
              this.loginService.dataUser(data);
            }
          );
        }),
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access_token);
          return next.handle(this.addToken(request, token.access_token));
        })
      );

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
