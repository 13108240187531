import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ModulosService } from 'app/views/modulos/modulos.service';
import { LoginService, LoginService_v3 } from 'app/views/login/login.service';
import { NavigationService } from './navigation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { API_ARCHIVOS } from 'config/services';
import { MensajeResponse } from '../../../util/mensaje-response';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
    selector: 'navigation',
    templateUrl: 'navigation.template.html',
    styleUrls: ['./navigation.scss'],
    providers: [ModulosService, LoginService],
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
    public lista: any;
    public direction: any;
    public href: any;
    public ulActivo: any;
    public rutaPrincipal: string;
    public rutaSecundaria: string;
    public intervalHolder: any;
    public cotizador = '/comercial/cotizacion/cotizador';
    public cotizaciones = '/comercial/cotizacion/cotizaciones';
    public hrefActivo: any;
    public url: any;
    public el: any;
    public isOpen: boolean;
    public isMovil: boolean;
    public ltsclose: any;
    @ViewChild('ElMenu', { static: true }) ElMenu: ElementRef;
    @ViewChild('ElSubMenu', { static: true }) ElSubMenu: ElementRef;
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private router: Router,
        private _modulosService: ModulosService,
        private _loginService: LoginService,
        private loginService: LoginService_v3,
        private navigationService: NavigationService,
        private bnIdle: BnNgIdleService,
        private mensajeResponse: MensajeResponse
    ) {
        this.lista = {};
        this.rutaPrincipal = '';
        this.rutaSecundaria = '';
        this.isOpen = false;
        this.router.events.subscribe((d: any) => {
            if (d.url) {
                let arrayRuta = d.url.split('/');
                this.rutaPrincipal = arrayRuta[2];
                if (arrayRuta.length > 3) {
                    if (this.isMovil === true) {
                        this.rutaPrincipal = '';
                        this.navigationService.toggleMenu();
                    }
                    this.rutaSecundaria = arrayRuta[3];
                } else {
                    this.rutaSecundaria = '';
                }
            }
        });
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (event.target.innerWidth < 960) {
            this.isMovil = true;
            if (this.isOpen) {
                this.navigationService.toggleMenu();
            }
        } else if (event.target.innerWidth >= 960) {
            this.isMovil = false;
            if (!this.isOpen) {
                this.navigationService.toggleMenu();
            }
        }
    }
    ngOnDestroy() {
        this.navigationService.toggleMenu();
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    cambiarPrincipal(ruta: string) {
        if (ruta === this.rutaPrincipal) {
            this.rutaPrincipal = '';
        } else {
            this.rutaPrincipal = ruta;
        }
    }

    initOption() {
        if (window.innerWidth < 960) {
            this.isMovil = true;
            if (this.isOpen) {
                this.navigationService.toggleMenu();
            }
        } else if (window.innerWidth >= 960) {
            this.isMovil = false;
            if (this.isOpen === false) {
                this.navigationService.toggleMenu();
            }
        }
    }
    ngAfterViewInit() {
        this.listMenu();
    }

    ngOnInit() {
        let user = this.loginService.getdataUser();
        this.ltsclose = {
            nombre: user.nombres,
            cargo: user.cargo,
            modulos: 'Módulos',
            perferencias: 'Preferencias',
            miCuenta: 'Mi Cuenta',
            cerrarSesion: 'Cerrar Sesión',
            imageUrl: user.foto != null ? API_ARCHIVOS + user.foto : 'assets/img/avatar.jpg',
            configuracion: 'Mi Perfil',
        };
        this.navigationService.changeToggleMenu.pipe(takeUntil(this.destroy$)).subscribe((isOpen) => {
            this.isOpen = isOpen;
            if (isOpen && this.isMovil === true) {
                this.rutaPrincipal = '';
            } else {
                let arrayRuta = this.router.url.split('/');
                this.rutaPrincipal = arrayRuta[2];
                if (arrayRuta.length > 3) {
                    this.rutaSecundaria = arrayRuta[3];
                } else {
                    this.rutaSecundaria = '';
                }
            }
        });
        this.initOption();

        // No se detiene en onDestroy para poder seguir
        //escuchando alguna inactividad a partir de este componente
        if (this.bnIdle.expired$.observers.length === 0) {
            this.bnIdle.startWatching(900).subscribe((isTimedOut: boolean) => {
                if (isTimedOut) {
                    if (localStorage.getItem('data_user')) {
                        this.mensajeResponse.danger('Ok', 'Cerrado por Inactividad').subscribe();
                        this.cerrarSesion();
                        this.bnIdle.expired$.complete();
                    }
                }
            });
        } else {
            // Si la pagina es refresada se refresca el timer
            this.bnIdle.resetTimer();
        }
    }

    cerrarSesion() {
        this.loginService.logout().subscribe(() => {
            this.router.navigate(['/login']);
        });
    }

    activeRoute(routename: string): boolean {
        return this.router.url.indexOf(routename) > -1;
    }
    backmodules() {
        this.router.navigate(['/modulos']);
        // this.router.navigate(['/comercial/dashboard'])
    }
    listMenu() {
        let url = this.router.url.split('/');
        if (url[1] == '') {
            this.router.navigate(['/modulos']);
        } else {
            // let modulos: Array<any> = this._loginService.getdataUser().modulos;
            // let modulo = modulos.find(value => value.ruta == url[1]);
            let id = localStorage.getItem('idmenu');

            this._modulosService.listMenu(id).subscribe(
                (response) => {
                    this.lista = response;
                    // (modulo.id == 1) ? this.lista = menuMarketing(response) : null;
                    // (modulo.id == 3) ? this.lista = menuCmercial(response) : null;
                },
                (error) => {}
            );
        }
    }
}
