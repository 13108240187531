import { Component, Input, AfterViewInit, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService, LoginService_v2 } from 'app/views/login/login.service';
import { API_ARCHIVOS } from 'config/services';

@Component({
  selector: 'busqueda-avanzada',
  templateUrl: 'busqueda-avanzada.template.html',
  styleUrls:[
    './busqueda-avanzada.scss'
  ],
  providers: [
    LoginService
  ]
})
export class BusquedaAvanzadaComponent implements AfterViewInit, OnInit {
  public active: boolean;
  public ltsclose: Array<any>;

  @Input() show: boolean = false;
  @Output() close = new EventEmitter();
  // active: boolean = false;
  // public mostrarAvanzada: boolean;

  constructor(
    private _router: Router,
    private loginService: LoginService_v2,
    // private loginService: LoginService
  ) {
    this.active = false;
    // this.mostrarAvanzada = false;


  }

  ngAfterViewInit() {


    document.addEventListener('click', (event) => {
      let obj = event['path'];

      let CerrarDrow: Boolean = true;
      for (let index = 0; index < obj.length - 3; index++) {

        if (obj[index].parentElement !== null && obj[index].parentElement.nodeName == 'BUSQUEDA-AVANZADA') {
          CerrarDrow = false;
        } else if (obj[index].parentElement === null) {
          CerrarDrow = false;
        } else if (obj[index].parentElement[0] !== undefined && obj[index].parentElement[0] !== 0) {
          CerrarDrow = false;
        } else if (obj[index].tagName === 'NAV') {
          CerrarDrow = false;
        }

      }

      if (CerrarDrow === false) {
        this.active = true;

      } else {
        if (this.active == false && this.show == true) {
          this.active = true;
        } else {
          this.show = false;
          this.close.emit(this.show);
          this.active = false;
        }
      }

    });

  }


  ngOnInit(): void {
    let user = this.loginService.getdataUser();
    this.ltsclose = [
      {
        nombre: user.nombres,
        cargo: user.cargo,
        modulos: "Módulos",
        perferencias: "Preferencias",
        miCuenta: "Mi Cuenta",
        cerrarSesion: "Cerrar Sesión",
        imageUrl: user.foto != null? API_ARCHIVOS + user.foto : 'assets/img/avatar.jpg',
        configuracion: "Mi Perfil",
      },
    ]
  }


  desactivarDrow() {
    this.close.emit(false);
  }

  cerrarSesion() {
    this.loginService.logout().subscribe(d => {
      this._router.navigate(['/login']);
    });
  }
  modulos() {
    this._router.navigate(['/modulos']);
  }
}
