import { Component } from '@angular/core';

@Component({
    selector: 'layouts-modulos',
    templateUrl: 'layouts-modulos.template.html'
})
export class LayoutsModulosComponent {
    // Cabecera de cada Vista
    public links: Array<any>;
    constructor() {
        this.links = [
            { titulo: 'Módulos' },

        ];
    }
}
