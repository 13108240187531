import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { SelectDefaultComponent } from './select-default.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    SelectDefaultComponent,
  ],
  exports: [
    SelectDefaultComponent,
  ],
  imports: [
    CommonModule
  ],
})
export class SelectDefaultModule {}
