import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAccordionComponent } from './accordion.component';
import { CommonAccordionContainerComponent } from './accordion-container/accordion-container.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [CommonAccordionComponent, CommonAccordionContainerComponent],
    exports: [CommonAccordionComponent, CommonAccordionContainerComponent]
})
export class CommonAccordionModule { }
