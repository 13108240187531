import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'config/services';
import { SERVICIO_EXPEDIENTES } from 'config/services/riesgos-admision';

@Injectable({
    providedIn: 'root'
})
export class ExpedienteModalOpinionService {
    constructor(private _http: HttpClient) {
    }

    public comiteSaveOpinion(data: FormData) {
        return this._http.post(`${SERVICIO_EXPEDIENTES["v1.1"].porAprobasion.informeFinal.valiedezOperacion}`, data);
        // return this._http.post(`${API_URL}expediente/validez/operacion/comite`, data);
    }
    public comiteGetOpinion(idExpediente: Number, idOperacion: number) {
        return this._http.get<any>(`${API_URL}expediente/${idExpediente}/comentarios/operaciones/${idOperacion}/comite`);
    }
    public analistaSaveOpinion(data) {
        return this._http.post<any>(`${API_URL}expediente/validez/operacion/`, data);
    }
    public analistaUpdateOpinion(idExpediente: number, idOperacion: number, data) {
        return this._http.put<any>(`${API_URL}expediente/${idExpediente}/comentarios/operaciones/${idOperacion}/`, data);
    }
    public analistaGetOpinion(idExpediente: number, idOperacion: number) {
        return this._http.get<any>(`${API_URL}expediente/${idExpediente}/comentarios/operaciones/${idOperacion}/`);
    }
}

export class DataAnalista {
    validez: boolean;
    idoperacion: number;
    comentario: string
    idexpediente: number
    aplicar_todos: boolean;
}