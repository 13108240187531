import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CalendarioPrincipalComponent } from "./calendario-principal.component";
import { CalendarioRightModule } from "./calendario-right/calendario-right.module";
import { CommonModule } from "@angular/common";



@NgModule({
    declarations: [CalendarioPrincipalComponent],
    imports: [
        CommonModule,
        RouterModule,
        CalendarioRightModule
    ],
    exports: [CalendarioPrincipalComponent]
})

export class CalendarioPrincipalModule { }
