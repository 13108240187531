import { AfterViewInit, Directive, ElementRef, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

declare var $: any;

@Directive({
    selector: '[datepicker]',
})
export class DatepickerDirective implements OnInit, AfterViewInit {
    select: any;
    @Input() view: any;
    @Input() minView: any;
    @Input() format: any;
    @Input() inline: any;
    @Input() autoClose: any;
    @Input() startDate: any; // YYYY-MM-DD
    @Input() minDate: any; // new Date(moment().format('MM-DD-YYYY'))
    @Input() maxDate: any; // new Date(moment().format('MM-DD-YYYY'))
    @Input() position: 'top' | 'bottom';
    @Output() onSelect = new EventEmitter();
    updates: any;
    constructor(private el: ElementRef) {
        this.format = 'dd-mm-yyyy';
        this.inline = false;
        this.autoClose = true;
        this.minDate = false;
        this.maxDate = false;
        this.updates = false;
        this.startDate = '';
        this.view = 'days'; // days, months, years
        this.minView = 'days'; // days, months, years
    }

    ngOnInit() {
        setTimeout(() => {
            this.updates = true;
        }, 0);
    }

    ngAfterViewInit() {
        this.init();
    }

    private init() {
        $(this.el.nativeElement).datepicker({
            language: 'es',
            dateFormat: this.format,
            inline: this.inline,
            autoClose: this.autoClose,
            minDate: this.minDate,
            maxDate: this.maxDate,
            view: this.view,
            minView: this.minView,
            position: this.getPosition(),
            onSelect: (fd, date) => {
                if (this.view == 'years' && date != '') date = date.getFullYear();
                this.onSelect.emit(date);
            },
        });

        this.selectDate();
    }
    getPosition(): string {
        let position = 'bottom left';
        if (this.position == 'top') {
            position = 'top left';
        } else if (this.position == 'bottom') {
            position = 'bottom left';
        }
        return position;
    }

    ngOnChanges(simple: SimpleChanges) {
        if (this.updates) {
            let actualizacion = {};
            if (simple.startDate && simple.startDate.currentValue != '') {
                if (this.view == 'years') actualizacion['startDate'] = moment(simple.startDate.currentValue, 'YYYY').toDate();
                else actualizacion['startDate'] = moment(simple.startDate.currentValue, 'DD-MM-YYYY').toDate();
                this.startDate = actualizacion['startDate'];
                $(this.el.nativeElement).data('datepicker').selectDate(this.startDate);
            }
            if (simple.minDate) {
                actualizacion['minDate'] = simple.minDate.currentValue;
            }
            if (simple.maxDate) {
                actualizacion['maxDate'] = simple.maxDate.currentValue;
            }
            $(this.el.nativeElement).data('datepicker').update(actualizacion);
        }
        if (simple.startDate) {
            if (simple.startDate.currentValue != '' && simple.startDate.currentValue != simple.startDate.previousValue) {
                this.selectDate();
            } else {
                this.clearDate();
            }
        }
    }
    clearDate() {
        setTimeout(() => {
            $(this.el.nativeElement).data('datepicker').clear();
        }, 300);
    }

    selectDate() {
        if (this.startDate !== '') {
            setTimeout(() => {
                $(this.el.nativeElement)
                    .data('datepicker')
                    .selectDate(
                        new Date(
                            Number(moment(this.startDate).format('YYYY')),
                            Number(moment(this.startDate).format('M')) - 1,
                            Number(moment(this.startDate).format('D'))
                        )
                    );
            }, 0);
        }
    }
}
