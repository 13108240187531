import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from './loading.service';

@Component({
    selector: 'loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
    private countdownEndRef: Subscription = null;
    private progressRef: Subscription = null;
    cargando: boolean;
    showProgress: boolean;
    progress: number;
    isLoading = this.loading.isLoading;
    constructor(private loading: LoadingService) {
        this.cargando = false;
        this.progress = 0;
    }

    ngOnInit(): void {
        // this.countdownEndRef = this.loading.countdownEnd$.subscribe((value) => {
        //     this.progress = 0;
        //     if (value) {
        //         this.cargando = value.show;
        //         this.showProgress = value.progress;
        //     } else {
        //         setTimeout(() => {
        //             this.cargando = value.show;
        //             this.showProgress = value.progress;
        //         }, 300);
        //     }
        // });
        // this.progressRef = this.loading.progress$.subscribe((progress) => {
        //     this.progress = Math.round(progress);
        // });
    }
    ngOnDestroy() {
        // this.countdownEndRef.unsubscribe();
        // this.progressRef.unsubscribe();
    }
}
