import { environment } from '@environments/environment';
export const VERSION_API: any = 'api/v1.0/';
export const VERSION_APIV2: any = 'api/v1.1/';
export const API_URL: any = environment.API_URL + VERSION_API;
export const API_URLV2: any = environment.API_URL + VERSION_APIV2;
export const API_ARCHIVOS: any = environment.API_ARCHIVO;
export const API_ARCHIVO_IFRAME: any = environment.API_ARCHIVO_IFRAME;
export const URL_SOCKET: any = environment.URL_SOCKET;
export const SERVICIO_SEGURIDAD = {
    login: API_URL + 'login/obtenermodulos',
    logOut: '',
    configurarPerfil: `${API_URL}usersettings`,
};
export const SERVICIO_UTILES = {};
export const SERVICIO_MENU = {
    menu: API_URL + 'usuario/menu/',
};

export const SERVICIO_MODULO_COMERCIAL = {
    util: {
        listarDocumentoIdentidad: API_URL + 'operaciones/documentos-identidad',
        listarEstadoCivil: API_URL + 'operaciones/estados-civiles',
        listarMoneda: API_URL + 'moneda',
        listarTipoOperacion: API_URL + 'tipo_operacion',
        listarBienNiveles: API_URL + 'bien/nivel',
        listarBienSubNiveles: API_URL + 'bien/sub_nivel',
        listarBienTipos: API_URL + 'bien/tipo',
        listarBienClases: API_URL + 'bien/clase',
        listarBienDestino: API_URL + 'bien_destino',
        listarBienUso: API_URL + 'uso',
        listarBienMarcas: API_URL + 'marca_autos',
        listarModelos: API_URL + 'modelo_autos',
        listarSucursales: API_URL + 'comercial/util/proveedor/sucursales/modelos',
        listarEmpresaTamanio: API_URL + 'empresa/tamanio',
        listarEmpresaRegimenes: API_URL + 'empresa/regimenes',
        listarEmpresaIncluidoLista: API_URL + 'incluido/lista',
        listarDepartamentos: API_URL + 'departamento',
        listarProvincias: API_URL + 'provincia/',
        listarDistritos: API_URL + 'distrito/',
        listarCanal: API_URL + 'canal',
        listarPlazo: API_URL + 'plazo',
        listarCampania: API_URL + 'campania',
        listarCanalContacto: API_URL + 'canal/contacto',
        listarTipoDocumento: API_URL + 'cliente/biblioteca/tipoarchivo/web/lista',
        consultaRuc: API_URL + 'cliente/ruc/web/',
        consultaBTL: API_URL + 'cotizacion/btl/web/',
        consultaRucProveedor: API_URL + 'proveedor/consultar/ruc',
        listarSucursalesLeasing: API_URL + 'sucursales/leasing',
        listarOrigen: API_URL + 'cotizacion/origen',
        listarTeaPorUsuario: API_URL + 'tea/cargo',
        listarEstadosCotizacion: API_URL + 'util/comercial/estadocotizacion',
        cambiarEstadosCotizacion: API_URL + 'util/cotizacion/cambiar_estado',
        listarEstadosOperacion: API_URL + 'util/comercial/estadooperacion',
        listarInfluencia: API_URL + 'administracion/zona-influencia/listar',
        listarSeccionesExpediente: API_URL + 'comercial/util/secciones',
        listarEstadosPorModulo: API_URL + 'comercial/util/expediente/estado/riesgo',
        listarAreaExpediente: API_URL + 'comercial/util/areas',
        listarProveedores: API_URL + 'proveedor/ctz', // {"razonsocial":"","pagina":1,"numfilas":1000,"idmodelo":6231}
        listarSucuarsales: API_URL + 'sucursal/ctz', // {"idproveedor":1132,"idmodelo":6231,"sucursal":"","pagina":1,"numfilas":1000}
        listarContactoSucuarsal: API_URL + 'proveedor/sucursal/contacto/movil/lista', // {"idsucursal":1079,"contacto":"","pagina":1,"numfilas":10}
        listarCarroceria: API_URL + 'bien/clase', // {"filtro":"a","pagina":1,"numfilas":10}
    },
    dashboard: {
        cotizacionesPorEstado: API_URL + 'dashboard/cotizacion/estado',
        cotizacionesPorTipoBien: API_URL + 'dashboard/cotizacion/tipobien',
        cotizacionesPorPeriodo: API_URL + 'dashboard/cotizacion/periodo',
        cotizacionesSucursal: API_URL + 'dashboard/cotizacion/sucursal',
        cotizacionesExpediente: API_URL + 'dashboard/cotizacion/expediente',
        cotizacionesExpedienteFecha: API_URL + 'dashboard/cotizacion/expediente/fecha',
    },
    planComercial: {},
    operaciones: {
        expediente: {
            consultar: API_URL + 'expediente/web/lista',
            listarxEstado: API_URL + 'expediente/web/estado',
            enviarRiesgo: API_URL + 'expediente/mover/web',
            asuntoComercial: API_URL + 'expediente/asunto/',
            enviarLegal: API_URL + 'comercial/enviar-legal-comercial',
            detalle: API_URL + 'expediente/detalle',
            folder: {
                listar: API_URL + 'cliente/expediente/folder/web/lista',
                historial: API_URL + 'cliente/expediente/folder/historial/web/lista',
                biblioteca: {
                    listar: API_URL + 'cliente/expediente/folder/biblioteca/lista',
                    guardar: API_URL + 'cliente/cliente/expediente/folder/biblioteca/web',
                },
                checklist: {
                    listar: API_URL + 'expediente/checklist/documentos/web/lista',
                    guardar: API_URL + 'expediente/checklist/web',
                },
            },
        },
        operaciones: {
            listar: API_URL + 'operacion/operacion/listar',
            get: API_URL + 'operacion/operacion/detalle/',
        },
    },
    mapa: {
        ejecutivo: 'mapa/ejecutivo/web/id/',
        recorrido: 'mapa/recorrido/web/',
        sucursales: 'mapa/filtro/leasing/sucursales',
        ejecutivos: 'mapa/filtro/leasing/sucursales/ejecutivos',
        proveedores: 'mapa/filtro/proveedores',
        clientes: 'mapa/filtro/clientes',
        ubicaciones: 'mapa/filtro/ubicaciones',
    },
    organizaciones: {
        clientes: {
            consulta_ruc: API_URL + 'cliente/ruc/web/',
            listar: API_URL + 'cliente/web/lista',
            nuevo: API_URL + 'cliente/web',
            // detalle: API_URL + "cliente/web/",
            detalle: API_URL + 'cliente/detalle/web/',
            editar: API_URL + 'cliente/web/',
            // editar: "",
            eliminar: API_URL + 'cliente/web/delete/',
        },
        cliente_contactos: {
            listar: API_URL + 'cliente/contacto/web/lista',
            nuevo: API_URL + 'cliente/contacto/web',
            editar: API_URL + 'cliente/contacto/web/',
            ver: API_URL + 'cliente/contacto/web/',
            detalle: API_URL + 'cliente/contacto/detalle/web/',
            // eliminar: API_URL + 'cliente/contacto/web/',
            eliminar: API_URL + 'cliente/contacto/web/delete/',
        },
        cliente_bibliotecas: {
            listar: API_URL + 'cliente/biblioteca/web/lista',
            nuevo: API_URL + 'cliente/biblioteca/web',
            eliminar: API_URL + 'cliente/biblioteca/web/delete/',
            ver_Imagen: API_ARCHIVOS,
            dowload: API_URL + 'util/comercial/file/',
        },
        proveedores: {
            listar: API_URL + 'proveedor/web/lista',
            eliminar: API_URL + 'proveedor/web/deletep/',
            // eliminar: API_URL + "proveedor/web/delete/",
            nuevo: API_URL + 'proveedor/web',
            consultar: API_URL + 'proveedor/consultar/ruc',
        },

        proveedores_ubicacion: {
            listar: API_URL + '',
        },
        proveedores_sucursales: {
            listar: API_URL + 'proveedor/sucursal/web/lista',
            eliminar: API_URL + 'proveedor/sucursal/web/delete/',
            consultar: API_URL + 'proveedor/sucursal/web/',
            nuevo: API_URL + 'proveedor/sucursal/web',
            editar: API_URL + 'proveedor/sucursal/web',
        },
        proveedores_contactos: {
            listar: API_URL + 'proveedor/sucursal/contacto/web/lista',
            eliminar: API_URL + 'proveedor/sucursal/contacto/web/delete/',
            consultar: API_URL + 'proveedor/sucursal/contacto/web/',
            nuevo: API_URL + 'proveedor/sucursal/contacto/web',
            editar: API_URL + 'proveedor/sucursal/contacto/web',
        },
        asignacionCartera: {
            listarEjecutivos: API_URL + 'cartera/ejecutivo/web/lista',
            listarEjecutivosPorId: API_URL + 'cartera/proveedor/web/asignacion/proveedor/',
            listarProveedores: API_URL + 'cartera/proveedor/web/lista',
            listarCliente: API_URL + 'cartera/cliente/web/lista',
            guardarProveedores: API_URL + 'cartera/asignacion/ejecutivo/proveedor/web',
            guardarCliente: API_URL + 'cartera/asignacion/ejecutivo/cliente/web',
            listaClienteAsignados: API_URL + 'cartera/asignacion/ejecutivo/cliente/web/lista',
            listaProveedoresAsignados: API_URL + 'cartera/asignacion/ejecutivo/proveedor/web/lista',
        },
        proveedores_bien: {
            listar: API_URL + 'proveedor/modelo/listar',
            eliminar: API_URL + 'proveedor/modelo/',
            grabar: API_URL + 'proveedor/sucursal/modelos',
        },
    },

    fuerzaVenta: {
        organigrama: {
            listaOrganigrama: API_URL + 'equipo/comercial',
        },
    },
    cotizaciones: {
        cotizacion: {
            listar: API_URL + 'cotizacion/web/lista',
            buscarCliente: API_URL + 'cotizacion/cliente/contacto/web/lista',
            listarProveedores: API_URL + 'proveedor/ctz',
            listarProveedoresCarroceria: API_URL + 'operaciones/proveedores/combo',
            listarSucuarsales: API_URL + 'sucursal/ctz',
            detalleBienes: API_URL + 'detalle_bienes',
            cronograma: API_URL + 'cronograma',
            cronogramaVariable: API_URL + 'cronograma/variable',
            pdf: API_URL + 'cotizacion/pdf3',
            enviar: API_URL + 'cotizacion/web',
            verPdf: API_URL + 'cotizacion/pdf/ruta/',
            enviarCorreo: API_URL + 'cotizacion/enviar/pdf',
            eliminar: API_URL + 'cotizacion/web/delete/',
            generarExpediente: API_URL + 'expediente/web',
            listarCorreosAdmision: API_URL + 'correos-admision',
            listarCorreos: API_URL + 'expediente/email_riesgos',
            actualizarDocumentosCotizacion: API_URLV2 + 'expediente/documento/checklist/cotizacion',
        },
        cotiExpress: {
            Listar_Cotiexpress: API_URL + 'cotiexpress/lista',
            detalle: API_URL + 'cotiexpress/',
        },
    },
    administracion: {
        ejecutivos: {
            listar: API_URL + 'ejecutivo/web/listar',
            detalle: API_URL + 'ejecutivo/web/',
            listarSucursal: API_URL + 'sucursales/leasing',
            listaZonaInfluencia: API_URL + 'administracion/zona-influencia/listar',
            guardar: API_URL + 'ejecutivo/web/asigcuota',
        },
        sucursal: {
            listar: API_URL + 'administracion/sucursal/listar',
            grabar: API_URL + 'administracion/sucursal',
            editar: API_URL + 'administracion/sucursal',
            get: API_URL + 'administracion/sucursal/',
            eliminar: API_URL + 'administracion/sucursal/',
        },
        checklist: {
            listar: API_URL + 'administracion/checklist/tipodocumento/listar',
            grabar: API_URL + 'administracion/checklist/tipodocumento/',
            editar: API_URL + 'administracion/checklist/tipodocumento/',
            get: API_URL + 'administracion/checklist/tipodocumento/',
            eliminar: API_URL + 'administracion/checklist/tipodocumento/',
        },
        zona_influencia: {
            listar: API_URL + 'administracion/zona-influencia/listar',
            grabar: API_URL + 'administracion/zona-influencia',
            editar: API_URL + 'administracion/zona-influencia',
            get: API_URL + 'administracion/zona-influencia/get/',
            eliminar: API_URL + 'administracion/zona-influencia/get/',
        },

        tipoCambio: {
            listar: API_URL + 'administracion/tipocambio/listar',
            detalle: API_URL + 'administracion/tipocambio',
            editar: API_URL + 'administracion/tipocambio',
            nuevo: API_URL + 'administracion/tipocambio',
        },
        alertas: {
            listar: API_URL + 'riesgos/estaciones',
            correos: API_URL + 'riesgos/correos-estacion/',
            newCorreos: API_URL + 'expediente/email_riesgos',
        },
        motivosModificacion: {
            listar: API_URL + 'operaciones/caf/operacion/motivos-modificacion',
            editar: API_URL + 'operaciones/caf/operacion/motivos-modificacion',
            nuevo: API_URL + 'operaciones/caf/operacion/motivos-modificacion',
            eliminar: API_URL + 'operaciones/caf/operacion/motivos-modificacion',
        },
    },
    reportes: {
        ejecutivos: '',
    },
};
