import { API_URL } from "./comun";

export const SERVICIOS_CONTRATOS = {
  garantias: {},
  caf: {
    listarcafs: `${API_URL}cafs/search`,

    RelacionarGarantiaConCafOperacion: (cafOperacionId: number) => {
      return `operaciones/caf-operaciones/${cafOperacionId}/garantias`;
    },
    solicitudPoliza: {
      listar: (cafOperacionId: number) => {
        return `operaciones/caf-operaciones/${cafOperacionId}/solicitudes-polizas`;
      },
      documentos: (solicitudPolizaId: number) => {
        return `operaciones/solicitudes-polizas/${solicitudPolizaId}/archivos`;
      },
      eviarCorreo: (solicitudPolizaId: number) => {
        return `operaciones/solicitudes-polizas/${solicitudPolizaId}/enviar`;
      },
      dataParaNuevo: (cafOperacionId: number) => {
        return `operaciones/caf-operaciones/${cafOperacionId}/solicitudes-polizas/nuevo`;
      },
      guardar: `operaciones/solicitudes-polizas`,
      comboBrokers: `operaciones/brokers/combo`,
      preview: `operaciones/solicitudes-polizas/preview`,
    },
    polizaDeSeguro: (cafOperacionId: number) => {
      return `operaciones/caf-operaciones/${cafOperacionId}/polizas-seguros`;
    },
    soatVehicular: (cafOperacionId: number) => {
      return `operaciones/caf-operaciones/${cafOperacionId}/soat`;
    },

    infoTabs: (cafOperacionId: number) => {
      return `operaciones/caf-operaciones/${cafOperacionId}/polizas-seguros/tabs`;
    },
    cafgarantias: {
      listarCafOperacion: (cafOperacionId: number) => {
        return `operaciones/caf-operaciones/${cafOperacionId}/garantias`;
      },
      eliminar: (cafOperacionId: number, garantiaId: number) => {
        return `operaciones/caf-operaciones/${cafOperacionId}/garantias/${garantiaId}`;
      },
      tasaciones: {
        listar: (garantiaId: number) => {
          return `operaciones/garantias/${garantiaId}/tasaciones`;
        }
      },
      formulario: {
        listarCafOperacionYCliente: (cafOperacionId: number) => {
          return `operaciones/caf-operaciones/${cafOperacionId}/garantias-cliente`;
        },
        comboCliente: () => {
          return `util/cliente`;
        },
      },
    },
    OrdenDeCompra: {
      listar: (cafOperacionId: number) => {
        return `operaciones/caf-operacion/${cafOperacionId}/ordenes-compras`;
      },
      vistaConstancia: (cafOperacionId: number) => {
        return `operaciones/ordenes-compras/${cafOperacionId}/pdf?tipo=constancia`;
      },
      vistaOrdenCompra: (cafOperacionId: number) => {
        return `operaciones/ordenes-compras/${cafOperacionId}/pdf`;
      },
      enviarCorreo: (ordenesComprasId: number) => {
        return `operaciones/ordenes-compras/${ordenesComprasId}/pdf`;
      },
    },
    clientes: {
      listar: (cafOperacionId: number) => {
        return `operaciones/caf-operacion/${cafOperacionId}/cliente`;
      },
      detalles: (clienteId: number) => {
        return `operaciones/clientes/${clienteId}/detalle`;
      },
      contactos: {
        listar: (clienteId: number) => {
          return `operaciones/clientes/${clienteId}/contactos`;
        },
        obtener: (clienteId: number, contactosId: number) => {
          return `operaciones/clientes/${clienteId}/contactos/${contactosId}`;
        },
        editar: (clienteId: number, contactosId: number) => {
          return `operaciones/clientes/${clienteId}/contactos/${contactosId}`;
        },
        detalle: (clienteId: number, contactosId: number) => {
          return `operaciones/clientes/${clienteId}/contactos/${contactosId}`;
        },
        guardar: (clienteId: number) => {
          return `operaciones/clientes/${clienteId}/contactos`;
        },
        eliminar: (clienteId: number, contactosId: number) => {
          return `operaciones/clientes/${clienteId}/contactos/${contactosId}`;
        },
      },
    },
    bienesOperaciones: {
      listar: (cafOperacionId: number) => {
        return `operaciones/caf-operacion/${cafOperacionId}/operaciones`;
      },
      tasaciones: (operacionId: number) => {
        return `operaciones/operaciones/${operacionId}/tasaciones`;
      },
    },
    checklist: {
      listar: (cafId: number) => {
        return `operaciones/caf/${cafId}/doc-a-vistos/checklist`;
      }
    },
    detalles: {
      detalles: (idCaf: number) => {
        return `operaciones/caf-operacion/${idCaf}/detalle`;
      }
    },
    postDesembolso: {
      listar: (cafId: number) => {
        return `operaciones/caf-operaciones/${cafId}/caf-operacion-tareas`;
      },
      detalle: (cafId: number) => {
        return `operaciones/caf/${cafId}/doc-a-vistos/checklist`;
      },
      empezar: (id: number) => {
        return `operaciones/caf-operacion-tareas/${id}/empezar`;
      },
      finalizar: (id: number) => {
        return `operaciones/caf-operacion-tareas/${id}/finalizar`;
      },
      archivos: (id: number) => {
        return `operaciones/caf-operacion-tareas/${id}/archivos`;
      },
      subirArchivos: (id: number) => {
        return `operaciones/caf-operacion-tareas/${id}/archivos`;
      },
    }
  },
};
