import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpedienteHistorialComponent } from './expediente-historial-documentos.component';
import { TabsModule } from '@common/tabs/tabs.module';
import { EditarCotizacionModule } from '@viewRiesgosAdmision/evaluacion/editar-cotizacion/editar-cotizacion.module';

@NgModule({
    imports: [
        CommonModule,
        TabsModule,
        EditarCotizacionModule,
    ],
    declarations: [
        ExpedienteHistorialComponent
    ],
    exports: [
        ExpedienteHistorialComponent
    ]
})
export class CommonExpedienteHistorialDocumentosModule { }
