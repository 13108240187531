import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpedienteDetalleDocumentosComponent } from './expediente-detalle-documentos.component';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        PipesModule
    ],
    declarations: [
        ExpedienteDetalleDocumentosComponent
    ],
    exports: [
        ExpedienteDetalleDocumentosComponent
    ]
})
export class CommonExpedienteDetalleDocumentosModule { }
