import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'doc_orden'
})

export class ChecklistPipe implements PipeTransform {
    transform(value: any) {
        return value.sort((a, b) => {
            if (a.doc_orden === null) {
                a.doc_orden = 1000
            } else if (b.doc_orden === null) {
                b.doc_orden = 1000
            }

            if (a.doc_orden < b.doc_orden) {
                return -1
            }

            if (a.doc_orden > b.doc_orden) {
                return 1
            }
            return 0

        });
    }
}

@Pipe({
    name: 'sec_orden'
})
export class ChecklistPipe2 implements PipeTransform {
    transform(value: any) {
        return value.sort((a, b) => {
            if (a.sec_orden < b.sec_orden) {
                return -1
            }

            if (a.sec_orden > b.sec_orden) {
                return 1
            }
            return 0
        });
    }
}







