import { Injectable, Optional } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SesionService {

    // public id: number;
    // public token: string;
    // public cargo: string;
    // public nombre: string;
    // public sucursal: string;
    // public perfil: string;
    // public idperfil: number;
    // public codigoperfil: string;

    //Al utilizar la configuracion de esta manera
    //Al actualizar la pagina La configuracion se perdia
    // constructor(@Optional() config: SesionService) {
    //     if (config) {
    //         this.id = config.id;
    //         this.token = config.token;
    //         this.cargo = config.cargo;
    //         this.nombre = config.nombre;
    //         this.sucursal = config.sucursal;
    //         this.perfil = config.perfil;
    //         this.idperfil = config.idperfil;
    //         this.codigoperfil = config.codigoperfil;
    //     }

    // }
}

//Transformamos el servicio en constantes
//Y lo inicializamos de esta manera
const data_user = localStorage.getItem('data_user');

export const dataSesion: ISesion = {
    id: JSON.parse(data_user || '{}').datos || undefined,
    token: JSON.parse(data_user || '{}').access_token || undefined,
    cargo: JSON.parse(data_user || '{}').cargo || undefined,
    nombre: JSON.parse(data_user || '{}').nombre || undefined,
    sucursal: JSON.parse(data_user || '{}').sucursal || undefined,
    perfil: JSON.parse(data_user || '{\"perfil\":[{}]}').perfil[0].nombre || undefined,
    idperfil: JSON.parse(data_user || '{\"perfil\":[{}]}').perfil[0].id || undefined,
    codigoperfil: JSON.parse(data_user || '{\"perfil\":[{}]}').perfil[0].codigo || undefined
}

export interface ISesion {
    id: number;
    token: string;
    cargo: string;
    nombre: string;
    sucursal: string;
    perfil: string;
    idperfil: number;
    codigoperfil: string;
}