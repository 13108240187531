import { Pipe, PipeTransform } from '@angular/core';
import { SeparadorMiles } from '@util/separado-miles.class';

@Pipe({
    name: 'currencyCustom',
})
export class CurrencyCustomPipe implements PipeTransform {
    transform(value: number, code: typeCode): string {

        let simbolo: string = '';
        if (value != null && value != undefined && Number(value) != NaN) {
            switch (code) {
                case 'PEN': {
                    simbolo = 'S/. ';
                    break;
                }
                case 'USD': {
                    simbolo = 'US$';
                    break;
                }
            }
        }
        return `${simbolo} ${SeparadorMiles.separar(value)}`;
    }
}

type typeCode = 'PEN' | 'USD';
