import { Component, ElementRef, ViewChild, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';

@Component({
    selector: 'select-defaul-async',
    templateUrl: 'select-defaul-async.template.html',
    providers: [
    ]
})
export class SelectDefaulAsyncComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('inputSelect', { static: true }) inputSelect: ElementRef;
    @ViewChild('inputSelectDiv', { static: true }) inputSelectDiv: ElementRef;
    @ViewChild('focusSelect', { static: true }) focusSelect: ElementRef;
    @Input() placeholder: any;
    @Input() loading: any;
    @Input() selected: any;
    @Input() search: boolean;
    @Input() searchPlaceholderText: any;
    @Input() data: Array<any>;
    // public dataFinal: Array<any>;
    @Output() selectChange = new EventEmitter<any>();
    public readonly: boolean;
    @Input() value: any;
    @Output() searchText = new EventEmitter<any>();
    @Output() scrolling = new EventEmitter<any>();
    public showOption: boolean;
    public searchChangeObserver: any;
    constructor(public el: ElementRef, private changeDetector: ChangeDetectorRef) {
        this.search = true;
        this.placeholder = "";
        this.searchPlaceholderText = "Buscar";
        this.data = [];
        this.readonly = false;
        this.value = "";
        this.showOption = false;
        this.selected = { id: "", text: "" };
        this.loading = false;
    }
    ngOnInit() {
        // this.searchData("");
    }
    ngOnChanges(simpleChange: SimpleChanges) {
    }
    showOptions() {
        let padre = this.inputSelect.nativeElement.parentElement;
        padre.classList.add('activo');
        this.showOption = true;
        
    }
    hideOptions() {
        let totalSelects = document.getElementsByTagName("SELECT-DEFAUL-ASYNC").length;
        for (let i = 0; i < totalSelects; i++) {
            let select = document.getElementsByTagName("SELECT-DEFAUL-ASYNC")[i].querySelector('.select-defaul-async');
            select.classList.remove('activo');
        }
        this.showOption = false;
        
    }
    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }  
    selectDeactivate(){
        this.hideOptions();
        this.readonly = true;
    }
    selectActivate(){
        this.showOptions();
        this.focusSelect.nativeElement.focus();
        
    }
    onBlurSelect(event){
        const relatedTarget = event.relatedTarget as HTMLElement;
        var isDeactivate = true
        if (relatedTarget){
            switch (true) {
                case relatedTarget.classList.contains('input-search'):
                    isDeactivate = false
                    break;
                case relatedTarget.classList.contains('icon-right'):
                    isDeactivate = false
                    break;
                case relatedTarget.classList.contains('container'):
                    isDeactivate = false
                default:
                    break;
            }
        }
        
        if (isDeactivate){
            this.selectDeactivate();
        }
    }
    ngAfterViewInit() {
        this.inputSelectDiv.nativeElement.onclick = (event) => {
            if(!this.showOption){
                this.selectActivate();
            }
        };
        // if (this.search) {
        //     this.inputSelect.nativeElement.onkeyup = () => {
        //         this.searchText.emit(this.inputSelect.nativeElement.value);
        //     };
        // }
    }
    selectOption(selected_index, data) {
        this.data.forEach((value, index) => {
            this.data[index].selected = false;
        });
        this.data[selected_index].selected = true;
        this.selected = data;
        this.selectChange.emit(data);
        this.hideOptions();
    }
    closeSelect() {
        if (this.search) {
            this.value = "";
        }
        if (this.showOption){
            this.selectDeactivate();
        }
        else {
            this.selectActivate();
            
        }
            
        
    }
    scrolled() {
        if (!this.loading) {
            this.scrolling.emit(this.inputSelect.nativeElement.value);
        }
    }
    searchValue(event) {
        if (this.search) {
            if (!this.searchChangeObserver) {
                Observable.create(observer => {
                    this.searchChangeObserver = observer;
                }).pipe(debounceTime(300))
                    .pipe(distinctUntilChanged())
                    .subscribe(res => {
                        this.searchText.emit(res);
                    });
            }
            this.searchChangeObserver.next(event);
        }
    }

}
