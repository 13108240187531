import { Component, Input } from '@angular/core';
@Component({
  selector: 'common-tab',
  templateUrl: './tab.template.html',
  styleUrls: ['./tab.scss']
})
export class TabComponent {
  @Input() titulo: string;
  @Input() active: boolean = false;
  @Input() with: number = 100;
}

