import { AfterViewInit, Directive, ElementRef, Input, OnInit, Output, EventEmitter, OnDestroy, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';

declare var $: any;

@Directive({
    selector: '[select2]'
})
export class Select2Directive implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Output() selectChange = new EventEmitter();
    @Output() unselect = new EventEmitter();
    @Input() search: boolean;
    @Input() placeholder: any;
    @Input() clear: boolean;
    @Input() selected: any;
    @Input() parent: any;
    @Input() theme: string;
    @Input() color: string;

    constructor(private el: ElementRef) {
        this.search = false;
        this.placeholder = "";
        this.clear = false;
        this.selected = "";
        this.theme = ''; // select-default-sm |
        this.color = ''; // white |

    }

    ngOnInit() {

    }
    ngAfterViewInit() {
        this.init();
    }
    ngOnDestroy() {
        $(this.el.nativeElement).select2('destroy');
    }
    ngOnChanges(simple: SimpleChanges) {
        if (simple.selected) {
            setTimeout(() => {
                $(this.el.nativeElement).val(simple.selected.currentValue).trigger('change');
            }, 500);
        }
    }
    private init() {
        this.color = this.color != '' ? `select-color-${this.color}` : '';
        $(this.el.nativeElement).select2({
            dropdownParent: $(`#${this.parent}`),
            theme: 'bootstrap4 ' + this.theme + ' ' + this.color,
            language: "es",
            width: '100%',
            minimumResultsForSearch: this.search ? 1 : -1,
            placeholder: this.placeholder,
            allowClear: this.clear,
        }).on('select2:selecting', (event) => {
            this.selectChange.emit(event.params.args.data);
            // $(this.el.nativeElement).select2('close');
        }).on("select2:unselecting", (e) => {
            this.unselect.emit();
        });
        this._selected();
    }
    private _selected() {
        if (
            this.selected !== '' &&
            this.selected !== null &&
            this.selected !== undefined
        ) {
            $(this.el.nativeElement).val(this.selected).trigger('change');
        }
    }
}
