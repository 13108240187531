import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivasModule } from '@directives/directivas.module';
import { AgregarDocumentoComponent } from './agregar-documentos.component';
import { SelectDefaulAsyncModule } from '@common/selects/select-defaul-async/select-defaul-async.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        DirectivasModule,
        SelectDefaulAsyncModule,
        FormsModule
    ],
    declarations: [
        AgregarDocumentoComponent
    ],
    exports: [
        AgregarDocumentoComponent
    ]
})
export class AgregarDocumentoModule { }
