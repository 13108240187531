import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginacionDefultComponent } from './paginacion-defult.component';
import { FormsModule } from '@angular/forms';
@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        PaginacionDefultComponent
    ],
    exports: [
        PaginacionDefultComponent
    ]
})
export class PaginacionDefaultModule { }
