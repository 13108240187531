import { Component, OnInit, Input } from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
import { Router } from '@angular/router';
import { LoginService_v2 } from 'app/views/login/login.service';
import { NavigationService } from '@common/navigation/navigation.service';
declare var jQuery: any;


@Component({
    selector: 'topnavbar',
    templateUrl: 'topnavbar.template.html',
    styleUrls:[
        './topnavbar.scss'
    ]
})
export class TopnavbarComponent implements OnInit {
    @Input('showMenu') menuShow: boolean;
    public mostrarAvanzada: boolean;
    constructor(
        private router: Router, 
        private loginService: LoginService_v2,
        private navigationService: NavigationService
        ) {
        this.mostrarAvanzada = false;
        this.menuShow = true;
    }
    toggleNavigation(): void {
        jQuery("body").toggleClass("mini-navbar");
        smoothlyMenu();
    }
    activeRoute(routename: string): boolean {
        return this.router.url.indexOf(routename) > -1;
    }


    logout() {
        this.router.navigate(['/login']);
        this.loginService.logout().subscribe(d => {
        });
    }
    ngOnInit(): void {

    }
    showMenu(){
        this.navigationService.toggleMenu();
    }
}
