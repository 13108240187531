import { Component, ElementRef, ViewChild, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';

@Component({
    selector: 'select-default',
    templateUrl: 'select-default.template.html',
    providers: [],
})
export class SelectDefaultComponent implements OnInit, AfterViewInit {
    @ViewChild('inputSelect', { static: true }) inputSelect: ElementRef;
    @ViewChild('inputSelectDiv', { static: true }) inputSelectDiv: ElementRef;
    @ViewChild('focusSelect', { static: true }) focusSelect: ElementRef;
    @Input() placeholder: any;
    @Input() selected: any;
    @Input() disabled: boolean = false;
    @Input() error: boolean;
    @Input() search: boolean;

    @Input() value: any;
    @Input() data: Array<any>;
    @Output() selectChange = new EventEmitter<any>();
    // @Input() value: any;
    public readonly: boolean;
    public showOption: boolean;
    public dataFinal: Array<any>;
    @Output() bandera: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(public el: ElementRef) {
        this.search = true;
        this.placeholder = '';
        this.data = [];
        this.readonly = false;
        this.value = '';
        this.showOption = false;
        this.selected = { id: '', text: '' };
        this.dataFinal = [];
    }

    ngOnInit() {
        this.searchData('');
    }

    showOptions() {
        if (!this.disabled) {
            let padre = this.inputSelect.nativeElement.parentElement;
            padre.classList.add('activo');
            this.showOption = true;
        }
    }

    hideOptions() {
        let totalSelects = document.getElementsByTagName('SELECT-DEFAULT').length;
        for (let i = 0; i < totalSelects; i++) {
            let select = document.getElementsByTagName('SELECT-DEFAULT')[i].querySelector('.select-defaul-async');
            select.classList.remove('activo');
        }
        this.showOption = false;
    }
    selectDeactivate() {
        this.hideOptions();
        this.readonly = true;
    }
    selectActivate() {
        this.showOptions();
        this.focusSelect.nativeElement.focus();
    }
    onBlurSelect(event) {
        const relatedTarget = event.relatedTarget as HTMLElement;
        var isDeactivate = true;
        if (relatedTarget) {
            switch (true) {
                case relatedTarget.classList.contains('input-search'):
                    isDeactivate = false;
                    break;
                case relatedTarget.classList.contains('icon-right'):
                    isDeactivate = false;
                    break;
                case relatedTarget.classList.contains('container'):
                    isDeactivate = false;
                default:
                    break;
            }
        }

        if (isDeactivate) {
            this.selectDeactivate();
        }
    }
    ngAfterViewInit() {
        this.inputSelectDiv.nativeElement.onclick = (event) => {
            this.selectActivate();
            this.searchData(this.inputSelect.nativeElement.value);
        };
        if (this.search) {
            this.inputSelect.nativeElement.onkeyup = () => {
                this.searchData(this.inputSelect.nativeElement.value);
            };
        }
    }

    selectOption(data) {
        if (data.text === 'Desembolso') {
            this.bandera.emit(true);
        } else {
            this.bandera.emit(false);
        }
        this.selected = data;
        this.selectChange.emit(data);
        this.hideOptions();
    }

    closeSelect() {
        if (this.search) {
            // this.value = "";
        }
        if (this.showOption) {
            this.selectDeactivate();
        } else {
            this.selectActivate();
            this.searchData(this.inputSelect.nativeElement.value);
        }
    }

    searchData(text) {
        this.dataFinal = [];
        if (Array.isArray(this.data)) {
            this.data.forEach((value) => {
                // value.text = String(value.text).toUpperCase()
                if (String(value.text).toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    // this.dataFinal.push({
                    //     id: value.id,
                    //     text: value.text
                    // });

                    this.dataFinal.push(value);
                }
            });
        }
    }
}
