import { Injectable } from '@angular/core';
import { API_URL } from 'config/services';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { ExpedienteCheckList, DocumentoCheckList } from '@models/comercial/expediente/expediente-checklist.model';
import { LoginService } from 'app/views/login/login.service';

@Injectable({
    providedIn: 'root'
})
export class ComercialExpedienteCheckListService {


    constructor(
        private _http: HttpClient,
        private _loginService: LoginService
    ) {
    }

    listarChecklist(idExpediente) {
        return this._http.get(API_URL + "expediente/" + idExpediente + "/checklist").pipe(
            map((response: any) => {
                let data: ExpedienteCheckList[] = [];
                let id = 0;
                response.data.forEach((value, index) => {
                    let documentos = new DocumentoCheckList(
                        value.iddocumento,
                        value.nombre_documento,
                        value.codigo_documento,
                        value.prioridad,
                        value.cantidad_archivos,
                        value.is_checked
                    );
                    if (id != value.idseccion) {
                        data.push(new ExpedienteCheckList(
                            value.idseccion,
                            value.nombre_seccion,
                            [
                                documentos
                            ])
                        )
                        id = value.idseccion;
                    } else {
                        data[data.length - 1].documentos.push(documentos);
                    }
                });
                response.data = data;
                return response;
            }),
        );
    }
    uploadFile(formData) {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response)
                    }
                }
            }
            xhr.open('POST', API_URL + 'expediente/documento/checklist/', true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + atob(atob(atob(this._loginService.getdataUser().access_token))));
            xhr.send(formData);
        });
    }
    listarDocumentos(idExpediente: number, iddocumento: number) {
        return this._http.get(API_URL + 'expediente/' + idExpediente + '/documento/' + iddocumento + '/checklist').pipe(map((response: any) => response.data));
    }
    eliminarDocumentos(iddocumento: number) {
        return this._http.delete(API_URL + 'archivo/expediente/' + iddocumento + '/checklist');
    }
    listarHistorialDocumentos(data) {
        /*
       EJEMLO:
           data = {
           "idtipodocumento":7,
           "idexpediente":80,
           "pagina": 1,
           "numfilas": 10
       }*/
        return this._http.post(API_URL + 'expediente/historial/checklist/', data);
    }
    expedienteCheck(data) {
        /*
       EJEMLO:
           data = {
            "idtipodocumento":1,
            "idexpediente":1,
            "is_check": true
        }*/
        return this._http.post(API_URL + 'expediente/checked/', data);
    }
    enviarRiesgo(data) {
        /*
       EJEMLO:
           data = {
                "idexpedienteasig": 1
            }*/
        return this._http.post(API_URL + 'expediente/checked/', data);

    }

    enviarLegal() {
        let data = {};
        return this._http.post(API_URL + 'expediente/checked/legal', data);
    }

    postEnviarLegal(id) {
        let data = {
            "subsanar_observado": true,
            "subsanar_adicional": true
        };
        return this._http.post(API_URL + `legal/expediente/comercial/adicionalesyobservados/${id}`, data);
    }

    getDocumentosAdicionales(id) {
        return this._http.get(API_URL + `legal/expediente/comercial/adicionalesyobservados/${id}`);
    }
}
