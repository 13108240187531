import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { fadeAnimation } from 'app/animations/fade.animation';
declare var jQuery: any;


@Component({
    selector: 'blank-component',
    templateUrl: 'blank.template.html',
    animations: [fadeAnimation],

})
export class BlankComponent implements AfterViewInit, OnDestroy {

    ngAfterViewInit() {
        jQuery('body').addClass('gray-bg');
    }

    ngOnDestroy() {
        jQuery('body').removeClass('gray-bg');
    }
}
