import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { TipoBienesCodigos } from "@util/tipo-bienes-codigo";

type columnBien =
  | "tipo"
  | "subTipo"
  | "marca"
  | "modelo"
  | "color"
  | "serieChasis"
  | "motor"
  | "anioModelo"
  | "anioFabricacion"
  | "ubicacion" // o dirección
  | "zonaRegistral"
  | "oficinaRegistral"
  | "partidaRegistral"
  | "nombreMarca"
  | "nombreModelo"
  | "descripcionBien"
  | "areaTotal"
  | "areaConstruida";

@Directive({
  selector: "[showColumnBien]",
})
export class showColumnBienDirective {
  @Input() set showColumnBien(data: {
    tipoBienCodigo: string;
    columnas: Array<columnBien>;
  }) {
    let { tipoBienCodigo, columnas } = data;
    let show = false;
    for (let i = 0; i < columnas.length; i++) {
      if (this.showColumn(tipoBienCodigo, columnas[i])) {
        show = true;
        break;
      }
    }
    if (show) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) { }

  private showColumn(tipoBienCodigo: string, column: columnBien): boolean {
    switch (column) {
      case "tipo":
        return TipoBienesCodigos.otros == tipoBienCodigo;
      case "subTipo":
        return (
          TipoBienesCodigos.vehiculos == tipoBienCodigo ||
          TipoBienesCodigos.maquinarias == tipoBienCodigo ||
          TipoBienesCodigos.inmuebles == tipoBienCodigo
        );
      case "marca":
        return (
          TipoBienesCodigos.vehiculos == tipoBienCodigo ||
          TipoBienesCodigos.maquinarias == tipoBienCodigo
        );
      case "modelo":
        return (
          TipoBienesCodigos.vehiculos == tipoBienCodigo ||
          TipoBienesCodigos.maquinarias == tipoBienCodigo
        );
      case "color":
        return (
          TipoBienesCodigos.vehiculos == tipoBienCodigo ||
          TipoBienesCodigos.maquinarias == tipoBienCodigo
        );
      case "serieChasis":
        return (
          TipoBienesCodigos.vehiculos == tipoBienCodigo ||
          TipoBienesCodigos.maquinarias == tipoBienCodigo
        );
      case "motor":
        return (
          TipoBienesCodigos.vehiculos == tipoBienCodigo ||
          TipoBienesCodigos.maquinarias == tipoBienCodigo
        );
      case "anioModelo":
        return TipoBienesCodigos.vehiculos == tipoBienCodigo;
      case "anioFabricacion":
        return (
          TipoBienesCodigos.maquinarias == tipoBienCodigo ||
          TipoBienesCodigos.vehiculos == tipoBienCodigo
        );

      case "ubicacion":
        return TipoBienesCodigos.inmuebles == tipoBienCodigo;
      case "zonaRegistral":
        return TipoBienesCodigos.inmuebles == tipoBienCodigo;
      case "oficinaRegistral":
        return TipoBienesCodigos.inmuebles == tipoBienCodigo;
      case "partidaRegistral":
        return TipoBienesCodigos.inmuebles == tipoBienCodigo;
      case "areaTotal":
        return TipoBienesCodigos.inmuebles == tipoBienCodigo;
      case "areaConstruida":
        return TipoBienesCodigos.inmuebles == tipoBienCodigo;
      case "nombreMarca":
        return TipoBienesCodigos.otros == tipoBienCodigo;
      case "nombreModelo":
        return TipoBienesCodigos.otros == tipoBienCodigo;
      case "descripcionBien":
        return TipoBienesCodigos.otros == tipoBienCodigo;

      default:
        return true;
    }
  }
}
