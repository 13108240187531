import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs/Observable';

@Injectable({
    providedIn: 'root',
})
export class MensajeResponse {
    constructor() {}

    success(btnText: any = 'Salir', msj: any = '¡Genial, operación exitosa!'): Observable<any> {
        return new Observable((observer) => {
            this.sweetalert2(observer, btnText, msj, 'success');
        });
    }

    danger(btnText: any = 'Salir', msj: any = '¡Ooops, algo salió mal!'): Observable<any> {
        return new Observable((observer) => {
            this.sweetalert2(observer, btnText, '<center>' + msj + '</center>', 'danger');
        });
    }

    dangerPersonalizado(btnText: any , msj: any ): Observable<any> {
        return new Observable((observer) => {
            this.sweetalert2(observer, btnText, '<center>' + msj + '</center>', 'danger');
        });
    }

    dangerFiador(btnText: any = 'Atras', msj: any = '¡Ya existe como representante!'): Observable<any> {
        return new Observable((observer) => {
            this.sweetalert2(observer, btnText, '<center>' + msj + '</center>', 'danger');
        });
    }

    dangerFiadorRepetido(btnText: any = 'Atras', msj: any = '¡Ya existe como fiador!'): Observable<any> {
        return new Observable((observer) => {
            this.sweetalert2(observer, btnText, '<center>' + msj + '</center>', 'danger');
        });
    }

    dangerFiadorFirmante(btnText: any = 'Atras', msj: any = '¡Ya existe como firmante!'): Observable<any> {
        return new Observable((observer) => {
            this.sweetalert2(observer, btnText, '<center>' + msj + '</center>', 'danger');
        });
    }

    dangerFiadorCliente(btnText: any = 'Atras', msj: any = '¡Es el mismo Cliente!'): Observable<any> {
        return new Observable((observer) => {
            this.sweetalert2(observer, btnText, '<center>' + msj + '</center>', 'danger');
        });
    }

    warnig() {}
    action(msj: string, btnAceptarText: string = 'Aceptar', btnCancelarText: string = 'Cancelar'): Observable<any> {
        return new Observable((observer) => {
            Swal.fire({
                title: '<center>' + msj + '</center>',
                text: '',
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonClass: 'mat-raised-button mat-primary',
                confirmButtonClass: 'mat-raised-button mat-primary',
                confirmButtonText: btnAceptarText,
                cancelButtonText: btnCancelarText,
                allowOutsideClick: false,
                allowEscapeKey: false,
                focusConfirm: false,
                focusCancel: false,
            }).then((result) => {
                observer.next(result.value);
                observer.complete();
            });
        });
    }
    action2(msj: string, btnAceptarText: string = 'Si', btnCancelarText: string = 'No'): Observable<any> {
        return new Observable((observer) => {
            Swal.fire({
                title: '<center>' + msj + '</center>',
                text: '',
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonClass: 'mat-raised-button mat-primary',
                confirmButtonClass: 'mat-raised-button mat-primary',
                confirmButtonText: btnAceptarText,
                cancelButtonText: btnCancelarText,
                allowOutsideClick: false,
                allowEscapeKey: false,
                focusConfirm: false,
                focusCancel: false,
            }).then((result) => {
                observer.next(result.value);
                observer.complete();
            });
        });
    }
    actionComentario(
        title: string,
        msj: string,
        reuqerido: boolean = true,
        btnAceptarText: string = 'Aceptar',
        btnCancelarText: string = 'Cancelar'
    ): Observable<ResultValue> {
        return new Observable((observer) => {
            Swal.fire({
                title: title,
                html: `
                <div class="text-left mt-3">
                    <h7>${msj}</h7>
                </div>
                <div class="text-left mt-3 mb-0 fs-14">
                    <label class="${reuqerido ? 'required' : ''}">${
                    msj == '¿Está seguro de no admitir el expediente?' ? 'Motivo de Observación' : 'Comentario:'
                }</label>
                </div>
                `,
                input: 'textarea',
                inputAttributes: {
                    rows: '6',
                },
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonClass: 'mat-raised-button mat-primary',
                confirmButtonClass: 'mat-raised-button mat-primary',
                confirmButtonText: btnAceptarText,
                cancelButtonText: btnCancelarText,
                allowOutsideClick: false,
                allowEscapeKey: false,
                focusConfirm: false,
                focusCancel: false,
                preConfirm: (value) => {
                    if (reuqerido && (!value || value.trim() === '')) {
                        return Swal.showValidationMessage(`<div class="justify-content-end col d-flex">
                                    <span>El campo es requerido.</span>
                                </div>`);
                    }
                    return value;
                },
            }).then((result) => {
                const res: ResultValue = {
                    result: !result.dismiss,
                    value: result.value || '',
                };
                observer.next(res);
                observer.complete();
            });
        });
    }

    infoDefault(title: string, msj: string, btnAceptarText: string = 'Aceptar'): Observable<any> {
        return new Observable((observer) => {
            Swal.fire({
                title: title,
                text: msj,
                confirmButtonClass: 'mat-raised-button mat-primary',
                confirmButtonText: btnAceptarText,
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((result) => {
                observer.next(result.value);
                observer.complete();
            });
        });
    }

    sweetalert2(observer, btnText, msj, tipo) {
        Swal.fire({
            title: '<div style="text-align: center;">' + msj + '</div>',
            customClass: 'swal2-alert swal2-alert-' + tipo,
            confirmButtonText: `${btnText}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            observer.next(result.value);
            observer.complete();
        });
    }
}

interface Subtipo {
    idsubtipo: string;
    descripcion: string;
    seleccionado: boolean;
}

interface ResultValue {
    result: boolean;
    value: string;
}
