import { Component, ElementRef, AfterViewInit, Inject  } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { DOCUMENT } from '@angular/common';
declare var jQuery:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    public apiKey: string = environment.apiKey;
    constructor(
      @Inject(DOCUMENT) private document: Document, 
            private elementRef:ElementRef
    ){
      this.apiKey = environment.apiKey;
    }
    ngAfterViewInit(){
      var script = this.document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://maps.googleapis.com/maps/api/js?key="+this.apiKey;
      this.elementRef.nativeElement.appendChild(script);
    }
  }
