import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerServicesService } from './services/spinner-services.service';

@Injectable({
    providedIn: 'root',
})
export class SpinnerService implements HttpInterceptor {
    constructor(private _servicesService: SpinnerServicesService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this._servicesService.onSpinner();
        return next.handle(req).pipe(finalize(() => this._servicesService.offSpinner()));
    }
}
