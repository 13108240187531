import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService_v3 } from '../app/views/login/login.service';
import { SesionService } from '@service/sesion.service';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators/map';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private _router: Router , private _login: LoginService_v3, private sesion: SesionService) { }
    canActivate() {
        if (this._login.isLoggedIn()) {
            this._router.navigate(['/modulos']);
        }
        return !this._login.isLoggedIn();
    }

}
