import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-common-body-simple',
  templateUrl: './body-simple.template.html'
})
export class BodySimpleComponent implements OnInit {
  @Input() titulo;
  @Input() cabecera;
  @Input() full;
  @Input() showTitle: boolean;
  @Input() showFilters: boolean;
  mostrar:boolean;
  constructor() {
    this.showTitle = true;
    this.showFilters = false;
    this.mostrar = true;
  }

  ngOnInit() {
  }

  ocultarFiltro(){
    this.mostrar = !this.mostrar;
  }
}
