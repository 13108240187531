import { NgModule } from "@angular/core";
import { BuscadorComponent } from "./buscador.component";
import { SelectDefaultModule } from "@common/selects/select-default/select-default.module";
import { DirectivasModule } from "@directives/directivas.module";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";



@NgModule({
    declarations: [BuscadorComponent],
    imports: [
        CommonModule,
        DirectivasModule,
        SelectDefaultModule,
        FormsModule

    ],
    exports: [BuscadorComponent]
})

export class BuscadorModule { }
