import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVICIO_SEGURIDAD } from 'config/services';
import { Observable } from 'rxjs/internal/Observable';
import { API_VERSION } from 'config/api';
import { dataSesion } from '../../services/sesion.service';


@Injectable({
  providedIn: "root"
})
export class LoginService {
  constructor(private _http: HttpClient) {
  }
  logout(): Observable<boolean> {
    return new Observable((observer) => {
      dataSesion.id = 0;
      dataSesion.token = "";
      dataSesion.cargo = "";
      dataSesion.nombre = "";
      dataSesion.sucursal = "";
      dataSesion.perfil = "";
      dataSesion.codigoperfil = "";
      dataSesion.idperfil = 0;
      localStorage.removeItem('data_user');
      observer.next(true);
      observer.complete();
    });
  }
  isLogin(): Observable<boolean> {
    return new Observable((observer) => {
      if (this.getdataUser() != null && this.getdataUser().access_token) {
        let userAux = this.getdataUser();

        dataSesion.id = userAux.datos;
        dataSesion.token = userAux.access_token;
        dataSesion.cargo = userAux.cargo;
        dataSesion.nombre = userAux.nombre;
        dataSesion.sucursal = userAux.sucursal;
        dataSesion.perfil = userAux.perfil[0].nombre;
        dataSesion.codigoperfil = userAux.perfil[0].codigo;
        dataSesion.idperfil = userAux.perfil[0].id;

        observer.next(true);
      }
      observer.next(false);
      observer.complete();
    });

  }
  login(data): Observable<boolean> {

    return new Observable((observer) => {

      data.tipo_acceso = "web";
      return this._http
        .post(`${API_VERSION.v1}riesgos-formularios/auth-clientes/login`, data)
        .subscribe((data: any) => {
          dataSesion.id = data.id;
          dataSesion.token = data.access_token;
          dataSesion.nombre = data.nombres;
          // dataSesion.sucursal = data.sucursal;
          dataSesion.perfil = data.perfiles[0].nombre;
          dataSesion.codigoperfil = data.perfiles[0].codigo;
          dataSesion.idperfil = data.perfiles[0].id;
          observer.next(data);
          observer.complete();
        }, error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  dataUser(data) {
    data.access_token = btoa(btoa(btoa(data.access_token)));
    localStorage.setItem('data_user', JSON.stringify(data));
  }
  getdataUser() {
    return JSON.parse(localStorage.getItem('data_user'));
  }


}

