import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import { CalendarioRightComponent } from "./calendario-right.component";
import { CommonModule } from "@angular/common";




@NgModule({
    declarations: [CalendarioRightComponent],
    imports     : [CommonModule, RouterModule],
    exports: [CalendarioRightComponent]
})

export class CalendarioRightModule {}
