import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@pipes/pipes.module';
import { ExpedienteDetalleDocumentosSinBotonComponent } from './expediente-detalle-documentos.component';

@NgModule({
    imports: [
        CommonModule,
        PipesModule
    ],
    declarations: [
        ExpedienteDetalleDocumentosSinBotonComponent
    ],
    exports: [
        ExpedienteDetalleDocumentosSinBotonComponent
    ]
})
export class CommonExpedienteDetalleDocumentosWithoutButonModule { }
