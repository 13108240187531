import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SesionService } from '@service/sesion.service';
import { dataSesion } from '../services/sesion.service';

@Directive({
  selector: '[rolesShow]'
})
export class RolesShowDirective {

  @Input() set rolesShow(roles: Array<string>) {
    if (roles.indexOf(dataSesion.perfil) > -1 || roles.indexOf(dataSesion.codigoperfil) > -1) {
      this._viewContainerRef.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainerRef.clear();
    }
  }
  constructor(private _templateRef: TemplateRef<any>, private _viewContainerRef: ViewContainerRef, private _sesionService: SesionService) { }

}
