import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SesionService } from './services/sesion.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class AppSingletonModule {

  constructor (@Optional() @SkipSelf() parentModule: AppSingletonModule) {
    if (parentModule) {
    //   throw new Error(
    //     'AppSingleton ya esta cargado');
    }
  }

  static forRoot(config: SesionService): ModuleWithProviders {
    return {
      ngModule: AppSingletonModule,
      providers: [
        {provide: SesionService, useValue: config },
      ]
    };
  }


 }
