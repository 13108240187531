import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { API_ARCHIVOS } from 'config/services';
import { DescargarArchivoService } from '@service/exportar-archivo.service';
import '@fancyapps/fancybox';
import { ChecklistDocumentos } from '@models/riesgos-admision/admision/admision-checklist-doumentos.model';
import { ExpedienteDetalleDocumentosService } from './expediente-detalle-documentos.service';
import { MensajeResponse } from '@util/mensaje-response';
import { OrdenComprasService } from 'app/views/operaciones/operaciones/expediente/orden-compras/orden-compras.service';

declare var $: any;

@Component({
    selector: 'common-expediente-detalle-documentos-sin-boton',
    templateUrl: 'expediente-detalle-documentos.template.html'
})
export class ExpedienteDetalleDocumentosSinBotonComponent implements OnInit, OnDestroy {
    @ViewChild('ExpedienteDetalleDocumentos', { static: true }) expedienteDetalleDocumentos: ElementRef;
    idExpediente: number;
    titulo: string;
    show: boolean;
    modulo: string;
    caf: any;
    idDocumento: number;
    idGarantia: number;
    eliminar: boolean;
    @Output() emitEliminar: EventEmitter<any> = new EventEmitter();
    idOperacion: number;
    @Output() cerrar = new EventEmitter();

    public documentos: ChecklistDocumentos[];
    public selectItemDocumentos: ChecklistDocumentos;
    public loading: boolean;
    @Input() flag: number;
    @Input() temporalDocuments: ChecklistDocumentos[];

    constructor(
        private expedienteDetalleDocumentosService: ExpedienteDetalleDocumentosService,
        private descargarArchivoService: DescargarArchivoService,
        private mensajeResponse: MensajeResponse,
        private ordenComprasService: OrdenComprasService
    ) {
        this.idExpediente = 0;
        this.idDocumento = 0;
        this.selectItemDocumentos = <ChecklistDocumentos>{};
        this.loading = false;
        this.titulo = "";
        this.documentos = [];
        this.eliminar = false;
    }

    ngOnInit() {
        $('[data-toggle="tooltip"]').tooltip();
    }
    ngOnDestroy() {
        $('[data-toggle="tooltip"]').tooltip('dispose');
    }
    ngOnChanges() {
        if (this.show === true) {
            this.detalle(this.titulo, this.idExpediente, this.idDocumento, this.modulo, this.idGarantia, this.temporalDocuments)
        }

        if (this.flag === 2) {
            this.ordenComprasService
                .verFactura(this.idOperacion)
                .subscribe(
                    (response: any) => {
                        this.documentos = response.data
                    })
        } else if (this.flag === 3) {
            this.ordenComprasService
                .verDocProveedor(this.idOperacion)
                .subscribe(
                    (response: any) => {
                        this.documentos = response.data
                    })
        } if (this.flag === undefined) {
            if (this.idOperacion !== undefined) {
                this.expedienteDetalleDocumentosService.getFiles(this.idExpediente, this.idOperacion).subscribe((response: any) => {
                    this.documentos = response.data
                })
            }
        }
    }
    salir() {
        this.documentos = [];
        $(this.expedienteDetalleDocumentos.nativeElement).modal("hide");
        this.selectItemDocumentos = <ChecklistDocumentos>{};

        if (this.show === true) {
            this.cerrar.emit();
        }
    }
    verDocumento() {
        let image = /(.jpg|.jpeg|.png|.gif)/i;
        let pdf = /(.pdf)/i;
        let data: any = {
            src: (this.selectItemDocumentos.iddoc != -1) ? API_ARCHIVOS + this.selectItemDocumentos.ruta_archivo : this.selectItemDocumentos.ruta_archivo,
        };
        if (image.exec(this.selectItemDocumentos.nombre)) {
            data.type = "image";
            $.fancybox.open([data]);
        } else if (pdf.exec(this.selectItemDocumentos.nombre)) {
            data.type = "iframe";
            $.fancybox.open([data]);
        } else {
            if (this.flag === 2 || this.flag === 3) {
                let extencion = this.selectItemDocumentos.nombre_archivo ? this.selectItemDocumentos.nombre_archivo.split('.') : this.selectItemDocumentos.ruta_archivo.split('.');
                this.descargarArchivoService.exportar(
                    data.src,
                    extencion[0],
                    extencion[extencion.length - 1]
                ).subscribe();
            } else {
                let extencion = this.selectItemDocumentos.nombre.split('.');
                this.descargarArchivoService.exportar(
                    data.src,
                    extencion[0],
                    extencion[extencion.length - 1]
                ).subscribe();
            }
        }
    }
    listarDocumentos() {
        this.loading = true;
        switch (this.modulo || '') {
            case 'garantias':
                if (this.idGarantia == null) {
                    this.loading = false;
                    this.documentos = this.temporalDocuments;
                    break;
                }
                this.expedienteDetalleDocumentosService.listarDocumentosGarantia(this.idExpediente, this.idDocumento, this.idGarantia).subscribe(
                    response => {
                        this.documentos = response;
                        if (this.temporalDocuments != undefined)
                            this.documentos.push(...this.temporalDocuments);
                    },
                    error => this.loading = false,
                    () => {
                        this.loading = false;
                    }
                );
                break;
            default:
                this.expedienteDetalleDocumentosService.listarDocumentos(this.idExpediente, this.idDocumento).subscribe(
                    response => this.documentos = response,
                    error => this.loading = false,
                    () => {
                        this.loading = false;
                    }
                );
                break;
        }

    }
    detalle(titulo: string, idExpediente: number, idDocumento: number, modulo: string, idGarantia: number, temporalDocuments: ChecklistDocumentos[]) {
        this.titulo = titulo;
        this.idExpediente = idExpediente;
        this.idDocumento = idDocumento;
        this.modulo = modulo;
        this.idGarantia = idGarantia;
        this.temporalDocuments = temporalDocuments;
        $(this.expedienteDetalleDocumentos.nativeElement).modal("show");
        if (this.show !== true) {
            this.listarDocumentos();
        }
    }
    eliminarDocumento() {
        this.mensajeResponse.action('¿Estás seguro de eliminar el archivo?').subscribe(result => {
            if (result) {
                this.loading = true;
                this.expedienteDetalleDocumentosService.eliminarDocumentos(this.selectItemDocumentos.idarchivo).subscribe(
                    response => {
                        this.emitEliminar.emit();
                        this.listarDocumentos();
                        this.salir();
                    }
                    ,
                    error => {
                        this.loading = false;
                        this.mensajeResponse.danger().subscribe();
                    }
                );
            }
        });
    }
}
