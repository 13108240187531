import { AfterViewInit, Directive, ElementRef, Input, OnInit, Output, EventEmitter } from '@angular/core';

declare var $: any;

@Directive({
    selector: '[clockpicker]'
})
export class ClockpickerDirective implements OnInit, AfterViewInit {
    select: any;
    @Input() view: any;
    @Input() default: any; // HH:mm
    @Input() autoClose: any;
    @Input() doneText: any;

    @Output() onSelect = new EventEmitter();
    constructor(private el: ElementRef) {
        this.default = 'now';
        this.doneText = 'Aceptar';
        this.autoClose = true;

    }

    ngOnInit() { }
    ngAfterViewInit() {
        this.init();
    }

    private init() {
        $(this.el.nativeElement).clockpicker({
            placement: 'bottom',
            align: 'left',
            default: this.default,
            donetext: this.doneText,
            autoclose: this.autoClose,
            afterDone: (e) => {
                this.onSelect.emit(this.el.nativeElement.value);
            }

        }
        );
    }
}
