import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_URL, SERVICIO_MODULO_COMERCIAL } from 'config/services';
import { map } from 'rxjs/internal/operators/map';
@Injectable({
    providedIn: 'root',
})
export class ExpedientesService {
    constructor(private _http: HttpClient) {}

    public listar(data) {
        return this._http.post(API_URL + 'riesgos/admision', data);
    }

    listarEstados() {
        return this._http.get(API_URL + 'comercial/util/expediente/estado/riesgo').pipe(map((response: any) => response.data));
    }

    listarEstadosModulo(modulo: string) {
        let params = new HttpParams();
        params = params.append('modulo', modulo);
        return this._http.get(SERVICIO_MODULO_COMERCIAL.util.listarEstadosPorModulo, { params }).pipe(map((response: any) => response.data));
    }

    listarTipos() {
        return this._http.get(API_URL + 'comercial/util/expediente/tipo_op').pipe(map((response: any) => response.data));
    }

    listarSubTipos() {
        return this._http.get(API_URL + 'comercial/util/expediente/sub_tipo').pipe(map((response: any) => response.data));
    }
}
