export class CondicionEvaluacion {
    public tea: number;
    public plazo: number;
    public cuotaInicial: number;
    public porcentajeInicial: number;
    public tipoCuota: boolean;
    public periodoGracia: number;
    public id: number;
    public montoBruto: number;
    public vafTotal: number;
    public cuotaMensual: number;
    public moneda: string;
    public garantias: Array<any>;
    public fianzaSolidaria: boolean;
    public fianzaSolidariaComentario: string;
    public incluyeGarantia: boolean;
    public cantidadGarantiaComercial: number;
    public idTipoOperacion: number;
    public custodio: string;
    public cambioMoneda: number;
    public tipoCambio: number;
    public otrasCondiciones: string;
    constructor(obj: any) {
        this.tea = obj.tea || 0;
        this.plazo = obj.plazo || 0;
        this.cuotaInicial = obj.cuota_inicial || 0;
        this.porcentajeInicial = obj.porcentaje_inicial || 0;
        this.tipoCuota = obj.tipo_cuota || 0;
        this.periodoGracia = obj.periodo_gracia || 0;
        this.id = obj.idcotizacion || null;
        this.montoBruto = obj.valor_bruto_total || 0;
        this.vafTotal = obj.valor_financiar || 0;
        this.cuotaMensual = obj.cuota_mensual || 0;
        this.moneda = obj.moneda_simbolo || 0;
        this.garantias = obj.garantias || [];
        this.fianzaSolidaria = obj.con_fianza_solidaria || false;
        this.fianzaSolidariaComentario = obj.comentario_fianza || null;
        this.incluyeGarantia = obj.incluye_garantia || false;
        this.cantidadGarantiaComercial = obj.cantidad_garantia_comercial || 0;
        this.idTipoOperacion = obj.idtipo_operacion || 0;
        this.custodio = obj.custodio || ''
        this.cambioMoneda = obj.cambio_moneda || 0;
        this.tipoCambio = obj.tipo_cambio || 0;
        this.otrasCondiciones = obj.otras_condiciones || '';
    }
}
