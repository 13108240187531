import { Component, OnInit, OnDestroy } from '@angular/core';
import { fadeAnimation } from 'app/animations/fade.animation';
import { NavigationService } from '@common/navigation/navigation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var jQuery: any;

@Component({
    selector: 'basic-component',
    templateUrl: 'basic.template.html',
    animations: [fadeAnimation],
    styleUrls: ['./basic.component.scss']
})
export class BasicComponent implements OnInit, OnDestroy {
    public isOpen: boolean;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private navigationService: NavigationService
    ){
        this.isOpen = false
    }
    ngOnInit(){
        this.navigationService.changeToggleMenu.pipe(takeUntil(this.destroy$)).subscribe(isOpen => {
            this.isOpen = isOpen;
        });
    }
    ngOnDestroy(){
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
 }
