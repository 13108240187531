import { API_URL } from './comun';
import { environment } from '@environments/environment';
import { API_VERSION } from '../api-version';

const API_V1_PROBICIONAL = `${environment.API_URL}${API_VERSION.v1}`;
const API_V2_PROBICIONAL = `${environment.API_URL}${API_VERSION.v2}`;

export const SERVICIO_EXPEDIENTES = {
    'v1.0': {
        porAprobasion: {
            informeFinal: {
                valiedezOperacion: `${API_V1_PROBICIONAL}expediente/validez/operacion/comite`,
                listarOperaciones: (expedienteId: number) => {
                    return `${API_V1_PROBICIONAL}expediente/${expedienteId}/operaciones/comite`;
                },
                recalcularCotizacion: (expedienteId: number) => {
                    return `${API_V1_PROBICIONAL}preview/${expedienteId}/comite`;
                },
            },
        },
        condicionesAnalista: {
            listarCondiciones: (expedienteId: number) => {
                return `${API_URL}expediente/${expedienteId}/condiciones-analista`;
            },
            guardarCondiciones: (expedienteId: number) => {
                return `${API_URL}expediente/${expedienteId}/guardar-condiciones-analista`;
            },
            saveWarrantyDocuments: `${API_URL}expediente/guardar-checklist-garantia`,
        },
    },
    'v1.1': {
        porAprobasion: {
            informeFinal: {
                valiedezOperacion: `${API_V2_PROBICIONAL}expediente/validez/operacion/comite`,
                listarOperaciones: (expedienteId: number) => {
                    return `${API_V2_PROBICIONAL}expediente/${expedienteId}/operaciones/comite`;
                },
                recalcularCotizacion: (expedienteId: number) => {
                    return `${API_V2_PROBICIONAL}preview/${expedienteId}/comite`;
                },
                getFiles: (idExpediente: number, idOperacion: number) => {
                    return `${API_V2_PROBICIONAL}expediente/get_files/${idExpediente}/${idOperacion}`;
                },
            },
        },
    },
    porAprobasion: {
        guardar: (expedienteId: number) => {
            return `${API_URL}expediente/${expedienteId}/guardar`;
        },
    },
    ultimaCorrida: {
        listar: `${API_URL}expediente/corrida-generada`
    }
}
