import { API_URL, API_URL2 } from './comun';
import { environment } from '@environments/environment';
import { VERSION_API } from 'config/services';

export const SERVICIOS_OPERACIONES = {
    operaciones: {},
    expediente: {
        contrato: {
            cruContrato: (idOperacion, expedienteId) => {
                return `operaciones/caf/operacion/${idOperacion}/detalles/${expedienteId}`;
            },
        },
        listarExpedienteNuevo: `${API_URL}expedientes/aprobados`,
        detalle: `expediente/detalle`,
        caf: {
            cambiarCuota: (cafId: number) => {
                return `operaciones/caf/operacion/${cafId}/cuota-cero`;
            },
            listarCaf: (expedienteId: number) => {
                return `operaciones/expediente/${expedienteId}/cafs`;
            },
            guardarCaf: (expedienteId: number) => {
                return `operaciones/expedientes/${expedienteId}/cafs`;
            },
            anexosCaf: (cafId: number) => {
                return `operaciones/pdf/${cafId}/anexos`;
            },
            anexosCafLegal: (cafId: number) => {
                return `operaciones/pdf/${cafId}/anexos_legal`;
            },
            crearAnexosCaf: (cafId: number) => {
                return `operaciones/pdf/${cafId}/anexos`;
            },
            detalleCaf: (expedienteId: number) => {
                return `operaciones/expedientes/${expedienteId}/cafs`;
            },
            enviarLegal: (cafOperacionId: number) => {
                return `operaciones/caf-operacion/${cafOperacionId}/enviar-a-legal`;
            },
            compararEnviarLegal: (cafOperacionId: number) => {
                return `operaciones/caf-operacion/${cafOperacionId}/enviar-a-legal`;
            },
            editar: (cafOperacionId: number, idExpediente: number) => {
                return `operaciones/caf-operacion/${cafOperacionId}/fecha-activacion/${idExpediente}`;
            },
            exportarExcel: (cafOperacionId: number) => {
                return `${API_URL}caf-operacion/${cafOperacionId}/excel`;
            },
            obtenerSecuencial: (idExpediente: number) => {
                return `${API_URL}caf/operacion/${idExpediente}/bien-caf`;
            },
            editarSecuencial: (idCaf: number) => {
                return `${API_URL}caf/operacion/${idCaf}/editar-caf-secuencial`;
            },
            modificarCafPosts: () => {
                return `operaciones/caf/operacion/editar-fechas-tabla`;
            },
            modificarCafGet: (id: number) => {
                return `operaciones/caf/operacion/${id}/editar-fechas-tabla`;
            },
            modificarCafPut: (id: number) => {
                return `operaciones/caf/operacion/${id}/editar-fechas-tabla`;
            },
            guardarCafPut: () => {
                return `operaciones/caf/operacion/editar-fechas-tabla`;
            },
            migrar: () => {
                return `${API_URL}expedientes/mandar_a_soft/cafs`;
            },
        },
        bienes: {
            listar: (expedienteId: number) => {
                return `${API_URL}expedientes/${expedienteId}/operaciones/aprobadas`;
            },
            obtener: (operacionId: number) => {
                return `${API_URL}operaciones/${operacionId}`;
            },
            editar: (operacionId: number) => {
                return `${API_URL}operaciones/${operacionId}`;
            },
            guardarSubOperacion: (operacionId: number) => {
                return `${API_URL}operaciones/${operacionId}/suboperaciones`;
            },
            obtenerSubOperacion: (operacionId: number, subOperacionId: number) => {
                return `${API_URL}operaciones/${operacionId}/suboperaciones/${subOperacionId}`;
            },
            editarSubOperacion: (operacionId: number, subOperacionId: number) => {
                return `${API_URL}operaciones/${operacionId}/suboperaciones/${subOperacionId}`;
            },
            eliminarSubOperacion: (operacionId: number, subOperacionId: number) => {
                return `${API_URL}operaciones/${operacionId}/suboperaciones/${subOperacionId}`;
            },
            tasaciones: {
                listar: (operacionId: number) => {
                    return `${API_URL}operaciones/${operacionId}/tasaciones`;
                },
                obtener: (tasacionId: number) => {
                    return `${API_URL}tasaciones/${tasacionId}`;
                },
                editar: (tasacionId: number) => {
                    return `${API_URL}tasaciones/${tasacionId}`;
                },
            },
        },
        cliente: {
            listarCliente: (expedienteId: number) => {
                return `${API_URL}expedientes/${expedienteId}/detalle-totales`;
            },
            detalleCliente: (clienteId: number) => {
                return `${API_URL}clientes/${clienteId}/detalle`;
            },
            obtener: (clienteId: number, idExpediente: number) => {
                return `${API_URL}clientes/${clienteId}/${idExpediente}`;
            },
            consultarRuc: (rucId: number) => {
                return `${API_URL}consulta-ruc/${rucId}`;
            },
            oficinasRegistrales: (oficinaRegistralId: number) => {
                return `${API_URL}zonas-registrales/${oficinaRegistralId}/oficinas-registrales`;
            },
            combos: `${API_URL}clientes/utiles`,
            editar: {
                editarCliente: (clienteId: number, idExpediente: number) => {
                    return `${API_URL}clientes/${clienteId}/${idExpediente}`;
                },
                dataRcc: `${API_URL}data-rcc/codigo-sbs`,
                dataRccCalcular: `${API_URL}data-rcc/codigo-tipo-credito`,
            },

            contactos: {
                listarContactos: (clienteId: number, expId: number) => {
                    return `${API_URL}clientes/${clienteId}/contactos/${expId}`;
                },
                obtener: (clienteId: number, contactosId: number, expId: number) => {
                    return `${API_URL}clientes/${clienteId}/contactos/${contactosId}/${expId}`;
                },
                editar: (clienteId: number, contactosId: number, expId: number) => {
                    return `${API_URL}clientes/${clienteId}/contactos/${contactosId}/${expId}`;
                },
                detalle: (clienteId: number, contactosId: number) => {
                    return `${API_URL}clientes/${clienteId}/contactos/${contactosId}`;
                },
                guardar: (clienteId: number, expId: number) => {
                    return `${API_URL}clientes/${clienteId}/contactos/${expId}`;
                },
                eliminar: (clienteId: number, contactosId: number, expId: number) => {
                    return `${API_URL}clientes/${clienteId}/contactos/${contactosId}/${expId}`;
                },
            },
        },
        checklist: {
            listar: (expedienteId: number) => {
                return `${API_URL}expediente/${expedienteId}/checklist`;
            },
            historial: `${API_URL}expediente/historial/checklist`,
            subirDocumentos: `${API_URL}expediente/checklist/upload`,
            subirDocumentosSimples: `${environment.API_URL}api/v1.0/expediente/documento/checklist/`,
            checkDocumento: `${API_URL}expediente/checklist/check/uncheck`,
            validarDocumento: `${API_URL}expediente/checklist/validar/invalidar`,
            listarDocumentos: (expedienteId: number, documentoId: number, seccionId: number, subseccionId: number) => {
                return `${API_URL}expediente/${expedienteId}/documento/${documentoId}/seccion/${seccionId}/subseccion/${subseccionId}/archivos`;
            },
            listarArchivosSimples: (idExpediente: number, idDocumento: number) => {
                return `${environment.API_URL}api/v1.0/expediente/${idExpediente}/documento/${idDocumento}/checklist`;
            },
            fieleANotaria: (expedienteId: number) => {
                return `${API_URL}expediente/${expedienteId}/file-a-notaria`;
            },
        },
        fileNoteria: {
            listar: (expedienteId: number) => {
                return `operaciones/expediente/${expedienteId}/file-a-notaria/checklist`;
            },
            listarLegal: (expedienteId: number, id_caf: number) => {
                return `operaciones/expediente/${expedienteId}/file-a-notaria/checklist?caf=${id_caf}`;
            },
            subirDocumentos: `operaciones/expediente/checklist/file-a-notaria/upload`,
            listarDocumentos: (expedienteId: number, documentoId: number, seccionId: number, subseccionId: number) => {
                return `operaciones/expediente/${expedienteId}/documento/${documentoId}/seccion/${seccionId}/subseccion/${subseccionId}/archivos/file-a-notaria`;
            },
            historial: `operaciones/expediente/historial/checklist/file-a-notaria`,
            enviarANotaria: (expedienteId: number) => {
                return `operaciones/expediente/${expedienteId}/enviar-a-notaria`;
            },
            finalizar: (expedienteId: number) => {
                return `operaciones/expediente/${expedienteId}/file-a-notaria/finalizar`;
            },
            vigente: `operaciones/expediente/checklist/file-a-notaria/vigente`,
            check: `operaciones/expediente/checklist/file-a-notaria/check/uncheck`,
            config: `operaciones/expediente/checklist/file-a-notaria/configuracion`,
            descargar: (expedienteId: number) => {
                return `operaciones/expediente/${expedienteId}/file-a-notaria/descargar`;
            },
        },
        garantias: {
            listar: (expedienteId: number) => {
                return `${API_URL}expedientes/${expedienteId}/garantias`;
            },
            obtener: (garantiaId: number, idExpediente: number) => {
                return `operaciones/garantias/${garantiaId}/${idExpediente}`;
            },
            editar: (garantiaId: number, idExpediente: number) => {
                return `operaciones/garantias/${garantiaId}/${idExpediente}`;
            },
            guardarOtorgante: (idExpediente: number) => {
                return `operaciones/otorgantes/${idExpediente}`;
            },
            buscarOtorgante: (tipoDocumentoId: number, numeroDocumento: any) => {
                return `operaciones/otorgantes/consultar?tipo_documento_id=${tipoDocumentoId}&numero_documento=${numeroDocumento}`;
            },
            enviarLegal: (garantiaId: number) => {
                return `operaciones/garantias/${garantiaId}/email`;
            },
            tasaciones: {
                listar: (garantiaId: number) => {
                    return `${API_URL}garantias/${garantiaId}/tasaciones`;
                },
                obtener: (tasacionId: number) => {
                    return `${API_URL}tasaciones/${tasacionId}`;
                },
                editar: (tasacionId: number) => {
                    return `${API_URL}tasaciones/${tasacionId}`;
                },
            },
        },

        ordenCompras: {
            listar: (expedienteId: number) => {
                return `${API_URL}expedientes/${expedienteId}/ordenes-compras`;
            },
            oc: (id: number) => {
                return `${API_URL}orden-compra/${id}/enviar-proveedores`;
            },
            docProveedor: (id: number) => {
                return `${API_URL}orden-compra/${id}/enviar-proveedores`;
            },
            factura: (id: number) => {
                return `${API_URL}orden-compra/factura/${id}`;
            },
            agregarCarta: (expedienteId: number) => {
                return `${API_URL}ordenes-compras/${expedienteId}/carta-adelanto`;
            },
            getCartaAdelanto: (expedienteId: number) => {
                return `${API_URL}ordenes-compras/${expedienteId}/carta-adelanto`;
            },
            vistaConstancia: (ordenesComprasId: number) => {
                return `${API_URL}ordenes-compras/${ordenesComprasId}/pdf?tipo=constancia`;
            },
            vistaOrdenCompra: (ordenesComprasId: number) => {
                return `${API_URL}ordenes-compras/${ordenesComprasId}/pdf`;
            },
            enviarCorreo: (ordenesComprasId: number) => {
                return `${API_URL}ordenes-compras/${ordenesComprasId}/pdf`;
            },
            enviarCorreoGPS: (ordenesComprasId: number) => {
                return `${API_URL}ordenes-compras/${ordenesComprasId}/solicitud-gps`;
            },
            listarCFAs: (expedienteId: number) => {
                return `operaciones/expedientes/${expedienteId}/cafs/combo`;
            },
            listarProveedores: (cafId: number) => {
                return `operaciones/${cafId}/proveedores`;
            },
            listarSucursales: (cafId: number, proveedorId) => {
                return `operaciones/${cafId}/proveedores/${proveedorId}/sucursales`;
            },
            obtener: (cafOperacionId: number, sucursalId: number) => {
                return `operaciones/ordenes-compras/nuevo?caf_operacion_id=${cafOperacionId}&sucursal_id=${sucursalId}`;
            },
            verPdf: `operaciones/ordenes-compras/preview`,
            guardar: `operaciones/ordenes-compras`,
        },
    },
    desembolso: {
        listar: (expedienteId: number) => {
            return `${API_URL2}expediente/${expedienteId}/checklistdesembolso`;
        },
        devolver: (expedienteId: number) => {
            return `${API_URL}expedientes/${expedienteId}/devolver-comercial`;
        },
        motivos: () => {
            return `${API_URL}expedientes/motivos-devolucion`;
        },
        listarCorreos: (expedienteId: number) => {
            return `${API_URL2}expediente/${expedienteId}/checklistdesembolso/envio-tesoreria`;
        },
        historial: `${API_URL2}expediente/historial/checklist/`,
        listarDocumentos: (idExpediente: number, idDocumento: number, idSeccion: number, idSubseccion: number) => {
            return `${API_URL2}expediente/${idExpediente}/documento/${idDocumento}/seccion/${idSeccion}/subseccion/${idSubseccion}/archivos`;
        },
        subirDocumentosDesembolso: `${API_URL2}expediente/documento/checklist/`,
    },
};
