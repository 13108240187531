import {
    Component,
    OnInit,
    QueryList,
    ContentChildren,
    Output,
    EventEmitter,
    AfterContentInit,
    Input
} from '@angular/core';
import { CommonAccordionContainerComponent } from './accordion-container/accordion-container.component';

@Component({
    selector: 'common-accordion',
    templateUrl: './accordion.template.html',
    styleUrls: [
        './accordion.scss'
    ]
})
export class CommonAccordionComponent implements OnInit, AfterContentInit {
    @ContentChildren(CommonAccordionContainerComponent)
    accordionContainer: QueryList<CommonAccordionContainerComponent>;
    @Input() show: boolean;
    @Output() seleccionado = new EventEmitter();
    @Input() ocultarContenido: boolean;
    constructor() {
        this.show = false;
        this.ocultarContenido = true;
    }
    ngAfterContentInit() {
        if (this.show) {
            this.accordionContainer.toArray().forEach(value => value.show = true);
        }
        if (this.ocultarContenido === false) {
            this.accordionContainer.toArray().forEach(value => value.ocultarContenido = false);
        }
    }
    ngOnInit() {

    }
}
