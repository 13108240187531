import { Component } from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
import { Router } from '@angular/router';
import { LoginService_v2 } from 'app/views/login/login.service';
declare var jQuery: any;


@Component({
    selector: 'topnavbar-modulos',
    templateUrl: 'topnavbar-modulos.template.html'
})
export class TopnavbarModulosComponent {
    constructor(private router: Router, private loginService: LoginService_v2) { }
    toggleNavigation(): void {
        jQuery("body").toggleClass("mini-navbar");
        smoothlyMenu();
    }
    activeRoute(routename: string): boolean {
        return this.router.url.indexOf(routename) > -1;
    }


    logout() {
        this.loginService.logout().subscribe(d => {
          this.router.navigate(['/login'])
        })
    }

}
