import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

declare var $: any;

@Component({
    selector: 'editor-texto-component',
    templateUrl: 'editor-texto.template.html'
})

export class EditorTextoComponent implements OnInit {
    @Input() comentario: string = ""
    @Input() height: string = "auto"
    @Input() editable: boolean = false
    @Output() comentarioChange = new EventEmitter<string>();

    editorConfig: AngularEditorConfig = {
        editable: false,
        spellcheck: false,
        height: 'auto',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: false,
        placeholder: '',
        defaultParagraphSeparator: '',
        defaultFontName: 'Arial',
        defaultFontSize: '',
        fonts: [
            { class: 'arial', name: 'Arial' },
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        uploadUrl: 'v1/image',
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['indent',
                'outdent',
                'redo',
                'subscript',
                'justifyLeft',
                'justifyCenter',
                'justifyRight',
                'justifyFull',
                'superscript',
                'insertUnorderedList',
                'insertOrderedList'
            ], [
                'fontSize',
                'customClasses',
                'link',
                'unlink',
                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
                'toggleEditorMode',
            ],
        ]
    };

    constructor() { }

    ngOnInit(): void {
        this.editorConfig.height = this.height
        this.editorConfig.editable = this.editable
        this.editorConfig.showToolbar = this.editable
    }

    updateComentario() {
        this.comentarioChange.emit(this.comentario)
    }

}
