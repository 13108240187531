import { Routes } from "@angular/router";
import { AuthGuard } from "config/auth.guard";
import { LoginGuard } from "config/login.guard";
// export const routes_modulos: any = {
//   login: 'login',
//   modulos: 'modulos',
//   comercial: 'comercial'
// }
export const ROUTES: Routes = [
  {
    path: 'clientes',
    loadChildren: () => import('./views-clientes/views-clientes.module').then(m => m.ViewsClientesModule)
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'encuestas',
    loadChildren: () => import('./views/encuestas/encuestas.module').then(m => m.EncuestasModule)
  },
  {
    path: 'modulos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/modulos/modulos.module').then(m => m.ModulosModule)
  },
  {
    path: 'comercial',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/comercial/comercial.module').then(m => m.ComercialModule)
  },
  {
    path: 'riesgo-admision',
    canActivate: [AuthGuard],
    loadChildren: () => import('@viewRiesgosAdmision/riesgos-admision.module').then(m => m.ComercialModule)
  },
  {
    path: 'contabilidad',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/contabilidad/contabilidad.module').then(m => m.ContabilidadModule)
  },
  {
    path: 'legal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/legal/legal.module').then(m => m.LegalModule)
  },
  {
    path: 'mi-perfil',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/configurar-perfil/configurar-perfil.module').then(m => m.ConfigurarPerfilModule)
  },
  {
    path: 'tesoreria',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/tesoreria/tesoreria.module').then(m => m.TesoreriaModule)
  },
  {
    path: 'cobranza',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/cobranza/cobranza.module').then(m => m.CobranzaModule)
  },
  {
    path: 'finanzas',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/finanzas/finanzas.module').then(m => m.FinanzasModule)
  },
  {
    path: 'gestion-administrativa',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/administracion/administracion.module').then(m => m.AdministracionModule)
  },
  {
    path: 'operaciones',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/operaciones/operaciones.module').then(m => m.OperacionesModule)
  },
  {
    path: 'sistemas',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/sistemas/sistemas.module').then(m => m.SistemasModule)
  },
  {
    path: 'gestion-ti',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/gestion-ti/gestion-ti.module').then(m => m.GestionTiModule)
  },
  {
    path: 'rr-hh',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/recursos-humanos/recursos-humanos.module').then(m => m.RecursosHumanosModule)
  },
  {
    path: 'marketing',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/marketing2/marketing.module').then(m => m.MarketingModule)
  },
  {
    path: 'portal-colaborador',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/portal-colaborador/portal-colaborador.module').then(m => m.PortalColaboradorModule)
  },
  {
    path: '**', redirectTo: 'modulos'
  },
];






