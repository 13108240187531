import { FormControl } from '@angular/forms';
export class NumberValidation {
    static max(max: number) {
        return (control: FormControl) => {
            let number = Number((control.value + "").replace(/,/gi, ''));
            if (
                number !== null &&
                number !== NaN &&
                number !== undefined &&
                number <= max
            ) {
                return null;
            }
            return { invalidMax: true };
        };

    }
    static min(min: number) {
        return (control: FormControl) => {
            let number = Number((control.value + "").replace(/,/gi, ''));
            if (
                number !== null &&
                number !== NaN &&
                number !== undefined &&
                number >= min
            ) {
                return null;
            }
            return { invalidMin: true };
        };

    }
    static equal(equal: number) {
        return (control: FormControl) => {
            let number = control.value ? Number(control.value.toString().replace(/,/gi, '')) : '';
            if (
                number !== null &&
                number !== NaN &&
                number !== undefined &&
                number === equal
            ) {
                return null;
            }
            return { invalidEqual: true };
        };

    }
}
