import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, } from '@angular/router';
import { SesionService } from '@service/sesion.service';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators/map';
import { LoginService } from 'app/views-clientes/login/login.service';
import { dataSesion } from '../app/services/sesion.service';

@Injectable()
export class AuthClienteGuard implements CanActivate {
    constructor(private _router: Router, private _login: LoginService, private sesion: SesionService) { }
    canActivate(router: ActivatedRouteSnapshot): Observable<boolean> {
        if (dataSesion.token) {
            return of(true);
        } else {
            return this._login.isLogin().pipe(map((data) => {
                if (dataSesion.token) {
                    return true;
                } else {
                    this._router.navigate(['clientes/login']);
                    return false;
                }
            }));

        }
    }
}






// @Injectable()
// export class AuthGuard implements CanActivate {
//     constructor(private _router: Router, private _login: LoginService) { }
//     canActivate(router: ActivatedRouteSnapshot): boolean {
//         if (this._login.isLogin()) {
//             return true;
//         }
//         this._router.navigate(['/login']);
//         return false;
//     }
// }
