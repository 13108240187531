import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ModulosModule } from './views/modulos/modulos.module';
import { LayoutsModule } from './components/common/layouts/layouts.module';
import { GoogleMapsAPIWrapper, AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from 'config/token.interceptor';
import { LoginService } from './views/login/login.service';
import { ModulosService } from './views/modulos/modulos.service';
import { AuthInterceptor } from 'config/auth.interceptor';
import { environment } from '@environments/environment';
import { CommonModule } from '@angular/common';
import { AuthGuard } from 'config/auth.guard';
import { LoginGuard } from 'config/login.guard';
import { AppSingletonModule } from './app.singleton.module';
import { CabeceraContenedorModule } from '@common/cabecera-contenedor/cabecera-contenedor.module';
import { BodySimpleModule } from '@common/bodies/body-simple/body-simple.module';
import { PaginacionDefaultModule } from '@common/paginacion/paginacion-defult/paginacion-defult.module';
import { BuscadorModule } from '@common/buscador/buscador.module';
import { CommonAccordionModule } from '@common/accordion/accordion.module';
import { CommonExpedienteDetalleDocumentosModule } from '@common/expediente-detalle-documentos/expediente-detalle-documentos.module';
import { CommonExpedienteHistorialDocumentosModule } from '@common/expediente-historial-documentos/expediente-historial-documentos.module';
import { CommonExpedienteModalOpinionModule } from '@common/expediente-modal-opinion/expediente-modal-opinion.module';
import { CommonExpedienteAgregarDocumentosModule } from '@common/expediente-agregar-documentos/expediente-agregar-documentos.module';
import { AuthClienteGuard } from 'config/auth-cliente.guard';
import { BnNgIdleService } from 'bn-ng-idle';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SpinnerService } from 'config/spinner.interceptor';

@NgModule({
    declarations: [AppComponent],
    imports: [
        CabeceraContenedorModule,
        BodySimpleModule,
        PaginacionDefaultModule,
        BuscadorModule,
        CommonAccordionModule,
        CommonExpedienteDetalleDocumentosModule,
        CommonExpedienteAgregarDocumentosModule,
        CommonExpedienteHistorialDocumentosModule,
        CommonExpedienteModalOpinionModule,
        FontAwesomeModule,
        // Angular modules
        CommonModule,
        // HttpModule,
        FormsModule,
        BrowserAnimationsModule,

        // Modules
        LayoutsModule,
        HttpClientModule,

        AppSingletonModule.forRoot({ id: 0, token: '', cargo: '', nombre: '', sucursal: '', perfil: '', idperfil: 0, codigoperfil: '' }),
        RouterModule.forRoot(ROUTES, {
            useHash: true,
            // preloadingStrategy: PreloadAllModules
        }),
        AgmCoreModule.forRoot({
            apiKey: environment.apiKey,
            libraries: ['places'],
        }),
    ],
    providers: [
        GoogleMapsAPIWrapper,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerService,
            multi: true,
        },
        BnNgIdleService,
        LoginService,
        ModulosService,
        AuthGuard,
        LoginGuard,
        AuthClienteGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
