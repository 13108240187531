import { NgModule } from "@angular/core";
import { TopnavbarComponent } from "./topnavbar.component";
import { RouterModule } from "@angular/router";
import { TopnavbarModulosComponent } from "./topnavbar-modulos.component";
import { FormsModule } from "@angular/forms";
import { BusquedaAvanzadaModule } from "./busqueda-avanzada/busqueda-avanzada.module";
import { CommonModule } from "@angular/common";


@NgModule({
    declarations: [TopnavbarComponent, TopnavbarModulosComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        BusquedaAvanzadaModule,
    ],
    exports: [TopnavbarComponent, TopnavbarModulosComponent],
})

export class TopnavbarModule { }
