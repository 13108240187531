import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy
} from '@angular/core';
import { API_URL } from 'config/services';
import { LoginService } from 'app/views/login/login.service';

declare var $: any;

@Directive({
    selector: '[select2Async]'
})
export class Select2AsyncDirective implements OnInit, AfterViewInit, OnDestroy {

    @Output() selectChange = new EventEmitter();
    @Output() unselect = new EventEmitter();
    @Input() search: boolean;
    @Input() placeholder: any;
    @Input() clear: boolean;
    @Input() selected: any;
    @Input() service: any;
    @Input() all: any;
    @Input() theme: string;
    constructor(
        private el: ElementRef,
        private loginService: LoginService
    ) {
        this.search = false;
        this.placeholder = "";
        this.clear = false;
        this.selected = "";
        this.all = false;
        this.theme = '';
    }

    ngOnInit() { }
    ngAfterViewInit() {
        this.init();
    }
    ngOnDestroy() {
        $(this.el.nativeElement).select2('destroy');
    }

    private init() {
        $(this.el.nativeElement).select2({
            ajax: {
                url: `${API_URL}${this.service}`,
                dataType: 'json',
                type: 'POST',
                headers: {
                    "Authorization": `Bearer ${atob(atob(atob(this.loginService.getdataUser().access_token)))}`,
                    'Content-Type': 'application/json'
                },
                data: (params) => {
                    return JSON.stringify({
                        filtro: params.term || "",
                        pagina: params.page || 1,
                        numfilas: 10
                    });
                },
                processResults: (data, params) => {
                    params.page = params.page || 1;
                    if (data.data.length > 0 && this.all && params.page === 1) {
                        data.data.unshift({ id: 0, text: 'TODOS' });
                    }
                    return {
                        results: data.data,
                        pagination: {
                            more: (params.page * 10) < data.total_filas
                        }
                    };
                },
                cache: true
            },
            theme: 'bootstrap4 ' + this.theme,
            language: "es",
            minimumResultsForSearch: this.search ? 1 : -1,
            minimumInputLength: 0,
            placeholder: this.placeholder,
            allowClear: this.clear,
        }).on('select2:select', (event) => {
            this.selectChange.emit(event.params.data);
            setTimeout(() => {
                $(this.el.nativeElement).select2('close');
            }, 500);
        }).on("select2:unselecting", (e) => {
            this.unselect.emit();
        });
        this._selected();
    }
    private _selected() {
        if (
            this.selected !== '' &&
            this.selected !== null &&
            this.selected !== undefined
        ) {
            $(this.el.nativeElement).val(this.selected).trigger('change');
        }
    }
}
