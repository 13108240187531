export class ExpedienteCheckList {
    id: number;
    text: string;
    documentos: DocumentoCheckList[];
    constructor(id: number, text: string, documentos: DocumentoCheckList[]) {
        this.id = id;
        this.text = text;
        this.documentos = documentos;
    }
}
export class DocumentoCheckList {
    id: number;
    text: string;
    codigo: string;
    prioridad: string;
    cantidad: number;
    estado: boolean;
    constructor(id: number, text: string, codigo: string, prioridad: string, cantidad: number, estado: boolean = false) {
        this.id = id;
        this.text = text;
        this.codigo = codigo;
        this.prioridad = prioridad;
        this.cantidad = cantidad;
        this.estado = estado;
    }
}

