import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { EditorTextoComponent } from "./editor-texto.component";
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
    declarations: [EditorTextoComponent],
    imports: [
        FormsModule,
        AngularEditorModule
    ],
    exports: [EditorTextoComponent]
})

export class EditorTextoModule { }
