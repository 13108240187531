import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CabeceraBasicoComponent } from "./basico.component";
import { CalendarioPrincipalModule } from "@common/calendario-principal/calendario-principal.module";
import { CommonModule } from "@angular/common";


@NgModule({
    declarations: [CabeceraBasicoComponent],
    imports: [
        CommonModule,
        RouterModule,
        CalendarioPrincipalModule

    ],
    exports: [CabeceraBasicoComponent]
})

export class CabeceraBasicoModule { }
