import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PaginacionModel } from '@models/paginacion.model';

@Component({
    selector: 'paginacion-defult',
    templateUrl: './paginacion-defult.template.html'
})
export class PaginacionDefultComponent implements OnInit {
    @Input() data: PaginacionModel;
    // public data: PaginacionModel;
    @Input() view: boolean;
    @Input() total: number;
    @Input() limit: number;
    @Output() updateData = new EventEmitter();
    public cacheInput: number;
    constructor(
    ) {
        this.view = true;
        this.data = new PaginacionModel(0, 0, 0, 0, 0, 0, 0, 0);
    }
    next() {
        if (this.data.pag_siguiente !== null) {
            this.updateData.emit(this.data.pag_siguiente);

        }
    }
    prev() {
        if (this.data.pag_anterior !== null) {
            this.updateData.emit(this.data.pag_anterior);
        }
    }
    first() {
        if (this.data.pag_anterior !== null) {
            this.updateData.emit(1);
        }
    }
    last() {
        if (this.data.pag_siguiente !== null) {
            this.updateData.emit(this.data.pag_total);
        }
    }
    ngOnInit() {
    }

}
