import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    SimpleChanges,
    OnChanges
} from '@angular/core';
import { LoginService } from 'app/views/login/login.service';

declare var $: any;

@Directive({
    selector: '[select2AsyncV2]'
})
export class Select2AsyncV2Directive implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Output() selectChange = new EventEmitter();
    @Output() unselect = new EventEmitter();
    @Input() search: boolean;
    @Input() placeholder: any;
    @Input() clear: boolean;
    @Input() selected: { id: any; text: any };
    @Input() service: any;
    @Input() extra: { id: any, text: any };
    @Input() theme: string;
    @Input() objeto: string;
    @Input() data: any;
    @Input() type: string; //GET, POST
    constructor(
        private el: ElementRef,
        private loginService: LoginService
    ) {
        this.search = true;
        this.placeholder = "";
        this.clear = false;
        this.selected = { id: '', text: '' };
        this.extra = { id: null, text: null };
        this.theme = '';
        this.objeto = 'data';
        this.data = {};
        this.service = '';
        this.type = 'POST'
    }

    ngOnInit() { }
    ngAfterViewInit() {
        // this.init();
    }
    ngOnDestroy() {
        $(this.el.nativeElement).select2('destroy');
    }
    ngOnChanges(simple: SimpleChanges) {
        if (simple.service) {
            this.init();
        }
        if (simple.selected != undefined) {
            $(this.el.nativeElement).append(`
                <option value="${this.selected.id == null ? '' : this.selected.id}" selected>
                    ${this.selected.text == null ? '' : this.selected.text}
                </option>`
            );
        }
    }

    private init() {
        $(this.el.nativeElement).select2({
            ajax: {
                url: `${this.service}`,
                dataType: 'json',
                type: this.type,
                
                headers: {
                    "Authorization": `Bearer ${atob(atob(atob(this.loginService.getdataUser().access_token)))}`,
                    'Content-Type': 'application/json'
                },
                data: (params) => {
                    this.data.filtro = params.term || "";
                    this.data.razonsocial = params.term || "";
                    this.data.sucursal = params.term || "";
                    this.data[this.objeto] = params.term || "";
                    this.data.pagina = params.page || 1;
                    return JSON.stringify(this.data);
                    // return JSON.stringify({
                    //     filtro: params.term || "",
                    //     pagina: params.page || 1,
                    //     numfilas: 10
                    // });
                },
                processResults: (data, params) => {
                    params.page = params.page || 1;
                    if (data[this.objeto].length > 0 && this.extra.id !== null && params.page === 1) {
                        data[this.objeto].unshift({ id: this.extra.id, text: this.extra.text });
                    }
                    data[this.objeto].map(value => {
                        if (value.text == undefined && value.descripcion != undefined) {
                            value.text = value.descripcion
                            return value;
                        }
                        if (value.text == undefined && value.raz_social != undefined) {
                            value.text = value.raz_social
                            return value;
                        }
                        if (value.text == undefined && value.nombre != undefined) {
                            value.text = value.nombre
                            return value;
                        }
                        return value
                    });
                    return {
                        results: data[this.objeto],
                        pagination: {
                            more: (params.page * 10) < data.total_filas
                        }
                    };
                },
                cache: true
            },
            theme: 'bootstrap4 ' + this.theme,
            language: "es",
            minimumResultsForSearch: this.search ? 1 : -1,
            minimumInputLength: 0,
            placeholder: this.placeholder,
            allowClear: this.clear,
        }).on('select2:select', (event) => {
            this.selectChange.emit(event.params.data);
            // setTimeout(() => {
            //     $(this.el.nativeElement).select2('close');
            // }, 500);
        }).on("select2:unselecting", (e) => {
            this.unselect.emit();
        });
        // this._selected();
    }
    // private _selected() {
    //     if (
    //         this.selected !== '' &&
    //         this.selected !== null &&
    //         this.selected !== undefined
    //     ) {
    //         $(this.el.nativeElement).val(this.selected).trigger('change');
    //     }
    // }
}
