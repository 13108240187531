import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BlankComponent } from "./blank.component";
import { BasicComponent } from "./basic.component";
import { CabeceraBasicoModule } from "@common/cabecera-de-pagina/cabecera-de-pagina.module";
import { NavigationModule } from "@common/navigation/navigation.module";
import { TopnavbarModule } from "@common/topnavbar/topnavbar.module"
import { LayoutsModulosComponent } from "./layouts-modulos.component"
import { CommonModule } from "@angular/common";
import { SimplePageComponent } from './simple-page/simple-page.component';
import { LoadingModule } from '@common/loading/loading.module';







@NgModule({
    declarations: [
        BlankComponent, 
        BasicComponent, 
        LayoutsModulosComponent,
        SimplePageComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NavigationModule,
        TopnavbarModule,
        CabeceraBasicoModule,
        

        LoadingModule,



    ],
    exports: [
        BlankComponent, 
        BasicComponent, 
        LayoutsModulosComponent,
        SimplePageComponent
    ]
})

export class LayoutsModule { }
