import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonExpedienteDetalleDocumentosWithoutButonModule } from '@common/expediente-detalle-documentos-without-button/expediente-detalle-documentos.module';
import { SelectDefaultModule } from "@common/selects/select-default/select-default.module";
import { DirectivasModule } from '@directives/directivas.module';
import { AgregarDocumentoModule } from "../asignados/checklist/agregar-documentos/agregar-documentos.module";
import { EditarCotizacionComponent } from "./editar-cotizacion.component";
import { EditorTextoModule } from "@common/editor-texto/editor-texto.module";
@NgModule({
    declarations: [
        EditarCotizacionComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DirectivasModule,
        SelectDefaultModule,
        AgregarDocumentoModule,
        CommonExpedienteDetalleDocumentosWithoutButonModule,
        EditorTextoModule
    ],
    exports: [
        EditarCotizacionComponent
    ]
})

export class EditarCotizacionModule { }
