import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { LoginService } from '../views/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class DescargarArchivoService {
  public extensions = {
    pdf: 'pdf',
    xlsx: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xml: 'xml; charset=utf-8'
  };
  public url: string;
  constructor(private loginService: LoginService) {
    // this.url = environment.apiUrl;

  }
  exportar(url_api, name: any = "", extencion: any = "xlsx", method: any = "GET", data: any = {}): Observable<Object[]> {
    name = name == "" ? moment().format('x') : name;
    return Observable.create(observer => {
      let xhr = new XMLHttpRequest();
      xhr.open(method, url_api, true);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let blob = new Blob([xhr.response], { type: this.extensions[extencion] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = name + '.' + extencion;
            link.click();
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(JSON.stringify({ msg: "No encontramos el archivo." }));

            // observer.error(xhr.response);
          }
        }
      };
      xhr.setRequestHeader("Authorization", 'Bearer ' + atob(atob(atob(this.loginService.getdataUser().access_token))));
      xhr.send(data);
    });
  }
  //   descargar(observer, responseFile, extencion) {
  //     let extensions = {
  //       pdf: 'pdf',
  //       xlsx: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //       xml: 'xml; charset=utf-8'
  //     };
  //     let xhr = new XMLHttpRequest();
  //     xhr.open('GET', this.url + 'file/' + responseFile.ruta + '/' + extencion, true);
  //     xhr.responseType = 'blob';
  //     xhr['filename'] = responseFile['archivo'];
  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState === 4) {
  //         if (xhr.status === 200) {
  //           let contentType = 'application/' + extensions[extencion];
  //           let blob = new Blob([xhr.response], { type: contentType });
  //           let link = document.createElement('a');
  //           link.href = window.URL.createObjectURL(blob);
  //           link.download = responseFile['archivo'] + "." + extencion;
  //           link.click();
  //           observer.next(blob);
  //           observer.complete();
  //         } else {
  //           observer.error(JSON.stringify({ msg: "no encontramos el archivo" }));
  //         }
  //       }
  //     };
  //     xhr.send();
  //   }
}
