import { Component, Input } from '@angular/core';

@Component({
    selector: 'cabecera-contenedor',
    templateUrl: 'cabecera-contenedor.template.html'
})
export class CabeceraContenedorComponent {
    @Input() widgets: boolean;
    @Input() titulo: any;
    @Input() links: Array<any>;
    constructor() {
        this.widgets = false;
        this.titulo = "";
        this.links = [];
    }
}
