import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import * as moment from 'moment';
import { ComercialExpedienteCheckListService } from '@serviceComercial/operaciones/expediente-checklist.service';
import { MensajeResponse } from '@util/mensaje-response';

declare var $: any;

@Component({
    selector: 'common-expediente-agregar-documentos',
    templateUrl: 'expediente-agregar-documentos.template.html'
})
export class ExpedienteAgregarDocumentosComponent implements OnInit, OnDestroy {
    public loadingSubirArchivo: boolean;
    public tipoDocumentos: Array<any>;
    @ViewChild('file', { static: true }) file: ElementRef;
    @ViewChild('expedienteAgregarDocumentos', { static: true }) expedienteAgregarDocumentos: ElementRef;
    public dataFile: Array<any>;
    public startDate = moment().format('YYYY-MM-DD');
    public maxDate: Date = new Date(moment().format('MM-DD-YYYY'));
    
    @Output() saved = new EventEmitter();
    @Input() idExpediente: number;
    @Input() idDocumento: number;
    @Input() titulo: string;
    constructor(
        private expedienteCheckListService: ComercialExpedienteCheckListService,
        private mensajeResponse: MensajeResponse
    ) {
        this.loadingSubirArchivo = false;
        this.dataFile = [];
        this.tipoDocumentos = [];
        this.titulo = "";

        this.idExpediente = 0;
        this.idDocumento = 0;
        this.titulo = "";
    }
    ngOnInit() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    ngOnDestroy() {
        $('[data-toggle="tooltip"]').tooltip('dispose');
    }
    agregar() {
        $(this.expedienteAgregarDocumentos.nativeElement).modal("show");
    }
    fileChangeEvent(fileInput: any) {

        let fileToUpload = <Array<File>>fileInput.target.files;
        for (let index = 0; index < fileToUpload.length; index++) {
            this.dataFile.push({
                nombre: fileToUpload[index].name,
                fecha_documento: this.startDate,
                arch: fileToUpload[index]
            });
        }
        this.file.nativeElement.value = "";
        setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
        }, 300);
    }
    eliminarArchivo(index) {
        this.dataFile.splice(index, 1);
    }
    selectedTipoDocumentos(index, event) {
        this.dataFile[index].tipo = event.id;
    }
    selectedFechaDocumento(index, event) {
        this.dataFile[index].fecha = moment(event).format('YYYY-MM-DD');
    }
    closeCargaArchivo() {
        this.dataFile = [];
        $(this.expedienteAgregarDocumentos.nativeElement).modal("hide");
    }
    cargaArchivo() {
        this.mensajeResponse.action('¿Estás seguro de guardar los archivos?').subscribe(result => {
            if (result) {
                this.loadingSubirArchivo = true;
                let formData: any = new FormData();
                formData.append('idexpediente', this.idExpediente);
                formData.append('iddocumento', this.idDocumento);
                this.dataFile.forEach((value, index) => {
                    let arch = "file[" + index + "][arch]";
                    let fecha = "file[" + index + "][fecha_documento]";
                    let nombre = "file[" + index + "][nombre_documento]";
                    formData.append(arch, value.arch);
                    formData.append(nombre, this.dataFile[index].nombre);
                    formData.append(fecha, this.dataFile[index].fecha_documento);
                });
                this.expedienteCheckListService.uploadFile(formData)
                    .then((response: any) => {
                        this.loadingSubirArchivo = false;
                        this.dataFile = [];
                        $(this.expedienteAgregarDocumentos.nativeElement).modal("hide");
                        this.file.nativeElement.value = "";
                        this.saved.emit();

                    }, error => {
                        this.loadingSubirArchivo = false;
                    });
            }
        });
    }
    validarBtnAceptar() {
        let val = true;
        this.dataFile.forEach((value, index) => {
            if (value.fecha == "") {
                val = false;
            }
        });
        if (this.dataFile.length == 0) {
            val = false;
        }
        return val;
    }
}
