import { API_URL } from './comun';

export const SERVICIOS_CRM = {
    prospectos : {

    },
    clientes :{ 

    },
    proveedores : {
        listar :'',
        // consultarRucProveedor: `${API_URL}proveedores/ruc/`,
        guardar : `${API_URL}proveedores`,
        consultarRuc : (ruc : string) => {
            return`${API_URL}proveedores/ruc/${ruc}`
        },
        editar : ( proveedorId : number ) => {
            return `${API_URL}proveedores/${proveedorId}`
        },
        eliminar : ( proveedorId : number ) => {
            return `${API_URL}proveedores/${proveedorId}`
        },
        obtener : ( proveedorId : number ) => {
            return `${API_URL}proveedores/${proveedorId}`
        },
    }
}