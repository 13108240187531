
import { environment } from '@environments/environment';
import { API_VERSION } from '../api-version'
const API_V1 = `${environment.API_URL}${API_VERSION.v1}`;
const API_V2 = `${environment.API_URL}${API_VERSION.v2}`;
export const SERVICIO_COTIZACIONES = {
    "v1.0": {
        cotizaciones: {
            ver: (cotizacionId: number) => {
                return `${API_V1}cotizacion/${cotizacionId}`;
            },
            detalleGastos: `${API_V1}detalle_bienes`,
            guardar: `${API_V1}cotizacion/web`,
            editar: `${API_V1}cotizacion/web`,
            cronograma: `${API_V1}cronograma`,
            preViewPdf: `${API_V1}cotizacion/pdf3`
        },
    },
    "v1.1": {
        cotizaciones: {
            detalleGastos: `${API_V2}cotizacion/detalle-gastos`,
            guardar: `${API_V2}cotizacion`,
            editar: `${API_V2}cotizacion`,
            cronograma: `${API_V2}cronograma`,
            preViewPdf: `${API_V2}cotizacion/preview-pdf`,
            subirArchivo: `${API_V2}expediente/documento/checklist/cotizacion`
        }
    }
}