import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ExpedienteOpinionTipoModal, ExpedienteUtil } from './expediente-util';
import { ExpedienteConclucion } from '@util/expediente-conclucion';
import { MensajeResponse } from '@util/mensaje-response';
import { ExpedienteModalOpinionService, DataAnalista } from './expediente-modal-opinion.service';
import * as moment from 'moment';

declare var $: any;

@Component({
    selector: 'common-expediente-modal-opinion',
    templateUrl: 'expediente-modal-opinion.template.html',
})
export class CommonExpedienteModalOpinionComponent implements OnInit, OnChanges {
    @ViewChild('expedienteModalOpinion', { static: true }) modalOpinionAnalista: ElementRef;
    @Input() titulo: string;
    @Input() tipoModal: ExpedienteOpinionTipoModal;
    @Input() show: boolean;
    @Input() data;
    @Input() editando: boolean;
    @Input() archivos: boolean = true;
    @Input() idExpediente: any;
    @Input() idOperacion: any;
    @Input() codigoOperacion: string;
    @ViewChild('file', { static: true }) file: ElementRef;
    @Output() cerrar = new EventEmitter();
    @Output() saved = new EventEmitter();
    public loading: boolean;
    public aprobaciones: Array<any>;
    public form: any;
    public selected: any;
    public labelConclucion: string;
    public agregarDocumento: AgregarDocumento[];
    public perfil: string;

    constructor(private expedienteModalOpinionService: ExpedienteModalOpinionService, private mensajeResponse: MensajeResponse) {
        this.perfil = JSON.parse(localStorage.getItem('data_user')).perfil[0].nombre;
        this.agregarDocumento = [];
        this.loading = false;
        this.editando = false;
        this.aprobaciones = [];
        this.idExpediente = 0;
        this.idOperacion = 0;
        this.codigoOperacion = '';
        this.selected = {
            id: '',
            text: 'Seleccionar',
        };
        this.labelConclucion = '';
    }

    ngOnInit() {
        this.aprobaciones = ExpedienteConclucion.conclucionesJefeComite;
        if (this.tipoModal == ExpedienteUtil.tipoComite) {
            this.labelConclucion = 'Conclusión: ';
            this.aprobaciones = ExpedienteConclucion.conclucionesJefeComite;
        } else {
            this.aprobaciones = ExpedienteConclucion.conclucionesAnalistaJefe;
            this.labelConclucion = 'Opinión: ';
        }
        this.form = new FormGroup({
            validez: new FormControl('', [Validators.required]),
            comentario: new FormControl('', [Validators.required]),
            todos: new FormControl(false, [Validators.required]),
            documento: new FormControl(''),
            nombreDocumento: new FormControl({ value: '', disabled: true }),
        });
    }
    ngOnChanges(simple: SimpleChanges) {
        if (simple.show !== undefined) {
            if (simple.show.currentValue === true) {
                if (this.editando) {
                    this.ver();
                }
                $(this.modalOpinionAnalista.nativeElement).modal('show');
            } else {
                $(this.modalOpinionAnalista.nativeElement).modal('hide');
            }
        }
    }
    ver() {
        if (this.tipoModal == ExpedienteUtil.tipoAnalista) {
            this.loading = true;
            this.expedienteModalOpinionService.analistaGetOpinion(this.idExpediente, this.idOperacion).subscribe(
                (response) => {
                    this.selected = {
                        id: response.data.validez + '',
                        text: ExpedienteConclucion.conclucionAnalistaJefe(response.data.validez + ''),
                    };
                    this.form.patchValue({
                        validez: response.data.validez,
                        comentario: response.data.comentario,
                        todos: false,
                    });
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                    this.mensajeResponse.danger().subscribe();
                }
            );
        } else if (this.tipoModal == ExpedienteUtil.tipoJefeAdmision) {
        } else if (this.tipoModal == ExpedienteUtil.tipoComite) {
            this.selected = this.data.selected;
            this.form.value = this.data.form;
            this.form.patchValue({
                validez: this.data.form.validez,
                comentario: this.data.form.comentario,
                todos: this.data.form.todos,
            });
            // this.expedienteModalOpinionService.comiteGetOpinion(this.idExpediente, this.idOperacion).subscribe(
            //     (response) => {
            //         this.selected = {
            //             id: response.data.validez_comite + '',
            //             text: ExpedienteConclucion.conclucionJefeComite(response.data.validez_comite + ''),
            //         };
            //         this.form.patchValue({
            //             validez: response.data.validez_comite,
            //             comentario: response.data.comentario_comite,
            //             todos: false,
            //         });
            //         this.loading = false;
            //     },
            //     (error) => {
            //         this.loading = false;
            //         this.mensajeResponse.danger().subscribe();
            //     }
            // );
        } else {
            this.mensajeResponse.danger().subscribe();
        }
    }
    fileChangeEvent(fileInput: any) {
        let fileToUpload = <Array<File>>fileInput.target.files;
        this.form.patchValue({
            nombreDocumento: fileToUpload[0].name,
            documento: fileToUpload[0],
        });
    }
    eliminarArchivo(index) {
        this.form.patchValue({
            nombreDocumento: '',
            documento: '',
        });
    }
    selectedOperacion(event) {
        this.form.patchValue({
            validez: Boolean(JSON.parse(event.id)),
        });
    }
    salir() {
        this.cerrar.emit();
        this.form.patchValue({
            validez: '',
            comentario: '',
            todos: false,
            nombreDocumento: '',
            documento: '',
        });
        this.selected = {
            id: '',
            text: 'Seleccionar',
        };
    }
    onSubmit() {
        if (this.tipoModal == ExpedienteUtil.tipoAnalista) {
            this.accionesAnalista();
        } else if (this.tipoModal == ExpedienteUtil.tipoJefeAdmision) {
        } else if (this.tipoModal == ExpedienteUtil.tipoComite) {
            this.accionesComite();
        } else {
            this.mensajeResponse.danger().subscribe();
        }
    }
    accionesAnalista() {
        if (this.editando == false) {
            this.loading = true;

            const formData = new FormData();
            formData.append('comentario', this.form.value.comentario);
            formData.append('aplicar_todos', this.form.value.todos);
            formData.append('idoperacion', this.idOperacion);
            formData.append('idexpediente', this.idExpediente);
            formData.append('validez', this.form.value.validez);
            formData.append('file[0][nombre_documento]', this.form.controls.nombreDocumento.value);
            formData.append('file[0][fecha_documento]', moment().format('YYYY-MM-DD'));
            formData.append('file[0][arch]', this.form.value.documento);
            this.expedienteModalOpinionService.analistaSaveOpinion(formData).subscribe(
                (response) => {
                    this.saved.emit();
                    this.salir();
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                    this.mensajeResponse.danger().subscribe();
                }
            );
        } else {
            const formData = new FormData();
            formData.append('comentario', this.form.value.comentario);
            formData.append('aplicar_todos', this.form.value.todos);
            formData.append('validez', this.form.value.validez);
            formData.append('file[0][nombre_documento]', this.form.controls.nombreDocumento.value);
            formData.append('file[0][fecha_documento]', moment().format('YYYY-MM-DD'));
            formData.append('file[0][arch]', this.form.value.documento);
            this.loading = true;

            this.expedienteModalOpinionService.analistaUpdateOpinion(this.idExpediente, this.idOperacion, formData).subscribe(
                (responee) => {
                    this.saved.emit();
                    this.salir();
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                    this.mensajeResponse.danger().subscribe();
                }
            );
        }
    }
    accionesComite() {
        this.loading = true;
        let body = {
            comentario: this.form.value.comentario,
            todos: this.form.value.todos,
            idoperacion: this.idOperacion,
            idexpediente: this.idExpediente,
            validez_comite: this.form.value.validez,
        };
        this.saved.emit(body);
        this.form.reset();
        this.salir();
        this.loading = false;

        // const formData = new FormData();
        // formData.append('comentario', this.form.value.comentario);
        // formData.append('todos', this.form.value.todos);
        // formData.append('idoperacion', this.idOperacion);
        // formData.append('idexpediente', this.idExpediente);
        // formData.append('validez_comite', this.form.value.validez);
        // formData.append('file[0][nombre_documento]', this.form.controls.nombreDocumento.value);
        // formData.append('file[0][fecha_documento]', moment().format('YYYY-MM-DD'));
        // formData.append('file[0][arch]', this.form.value.documento);

        // this.expedienteModalOpinionService.comiteSaveOpinion(formData).subscribe(
        //     response => {
        //         this.saved.emit(response);
        //         this.form.reset()
        //         this.salir();
        //         this.loading = false;
        //     }, error => {
        //         this.loading = false;
        //         this.mensajeResponse.danger().subscribe();
        //     }
        // );
    }
}
interface AgregarDocumento {
    nombre: string;
    documento: File;
}
