import {NgModule} from "@angular/core";
import { CabeceraContenedorComponent } from "./cabecera-contenedor.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";



@NgModule({
    declarations: [CabeceraContenedorComponent],
    imports     : [
        CommonModule,
        RouterModule
        ],
    exports: [CabeceraContenedorComponent]
})

export class CabeceraContenedorModule {}
