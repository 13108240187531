import { API_URL } from "./comun";

export const SERVICIOS_REPORTES = {
  cartasDespacho: {
    listar: `operaciones/cartas-despachos`,
    exportarCartas: `operaciones/cartas-despachos/exportar`,
  },
  cuponesPendientes: {
    listar: `operaciones/cupones/pendientes`,
    exportarCupones: `operaciones/cupones/pendientes/exportar`,
    enviarCupones: `operaciones/cupones/pendientes/enviar`,
  },
};
