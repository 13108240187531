import { API_URL } from './comun';

export const SERVICIOS_OPERACIONES_UTILS = {
    listarProveedores: `${API_URL}proveedores/combo`, // {filtro:'', modelo_id: 6188, tipo_bien_id:1, pagina:1, numfilas: 10}
    listarSucursales: (proveedorId: number) => {
        return `${API_URL}proveedores/${proveedorId}/sucursales/combo`;
    },
    consultaRucProveedor: (ruc: string) => {
        return `${API_URL}proveedores/ruc/${ruc}`;
    },
    zonasRegistrales: `${API_URL}zonas-registrales`,
    oficinasRegistrales: (zonaRegistralId: number) => {
        return `${API_URL}zonas-registrales/${zonaRegistralId}/oficinas-registrales`;
    },
    consultaRucTasador: (ruc: string) => {
        return `${API_URL}tasadores/ruc/${ruc}`;
    },
    guardarTasador: `${API_URL}tasadores`,
    editarTasador: (tasadorId: number) => {
        return `${API_URL}tasadores/${tasadorId}`;
    },
    documentosIdentidad: `${API_URL}documentos-identidad`,
    listarTipoGarantias: `${API_URL}tipos-garantias`,
    consultaRucOtorgante: (ruc: string) => {
        return `operaciones/otorgantes/ruc/${ruc}`;
    },
    listarFormasPago: `operaciones/formas-pagos`

}