export class PaginacionModel {
    item_desde: number;
    item_hasta: number;
    item_pagina: number;
    item_total: number;
    pag_actual: number;
    pag_anterior: number;
    pag_siguiente: number;
    pag_total: number;
    constructor(
        item_desde: number,
        item_hasta: number,
        item_pagina: number,
        item_total: number,
        pag_actual: number,
        pag_anterior: number,
        pag_siguiente: number,
        pag_total: number
    ) {
        this.item_desde = item_desde;
        this.item_hasta = item_hasta;
        this.item_pagina = item_pagina;
        this.item_total = item_total;
        this.pag_actual = pag_actual;
        this.pag_anterior = pag_anterior;
        this.pag_siguiente = pag_siguiente;
        this.pag_total = pag_total;
    }
}

export class PaginacionModel_v2 {
    item_desde: number;
    item_hasta: number;
    item_pagina: number;
    item_total: number;
    pag_actual: number;
    pag_anterior: number;
    pag_siguiente: number;
    pag_total: number;
    constructor(obj: Obj) {
        this.item_desde = obj.item_desde || 0;
        this.item_hasta = obj.item_hasta || 0;
        this.item_pagina = obj.item_pagina || 0;
        this.item_total = obj.item_total || 0;
        this.pag_actual = obj.pag_actual || 0;
        this.pag_anterior = obj.pag_anterior || null;
        this.pag_siguiente = obj.pag_siguiente || null;
        this.pag_total = obj.pag_total;
    }
}
interface Obj {
    item_desde?: number;
    item_hasta?: number;
    item_pagina?: number;
    item_total?: number;
    pag_actual?: number;
    pag_anterior?: number;
    pag_siguiente?: number;
    pag_total?: number;
}