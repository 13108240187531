import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivasModule } from '@directives/directivas.module';
import { SelectDefaulAsyncModule } from '@common/selects/select-defaul-async/select-defaul-async.module';
import { FormsModule } from '@angular/forms';
import { ExpedienteAgregarDocumentosComponent } from './expediente-agregar-documentos.component';

@NgModule({
    imports: [
        CommonModule,
        DirectivasModule,
        SelectDefaulAsyncModule,
        FormsModule
    ],
    declarations: [
        ExpedienteAgregarDocumentosComponent
    ],
    exports: [
        ExpedienteAgregarDocumentosComponent
    ]
})
export class CommonExpedienteAgregarDocumentosModule { }
