import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ExpedienteDetalleDocumentosSinBotonComponent } from '@common/expediente-detalle-documentos-without-button/expediente-detalle-documentos.component';
import { CondicionesService } from '@service/riesgos/condiciones/condiciones.service';
import { UtilService } from '@service/util.service';
// import { PorAprobacionService } from '@service/registros_admision/por-aprobacion.service';
import { MensajeResponse } from '@util/mensaje-response';
import { separadorMiles } from '@util/validar-formatos';
import { NumberValidation } from '@validators/number-validation.validator';
import { AgregarDocumentoComponent } from '../asignados/checklist/agregar-documentos/agregar-documentos.component';
import { CondicionEvaluacion } from '../condicion-evaluacion.model';
import { ChecklistDocumentos } from '@models/riesgos-admision/admision/admision-checklist-doumentos.model';
declare var $: any;

@Component({
    selector: 'modal-editar-cotizacion-evaluacion',
    templateUrl: 'editar-cotizacion.template.html',
    styleUrls: [
        './editar-cotizacion.scss'
    ]
})
export class EditarCotizacionComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('modalEditarCotizacionEvaluacion', { static: true }) modalEditarCotizacionEvaluacion: ElementRef;
    @ViewChild(AgregarDocumentoComponent, { static: true }) agregarDocumentoComponent: AgregarDocumentoComponent;
    @ViewChild(ExpedienteDetalleDocumentosSinBotonComponent, { static: true }) detalleDocumentos: ExpedienteDetalleDocumentosSinBotonComponent;
    @Input() show: boolean;
    @Input() data: CondicionEvaluacion;
    @Input() leasing: boolean;
    @Input() montoBruto: number = 0;

    @Output() saved = new EventEmitter<CondicionEvaluacion>();
    @Output() cerrar = new EventEmitter();

    public form: FormGroup;
    @Input() idExpediente: number;
    public loading: boolean;
    public errors: Errors;
    public tipoGarantias: Array<{ id: number, nombre: string }>;
    public garantias: any[];
    public bandera: boolean = false;
    public monedas: Array<any>;
    public selectedMoneda: any;
    public indexTemp: number;
    public documents: Map<number, FormData>;
    public idDocumento: number;
    public nombreDocumento: string;
    public otherConditions: string;

    constructor(
        private mensajeResponse: MensajeResponse,
        private utilService: UtilService,
        private condicionesService: CondicionesService,
    ) {
        this.documents = new Map<number, FormData>();
        this.show = false;
        this.data = new CondicionEvaluacion({});
        this.loading = false;
        this.errors = new Errors();
        this.leasing = false;
        this.tipoGarantias = [];
        this.initForm();
        this.monedas = [];
        this.condicionesService.obtenerDocumentosGenerales().then((response) => {
            for (let i = 0; i < response.data[0].data.length; ++i) {
                if (response.data[0].data[i]["codigo_documento"] == "LGG") {
                    this.idDocumento = response.data[0].data[i]["iddocumento"];
                    this.nombreDocumento = response.data[0].data[i]["nombre_documento"];
                }
            }
        });
        //this.selectedMoneda = { id: "", text: "" };
    }

    get formDataGarantias() {
        return this.form.get('garantias');
    }
    agregarDocumentos(index) {
        this.indexTemp = index;
        let data = {
            documento: {
                nombre_documento: this.nombreDocumento,
                iddocumento: this.idDocumento
            }
        };
        this.agregarDocumentoComponent.init(this.idExpediente, data, false);
    }

    detalle(id: number, index: number) {
        this.detalleDocumentos.detalle(this.nombreDocumento, this.idExpediente, this.idDocumento, 'garantias', id, this.getTemporalDocuments(index));
    }

    getNumberDocuments(data: FormData) {
        let elements = 0;
        data.forEach(element => { elements++; })
        return (elements - 2) / 3;
    }

    guardarDocumentosTemporales(data: FormData) {
        if (!this.documents.has(this.indexTemp))
            this.documents.set(this.indexTemp, data);
        else {
            let elements = this.getNumberDocuments(this.documents.get(this.indexTemp));
            for (var key of data.keys()) {
                if (key.includes('[arch]'))
                    this.documents.get(this.indexTemp).set(`file[${elements}][arch]`, data.get(key))
                if (key.includes('[nombre_documento]'))
                    this.documents.get(this.indexTemp).set(`file[${elements}][nombre_documento]`, data.get(key))
                if (key.includes('[fecha_documento]')) {
                    this.documents.get(this.indexTemp).set(`file[${elements}][fecha_documento]`, data.get(key))
                    elements++;
                }
            }
        }
        this.indexTemp = null;
    }

    getTemporalDocuments(index) {
        if (!this.documents.has(index))
            return undefined
        let documents = new Array<ChecklistDocumentos>()
        let url, fecha, nombre;
        let tempId = -1;
        for (var key of this.documents.get(index).keys()) {
            if (key.includes('[arch]')) {
                let data = this.documents.get(index).get(key);
                url = URL.createObjectURL(data as Blob);
            }
            if (key.includes('[nombre_documento]'))
                nombre = this.documents.get(index).get(key);
            if (key.includes('[fecha_documento]')) {
                fecha = this.documents.get(index).get(key);
                documents.push({
                    fechaDocumento: fecha,
                    fechaModifico: "SIN GUARDAR",
                    idarchivo: tempId--,
                    iddoc: -1,
                    nombre: nombre,
                    idfactura: -1,
                    nombre_archivo: nombre,
                    ruta_archivo: url,
                    usuarioModifico: "SIN GUARDAR"
                })
            }
        }

        return documents;
    }

    ngOnInit() {
        $('[data-toggle="tooltip"]').tooltip();
    }
    ngOnDestroy() {
        $('[data-toggle="tooltip"]').tooltip('dispose');

    }
    ngOnChanges(simple: SimpleChanges) {
        if (simple.show !== undefined) {
            if (simple.show.currentValue === true) {
                if (this.tipoGarantias.length == 0) {
                    this.listarTipoGarantias();
                }
                this.montoBruto = this.data.montoBruto;
                this.form.patchValue({
                    tea: separadorMiles(this.data.tea),
                    plazo: separadorMiles(this.data.plazo, 0),
                    cuotaInicial: separadorMiles(this.data.cuotaInicial),
                    porcentajeInicial: separadorMiles(this.data.porcentajeInicial),
                    periodoGracia: separadorMiles(this.data.periodoGracia),
                    tipoCuota: false,
                    fianzaSolidaria: this.data.fianzaSolidaria,
                    fianzaSolidariaComentario: this.data.fianzaSolidariaComentario,
                    incluyeGarantia: this.data.incluyeGarantia,
                    cantidadGarantiaComercial: this.data.cantidadGarantiaComercial,
                    custodio: this.data.custodio,
                });
                this.listarMoneda();
                this.otherConditions = this.data.otrasCondiciones
                const formGarantias = this.form.get('garantias') as FormArray;
                this.data.garantias.forEach(item => {
                    formGarantias.push(this.formControlGarantia(item));
                });

                $(this.modalEditarCotizacionEvaluacion.nativeElement).modal('show');
            } else {
                this.form.reset({ tipoCuota: false });
                $(this.modalEditarCotizacionEvaluacion.nativeElement).modal('hide');
            }
        }
        if (this.form.value.incluyeGarantia === true) {
            this.bandera = true
        }
    }
    initForm() {
        this.form = new FormGroup({
            tea: new FormControl('', [
                Validators.required,
                NumberValidation.min(1),
                NumberValidation.max(100)
            ]),
            plazo: new FormControl('', [
                Validators.required,
                NumberValidation.min(1)
            ]),
            cuotaInicial: new FormControl(''),
            porcentajeInicial: new FormControl(''),
            periodoGracia: new FormControl('', [
                Validators.required,
                NumberValidation.min(0),
                NumberValidation.max(3)
            ]),
            tipoCuota: new FormControl(false, Validators.required),
            fianzaSolidaria: new FormControl(false, [Validators.required]),
            fianzaSolidariaComentario: new FormControl(''),
            incluyeGarantia: new FormControl(''),
            cantidadGarantiaComercial: new FormControl(''),
            garantias: new FormArray([]),
            custodio: new FormControl('')
        });
    }

    listarMoneda() {
        this.monedas = [];
        this.monedas.push({
            id: "-1",
            text: "sin cambio"
        })
        this.utilService.listarMoneda().subscribe(
            (response: any) => {
                response.moneda.forEach((value, index) => {
                    if (this.data.cambioMoneda == 0) {
                        if (value.simbolo != this.data.moneda)
                            this.monedas.push({
                                id: String(value.id),
                                text: value.simbolo
                            });
                    }
                    else {
                        if (value.id == this.data.cambioMoneda)
                            this.monedas.push({
                                id: String(value.id),
                                text: value.simbolo
                            });
                        else
                            this.data.moneda = value.simbolo
                    }
                    if (this.data.cambioMoneda == value.id)
                        this.selectedMoneda = {
                            id: String(value.id),
                            text: value.simbolo
                        };

                });
                if (this.selectedMoneda == undefined)
                    this.selectedMoneda = {
                        id: "-1",
                        text: "sin cambio"
                    }
            },
        );
    }

    onSelectMoneda(event) {
        if (this.selectedMoneda.id == event.id)
            return;
        this.selectedMoneda = event;
        let ci: number = Number((this.form.value.cuotaInicial + '').replace(/,/gi, ''));
        if (this.selectedMoneda.text == "US$" || (this.selectedMoneda.text == "sin cambio" && this.data.moneda == "US$")) {
            this.form.patchValue({
                cuotaInicial: separadorMiles(ci / this.data.tipoCambio)
            })
            this.montoBruto /= this.data.tipoCambio;
        }
        else {
            this.form.patchValue({
                cuotaInicial: separadorMiles(ci * this.data.tipoCambio)
            });
            this.montoBruto *= this.data.tipoCambio;
        }
    }

    onSubmit() {

        let data: any = {};
        let garantias = [];
        this.form.value.garantias.forEach(value => {
            garantias.push({
                descripcion: value.descripcion,
                id_garantia: value.id,
                id_tipo_garantia: value.tipoId,
                eliminar: value.eliminar,
                id_propuesta: value.id_propuesta
            })
        });
        if (garantias.length != 0) {
            this.bandera = true
            this.garantias = garantias
        }
        if ((garantias.length === 0 || this.form.value.incluyeGarantia === false) && this.bandera === true) {
            this.cambiarValor()
        }
        data.tea_financiar = Number(this.form.value.tea);
        data.plazo_financiar = Number((this.form.value.plazo + '').replace(/,/gi, ''));
        data.perio_gracia_financiar = Number(this.form.value.periodoGracia);
        if (!this.form.value.tipoCuota) {
            data.cuota_inicial = Number((this.form.value.cuotaInicial + '').replace(/,/gi, ''));
        } else {
            data.porcentaje_inicial = Number(this.form.value.porcentajeInicial);
        }
        data.incluye_garantia = this.form.value.incluyeGarantia;
        data.garantias = this.garantias || [];
        data.con_fianza_solidaria = this.form.value.fianzaSolidaria;
        data.comentario_fianza = this.form.value.fianzaSolidaria ? this.form.value.fianzaSolidariaComentario : '';
        data.custodio = this.form.value.custodio;
        data.tipo_moneda = (this.selectedMoneda.id == "" || this.selectedMoneda.id == "-1") ? null : this.selectedMoneda.id;
        console.log(this.otherConditions)
        data.otras_condiciones = this.otherConditions
        this.loading = true;
        this.condicionesService.postCondicionesAnalista(this.idExpediente, data).subscribe(
            (response: any) => {
                this.garantias = []
                this.form.reset({ tipoCuota: false });
                this.limpiarGarantias();
                this.saved.emit(data);
                for (let i = 0; i < response.garantias.length; ++i) {
                    if (this.documents.has(i)) {
                        let data: FormData = this.documents.get(i);
                        let elements = this.getNumberDocuments(this.documents.get(i));
                        for (var c = 0; c < elements; ++c)
                            data.set(`file[${c}][idgarantia]`, response.garantias[i].id_garantia);
                        this.condicionesService.postWarrantyDocuments(data).subscribe(response => console.log("TERMINO"));
                    }
                }
                this.documents = new Map<number, FormData>();
                this.loading = false;
            },
            error => {
                this.loading = false;
                this.mensajeResponse.danger().subscribe();
            }
        )
    }
    cambiarValor() {
        this.garantias = this.garantias.filter(elemento => elemento.eliminar = true);
    }
    cancelar() {
        this.form.reset({ tipoCuota: false });
        this.limpiarGarantias();
        this.errors = new Errors();
        this.cerrar.emit();
        this.selectedMoneda = {
            id: "-1",
            text: "sin cambio"
        };
    }
    limpiarGarantias() {
        const formGarantias = this.form.get('garantias') as FormArray;
        formGarantias.clear();
    };
    valueTea(event) {
        this.form.patchValue({
            tea: event
        });
        let tea = this.form.value.tea;
        this.errors.tea = '';
        if (tea === '' || tea === null) {
            this.errors.tea = 'Campo requerido.';
        } else {
            if (tea < 1) {
                this.errors.tea = `Tiene que ser mayor o igual a 1.`;
            }
            if (tea > 100) {
                this.errors.tea = `Tiene que ser menor o igual a 100%.`;
            }
        }
    }
    valuePlazo(event) {
        this.form.patchValue({
            plazo: event
        });
        let plazo = this.form.value.plazo;
        this.errors.plazo = '';
        if (plazo === '' || plazo === null) {
            this.errors.plazo = 'Campo requerido.';
        } else {
            if (plazo < 0) {
                this.errors.plazo = `Tiene que ser mayor o igual a 1 meses.`;
            }
        }
    }
    valuePorcentajeInicial(event) {
        this.form.patchValue({
            porcentajeInicial: event
        });
        let inicial = this.form.value.porcentajeInicial;
        this.errors.porcentajeInicial = '';
        if (inicial === '' || inicial === null) {
            this.errors.porcentajeInicial = 'Campo requerido.';
        } else {
            if (inicial < 1) {
                this.errors.porcentajeInicial = `Tiene que ser mayor o igual a 1%.`;
            }
            if (inicial > 100) {
                this.errors.porcentajeInicial = `Tiene que ser menor o igual a 100%.`;
            }
        }
    }
    valueCuotaInicial(event) {
        this.form.patchValue({
            cuotaInicial: event
        });
        let inicial = this.form.value.cuotaInicial;
        this.errors.cuotaInicial = '';
        if (inicial === '' || inicial === null) {
            this.errors.cuotaInicial = 'Campo requerido.';
        } else {
            inicial = Number((inicial + "").replace(/,/gi, ''));
            if (inicial > this.montoBruto) {
                const number = Number(this.montoBruto)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                let simbolo = (this.selectedMoneda.text != 'sin cambio') ? this.selectedMoneda.text : this.data.moneda;
                this.errors.cuotaInicial = `Tiene que ser menor o igual a ${simbolo} ${number}`;
            }
            if (inicial === 0) {
                this.errors.cuotaInicial = `Tiene que ser mayor o igual a 1`;
            }
        }
    }
    valuePeriodoGracia(event) {
        this.form.patchValue({
            periodoGracia: event
        });
        let periodo = this.form.value.periodoGracia;
        this.errors.periodoGracia = '';
        if (periodo === '' || periodo === null) {
            this.errors.periodoGracia = 'Campo requerido.';
        } else {
            if (periodo < 0) {
                this.errors.periodoGracia = `Tiene que ser mayor o igual a 0 mes.`;
            }
            if (periodo > 3) {
                this.errors.periodoGracia = `Tiene que ser menor o igual a 3 meses.`;
            }
        }
    }
    formValid() {
        if (this.form.value.tipoCuota == false) {
            return this.errors.cuotaInicial != '' ? false : true;
        } else {
            return this.errors.porcentajeInicial != '' ? false : true;

        }
    }
    validarForm() {
        if (
            this.form.value.fianzaSolidaria === true &&
            (
                this.form.value.fianzaSolidariaComentario == '' ||
                this.form.value.fianzaSolidariaComentario == null
            )
        ) {
            return false;
        }
        return true;
    }
    listarTipoGarantias() {
        this.utilService.listarTipoGarantias().subscribe(
            response => {
                this.tipoGarantias = response.data;
            },
            error => {
                console.log(error);
            }
        );
    }

    agregarGarantias() {
        const formGarantias = this.form.get('garantias') as FormArray;
        formGarantias.push(this.formControlGarantia());
    }

    formControlGarantia(data: any = {}) {
        return new FormGroup({
            id: new FormControl(data.id_garantia),
            tipoId: new FormControl(data.id_tipo_garantia || '', [Validators.required]),
            descripcion: new FormControl(data.descripcion || '', [Validators.required]),
            eliminar: new FormControl(data.eliminar || false),
            id_propuesta: new FormControl(data.id_propuesta || ''),
        });
    }
    selectChangeTipoGarantia(event, garantia) {
        garantia.patchValue({
            tipoId: Number(event.id)
        })
    }
    eliminarGarantia(garantia, index) {
        if (garantia.value.id != null) {
            garantia.patchValue({
                eliminar: true,
            })
        } else {
            const formGarantias = this.form.get('garantias') as FormArray;
            formGarantias.removeAt(index);

        }
        if (this.contarGarantiasValidos() == 0) {
            this.form.patchValue({
                incluyeGarantia: false
            })
        }
    }
    contarGarantiasValidos(): number {
        const eliminados = this.form.value.garantias.filter(item => item.eliminar === true);
        return this.form.value.garantias.length - eliminados.length
        // form.value.garantias.length
    }
    changeIncluyeGarantia(event) {
        this.form.patchValue({ incluyeGarantia: event });
        if (event === true && this.contarGarantiasValidos() === 0) {
            this.agregarGarantias()
        }
    }
}

class Errors {
    tea: string;
    plazo: string;
    cuotaInicial: string;
    porcentajeInicial: string;
    periodoGracia: string;
    constructor() {
        this.tea = '';
        this.plazo = '';
        this.cuotaInicial = '';
        this.porcentajeInicial = '';
        this.periodoGracia = '';
    }
}
