import { Injectable } from '@angular/core';
import { SpinnerServicesService } from 'config/services/spinner-services.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    isLoading: Observable<boolean>;

    constructor(private _serviceService: SpinnerServicesService) {
        this.isLoading = this._serviceService.getLoadingStatus();
    }
}
