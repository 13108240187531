import { Component, Input, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { ExpedientesService } from '@service/registros_admision/expediente.service';

@Component({
    selector: 'buscador-component',
    templateUrl: 'buscador.template.html',
})
export class BuscadorComponent implements OnChanges {
    @ViewChild('fecha', { static: true }) fecha: any;
    @ViewChild('texto', { static: true }) texto: any;
    public columFiltro: string;
    public columInput: string;
    public ragoFecha: string;
    @Output() buscar = new EventEmitter<{ id: any; text: any; tipo: any; valor: any; desde: any; hasta: any }>();
    public showInputSimple: boolean;
    public showInputRango: boolean;
    public dataSelect: Array<{ id: any; text: any }>;
    public dataSelected: { id: any; text: any };
    @Input() filtros: Array<{ id: any; text: any; tipo: any }>;
    @Input() selected: { id: any; text: any; tipo: any; valor: any; desde: any; hasta: any };
    @Input() tiposelect: Observable<any>;
    @Input() selectBlock: boolean = false;
    @Input() bandera: boolean = false;
    public searchText: any;
    public searchNumberInicio: any;
    public searchNumberFin: any;
    public tipoText: any;
    public tipoNumberInicio: any;
    public tipoNumberFin: any;
    public tipoFechaInicio: any;
    public tipoFechaFin: any;

    constructor(private expedientesService: ExpedientesService) {
        this.filtros = [];
        this.selected = { id: 'all', text: 'Seleccionar', tipo: '', valor: '', desde: '', hasta: '' };
        this.columFiltro = 'col-md-5';
        this.columInput = 'col-md-7';
        this.showInputSimple = false;
        this.showInputRango = false;
        this.tipoText = '';
        this.tipoNumberInicio = '';
        this.tipoNumberFin = '';
        this.tipoFechaInicio = '';
        this.tipoFechaFin = '';
        this.dataSelect = [];
        this.dataSelected = { id: '', text: '' };
    }
    ngOnChanges(change: SimpleChanges) {
        for (const propName in change) {
            if (change.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'bandera': {
                        this.selectChange({
                            id: 'estado',
                            text: 'Estado',
                            tipo: 'select',
                            opciones: [
                                {
                                    id: '01',
                                    text: 'ACTIVO',
                                },
                                {
                                    id: '02',
                                    text: 'INACTIVO',
                                },
                            ],
                            valor: '',
                            desde: '',
                            hasta: '',
                        });
                        break;
                    }

                    case 'tiposelect': {
                        this.listSelect();
                        break;
                    }
                }
            }
        }
    }
    selectChange(event) {
        setTimeout(() => {
            switch (event.tipo) {
                case 'date':
                    this.fecha.nativeElement.click();
                    break;
                case 'text':
                    this.texto.nativeElement.focus();
                    break;
            }
        }, 10);

        event.valor = '';
        if (event.text === 'VAF') {
            this.tipoNumberFin = '';
            this.tipoNumberInicio = '';
        }
        event.desde = '';
        event.hasta = '';
        this.selected = event;
        if (event.id === 'all' || event.id === 'ALL') {
            this.showInputRango = false;
            this.buscar.emit(this.selected);
            this.columFiltro = 'col-md-5';
        } else {
            if (event.tipo == 'text') {
                this.tipoText = '';
                this.columFiltro = 'col-md-5';
                this.columInput = 'col-md-7';
                this.showInputRango = false;
            } else if (event.tipo == 'number') {
                this.columFiltro = 'col-md-4';
                this.columInput = 'col-md-4';
                this.showInputRango = true;
            } else if (event.tipo == 'date') {
                this.columFiltro = 'col-md-4';
                this.columInput = 'col-md-4';
                this.showInputRango = true;
            } else if (event.tipo == 'select') {
                if (event.id == 'tipo') {
                    this.tiposelect = this.expedientesService.listarTipos();
                }
                if (event.id == 'subtipo') {
                    this.tiposelect = this.expedientesService.listarSubTipos();
                }
                if (event.id == 'estado') {
                    this.buscar.emit();
                }
                if (event.id === 'filtro_comite' && event.cambiar == undefined) {
                    this.selectChange({
                        id: 'filtro_comite',
                        text: 'Comite',
                        cambiar: true,
                        tipo: 'select',
                        opciones: [
                            {
                                id: true,
                                text: 'ACTIVO',
                            },
                            {
                                id: false,
                                text: 'INACTIVO',
                            },
                        ],
                        valor: '',
                        desde: '',
                        hasta: '',
                    });
                }
                if (this.bandera && event.id === 'estado') {
                    this.dataSelected = { id: '01', text: 'ACTIVO' };
                    this.bandera = false;
                } else {
                    this.dataSelected = { id: '', text: '' };
                }
                if (this.tiposelect) {
                    this.listSelect();
                }
                this.columFiltro = 'col-md-5';
                this.columInput = 'col-md-7';
                this.showInputRango = false;
            }
        }
    }

    dataSelectChange(event) {
        this.selected.valor = event.id;
        this.buscar.emit(this.selected);
    }

    listSelect() {
        this.tiposelect.subscribe(
            (response: any) => {
                // response
                this.dataSelect = response;
            },
            (error: any) => {}
        );
    }

    buscarText(event) {
        Observable.create((observer) => {
            this.searchText = observer;
        })
            .pipe(distinctUntilChanged())
            .subscribe((res: string) => {
                this.selected.valor = res;
                this.buscar.emit(this.selected);
            });
        this.searchText.next(event);
    }
    numeroInicio(event) {
        if (!this.searchNumberInicio) {
            Observable.create((observer) => {
                this.searchNumberInicio = observer;
            })
                .pipe(debounceTime(300))
                .pipe(distinctUntilChanged())
                .subscribe((res: string) => {
                    this.selected.desde = Number(res.replace(/,/gi, ''));
                    if (this.selected.hasta > 0) {
                        this.buscar.emit(this.selected);
                    }
                });
        }
        this.searchNumberInicio.next(event);
    }
    numeroFin(event) {
        if (!this.searchNumberFin) {
            Observable.create((observer) => {
                this.searchNumberFin = observer;
            })
                .pipe(debounceTime(300))
                .pipe(distinctUntilChanged())
                .subscribe((res: string) => {
                    this.selected.hasta = Number(res.replace(/,/gi, ''));
                    if (this.selected.desde) {
                        this.buscar.emit(this.selected);
                    }
                });
        }
        this.searchNumberFin.next(event);
    }
    fechaInicio(event) {
        this.selected.desde = moment(event.start, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.selected.hasta = moment(event.end, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.ragoFecha = `${this.selected.desde} / ${this.selected.hasta}`;
        if (this.selected.hasta != '' && this.selected.desde != '') {
            this.buscar.emit(this.selected);
        }
    }
    fechaFin(event) {
        this.selected.hasta = moment(event).format('YYYY-MM-DD');
        if (this.selected.desde != '') {
            this.buscar.emit(this.selected);
        }
    }
}
