import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { API_URL, SERVICIO_SEGURIDAD } from 'config/services';
import { Observable } from 'rxjs/internal/Observable';
// import { map } from 'rxjs/operator/map';
import { environment } from '@environments/environment';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import { dataSesion } from '../../services/sesion.service';
@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(private _http: HttpClient) {}
    logout() {
        localStorage.removeItem('data_user');
        // localStorage.clear();
    }
    isLogin() {
        if (this.getdataUser() != null && this.getdataUser().access_token) {
            return true;
        }
        return false;
    }
    login(data) {
        data.tipo_acceso = 'web';
        return this._http.post(SERVICIO_SEGURIDAD.login, data);
    }
    dataUser(data) {
        data.access_token = btoa(btoa(btoa(data.access_token)));
        localStorage.setItem('data_user', JSON.stringify(data));
    }
    getdataUser() {
        return JSON.parse(localStorage.getItem('data_user'));
    }
}

@Injectable({
    providedIn: 'root',
})
export class LoginService_v2 {
    constructor(private _http: HttpClient) {}
    logout(): Observable<boolean> {
        return new Observable((observer) => {
            dataSesion.id = 0;
            dataSesion.token = '';
            dataSesion.cargo = '';
            dataSesion.nombre = '';
            dataSesion.sucursal = '';
            dataSesion.perfil = '';
            dataSesion.codigoperfil = '';
            dataSesion.idperfil = 0;
            localStorage.removeItem('data_user');
            // localStorage.clear();
            observer.next(true);
            observer.complete();
        });
    }
    isLogin(): Observable<boolean> {
        return new Observable((observer) => {
            if (this.getdataUser() != null && this.getdataUser().access_token) {
                let userAux = this.getdataUser();

                dataSesion.id = userAux.datos;
                dataSesion.token = userAux.access_token;
                dataSesion.cargo = userAux.cargo;
                dataSesion.nombre = userAux.nombre;
                dataSesion.sucursal = userAux.sucursal;
                dataSesion.perfil = userAux.perfil[0].nombre;
                dataSesion.codigoperfil = userAux.perfil[0].codigo;
                dataSesion.idperfil = userAux.perfil[0].id;

                observer.next(true);
            }
            observer.next(false);
            observer.complete();
        });
    }
    login(data): Observable<boolean> {
        return new Observable((observer) => {
            data.tipo_acceso = 'web';
            return this._http.post(SERVICIO_SEGURIDAD.login, data).subscribe(
                (data: any) => {
                    dataSesion.id = data.datos;
                    dataSesion.token = data.access_token;
                    dataSesion.cargo = data.cargo;
                    dataSesion.nombre = data.nombre;
                    dataSesion.sucursal = data.sucursal;
                    dataSesion.perfil = data.perfil[0].nombre;
                    dataSesion.codigoperfil = data.perfil[0].codigo;
                    dataSesion.idperfil = data.perfil[0].id;
                    observer.next(data);
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    dataUser(data) {
        data.access_token = btoa(btoa(btoa(data.access_token)));
        localStorage.setItem('data_user', JSON.stringify(data));
    }
    getdataUser() {
        return JSON.parse(localStorage.getItem('data_user'));
    }
}

@Injectable({
    providedIn: 'root',
})
export class LoginService_v3 {
    constructor(private _http: HttpClient) {}

    // login_oauth(user: {email: string, password: string}) {
    //   return this._http.post<any>("http://localhost:5000/login", user)
    //     .pipe(
    //       tap(response => this.storeData(response))
    //     )
    // }

    // login_oauth(user: { usuario: string, password: string }): Observable<boolean> {
    //   let body = new URLSearchParams();
    //   body.set('client_id', environment.CLIENT_ID);
    //   body.set('client_secret', environment.CLIENT_SECRET);
    //   body.set('username', user.usuario);
    //   body.set('password', user.password);
    //   body.set('grant_type', 'password');
    //   const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    //   return this._http.post<any>(`${API_URL}session`, body.toString(), { headers: headers });
    // }

    login_oauth(token): Observable<boolean> {
        return new Observable((observer) => {
            const headers = { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + token.access };
            return this._http.get<any>(`${API_URL}session`, { headers: headers }).subscribe(
                (data: any) => {
                    dataSesion.id = data.datos;
                    dataSesion.token = data.access_token;
                    dataSesion.cargo = data.cargo;
                    dataSesion.nombre = data.nombre;
                    dataSesion.sucursal = data.sucursal;
                    dataSesion.perfil = data.perfil[0].nombre;
                    dataSesion.codigoperfil = data.perfil[0].codigo;
                    dataSesion.idperfil = data.perfil[0].id;
                    data.oauth_access_token = token.access;
                    data.oauth_refresh_token = token.refresh;
                    observer.next(data);
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    login(data) {
        data.tipo_acceso = 'web';
        return this._http.post(SERVICIO_SEGURIDAD.login, data);
    }
    token(data): Observable<boolean> {
        return new Observable((observer) => {
            let body = new URLSearchParams();
            body.set('email', data.email);
            body.set('password', data.password);
            body.set('recaptcha', data.recaptcha);
            const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
            return this._http.post(`${API_URL}token`, body.toString(), { headers: headers }).subscribe(
                (data: any) => {
                    localStorage.setItem('token_cobranza', data.access), observer.next(data);
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    logout() {
        let body = new URLSearchParams();
        body.set('client_id', environment.CLIENT_ID);
        body.set('client_secret', environment.CLIENT_SECRET);
        body.set('token', this.getRefreshToken());
        body.set('token_type_hint', 'refresh_token');
        const options = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: body.toString(),
        };
        return this._http.delete(`${environment.AUTH_URL}api/v1.0/token`, options).pipe(
            tap((resp) => {
                dataSesion.id = 0;
                dataSesion.token = '';
                dataSesion.cargo = '';
                dataSesion.nombre = '';
                dataSesion.sucursal = '';
                dataSesion.perfil = '';
                dataSesion.codigoperfil = '';
                dataSesion.idperfil = 0;
                localStorage.removeItem('data_user');
                localStorage.removeItem('idmenu');
                localStorage.removeItem('_grecaptcha');
                localStorage.removeItem('token_cobranza');
            })
        );
    }
    isLogin(): Observable<boolean> {
        return new Observable((observer) => {
            if (this.getdataUser() != null && this.getdataUser().access_token) {
                let userAux = this.getdataUser();

                dataSesion.id = userAux.datos;
                dataSesion.token = userAux.access_token;
                dataSesion.cargo = userAux.cargo;
                dataSesion.nombre = userAux.nombre;
                dataSesion.sucursal = userAux.sucursal;
                dataSesion.perfil = userAux.perfil[0].nombre;
                dataSesion.codigoperfil = userAux.perfil[0].codigo;
                dataSesion.idperfil = userAux.perfil[0].id;

                observer.next(true);
            }
            observer.next(false);
            observer.complete();
        });
    }

    dataUser(data) {
        data.access_token = btoa(btoa(btoa(data.access_token)));
        data.oauth_access_token = btoa(btoa(btoa(data.oauth_access_token)));
        data.oauth_refresh_token = btoa(btoa(btoa(data.oauth_refresh_token)));
        localStorage.setItem('data_user', JSON.stringify(data));
    }
    getdataUser() {
        return JSON.parse(localStorage.getItem('data_user'));
    }

    getTokenCobranza() {
        return localStorage.getItem('token_cobranza');
    }

    refreshToken() {
        let body = new URLSearchParams();
        body.set('client_id', environment.CLIENT_ID);
        body.set('client_secret', environment.CLIENT_SECRET);
        body.set('refresh_token', this.getRefreshToken());
        body.set('grant_type', 'refresh_token');
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return this._http.post<any>(`${environment.AUTH_URL}o/token/`, body.toString(), { headers: headers });
        // return this._http.get<any>(`${environment.AUTH_URL}token`)
        //   .pipe(tap((tokens: any) => {
        //     let data = this.getdataUser();
        //     data.access_token = tokens.access_token;
        //     this.dataUser(data);
        //   })
        // );
    }

    getToken() {
        if (this.getdataUser()) {
            return atob(atob(atob(this.getdataUser().access_token)));
        }
        return null;
    }

    getCobranza() {
        if (this.getdataUser()) {
            return this.getTokenCobranza();
        }
        return null;
    }

    getAccessToken() {
        if (this.getdataUser()) {
            return atob(atob(atob(this.getdataUser().oauth_access_token)));
        }
        return null;
    }

    getRefreshToken() {
        if (this.getdataUser()) {
            return atob(atob(atob(this.getdataUser().oauth_refresh_token)));
        }
        return null;
    }

    isLoggedIn() {
        return !!this.getAccessToken();
    }
}
