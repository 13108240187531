import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVICIO_EXPEDIENTES } from 'config/services/riesgos-admision';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SERVICIO_COTIZACIONES } from 'config/services/comercial';

@Injectable({
    providedIn: 'root'
})
export class CondicionesService {
    private _refresh$ = new Subject<void>();

    constructor(private _http: HttpClient) { }
    get refresh$() {
        return this._refresh$;
    }

    getCondicionesAnalista(idExpediente: number) {
        return this._http.get(SERVICIO_EXPEDIENTES["v1.0"].condicionesAnalista.listarCondiciones(idExpediente));
    }

    postCondicionesAnalista(idExpediente: number, body: any) {
        return this._http.post(SERVICIO_EXPEDIENTES["v1.0"].condicionesAnalista.guardarCondiciones(idExpediente), body)
            .pipe(
                tap(() => this._refresh$.next())
            );
    }

    postWarrantyDocuments(body: any) {
        return this._http.post(SERVICIO_EXPEDIENTES["v1.0"].condicionesAnalista.saveWarrantyDocuments, body);
    }

    obtenerDocumentosGenerales() {
        return this._http.get<any>(SERVICIO_COTIZACIONES["v1.1"].cotizaciones.subirArchivo).toPromise();
    }
}
