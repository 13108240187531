import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'config/services';
import { LoginService } from '../views/login/login.service';
import { SERVICIO_MODULO_COMERCIAL } from 'config/services';
import { map } from 'rxjs/internal/operators/map';
@Injectable({
    providedIn: 'root'
})
export class UtilService {
    constructor(
        private _http: HttpClient,
        private loginService: LoginService
    ) {
    }
    consultaSunat(ruc) {
        return this._http.get(API_URL + 'cliente/ruc/web/' + ruc);
    }
    consultaSunatProveedor(ruc,idmodelo : number = 0) {
        const data = {
            ruc : ruc, 
            idmodelo : idmodelo
        }
        return this._http.post( SERVICIO_MODULO_COMERCIAL.util.consultaRucProveedor, data);
    }
    uploadFile(url: string, formData, method: string = "POST") {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response)
                    }
                }
            }
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + atob(atob(atob(this.loginService.getdataUser().access_token))));
            xhr.send(formData);
        });
    }
    listarDepartamentos() {
        return this._http.get(SERVICIO_MODULO_COMERCIAL.util.listarDepartamentos);
    }
    listarProvincias(departamentoId: number = 0) {
        return this._http.get(SERVICIO_MODULO_COMERCIAL.util.listarProvincias + departamentoId);
    }
    listarDistrito(provinciaId: number = 0) {
        return this._http.get(SERVICIO_MODULO_COMERCIAL.util.listarDistritos + provinciaId);
    }
    listarSubNivel(idNivel) {
        let data = {
            idnivel: idNivel
        };
        return this._http.post<any>(API_URL + 'bien/sub_nivel', data).pipe(map((response: any) => {
            response.bien_sub_nivel.map(value => {
                value.text = value.descripcion;
                return value;
            });
            return response;
        }));
    }
    listarTipoBienMarcas() {
        return this._http.get<any>(API_URL + 'comercial/util/tipobien/marca');
    }
    listarGrupos() {
        return this._http.get<any>(API_URL + 'comercial/util/grupos');
    }
    listarPerfiles() {
        return this._http.get<any>(API_URL + 'perfiles/listar').pipe(map(response => {
            return response.perfiles.map(perfil => {
                perfil.text = perfil.decripcion;
                delete perfil.decripcion;
                return perfil;
            });
        }));

    }
    listarCargos(filtro = -1) {
        let params = {
            "cargo": filtro.toString()
        }
        return this._http.get(`${API_URL}util/cargos`, { params: params }).pipe(map((response: any) => response.data));
    }
    listarTipoGarantias() {
        return this._http.get<any>(`${API_URL}comercial/tipos_garantia`);
    }
    listarMoneda() {
        return this._http.get(SERVICIO_MODULO_COMERCIAL.util.listarMoneda);
    }

}
