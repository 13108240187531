import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusquedaAvanzadaComponent } from "./busqueda-avanzada.component";
import { DirectivasModule } from "@directives/directivas.module";
import { CommonModule } from "@angular/common";
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [BusquedaAvanzadaComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        DirectivasModule
    ],
    exports: [BusquedaAvanzadaComponent],

})

export class BusquedaAvanzadaModule {

}
