import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SpinnerServicesService {
    private isLoading = new BehaviorSubject<boolean>(false);
    private activeRequests = 0;

    constructor() {}
    onSpinner(): void {
        this.activeRequests++;
        this.isLoading.next(true);
    }

    offSpinner(): void {
        this.activeRequests--;
        if (this.activeRequests === 0) {
            this.isLoading.next(false);
        }
    }

    getLoadingStatus(): Observable<boolean> {
        return this.isLoading.asObservable();
    }
}
