import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { TabComponent } from './tab/tab.component';
import { OnChanges } from '@angular/core';
@Component({
  selector: 'common-tabs',
  templateUrl: './tabs.template.html',
  styleUrls: ['./tabs.scss']
})
export class TabsComponent implements AfterContentInit, OnChanges {

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  widthTabs: number = 100;
  widthTab: number = 100;
  leftTabs: number = 0;
  @Input() alinearTabs: string = 'left'; // center,left,right;
  @Input() tabIndex: number;
  @Output() seleccionado = new EventEmitter();
  constructor() {
    this.tabIndex = 0;
  }
  ngAfterContentInit() {
    this.widthTabs = this.tabs.length * 100;
    this.widthTab = 100 / this.tabs.length;
    setTimeout(() => {
      this.tabs.map((tab) => {
        tab.with = this.widthTab;
        return tab;
      });
    });

    this.selectTab(this.tabs['_results'][this.tabIndex], this.tabIndex);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.tabs != undefined && changes && changes.tabIndex && changes.tabIndex.currentValue) {
      let index = changes.tabIndex.currentValue;
      this.selectTab(this.tabs['_results'][index], index);
    }
  }
  alignTab(): string {
    let align = 'flex-start';
    if (this.alinearTabs === 'center') {
      align = 'center';
    } else if (this.alinearTabs === 'right') {
      align = 'flex-end';
    } else {
      align = 'flex-start';
    }
    return align;
  }
  selectTab(tab, index) {
    this.leftTabs = -1 * (index * 100);
    this.tabs.toArray().forEach(value => value.active = false);
    this.seleccionado.emit(index);
    tab.active = true;
  }
}
