import { NgModule } from "@angular/core";
import { TruncatePipe } from "./truncate.pepe";
import { CurrencyCustomPipe } from './simbolo-moneda.pipe';
import { ChecklistPipe, ChecklistPipe2 } from "./checklist.pipe";
import { SplitPipe } from "./split.pipe";

@NgModule({
    declarations: [
        TruncatePipe,
        CurrencyCustomPipe,
        ChecklistPipe2,
        ChecklistPipe,
        SplitPipe
    ],
    imports: [

    ],
    exports: [
        TruncatePipe,
        CurrencyCustomPipe,
        ChecklistPipe2,
        ChecklistPipe,
        SplitPipe
    ],
    providers: [
    ]
})

export class PipesModule { }
