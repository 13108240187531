import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService_v3 } from '../app/views/login/login.service';
import { SesionService } from '@service/sesion.service';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators/map';
import { MensajeResponse } from '@util/mensaje-response';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private _router: Router, private _login: LoginService_v3, private mensajeResponse: MensajeResponse, private sesion: SesionService) {}

    canActivate(router: ActivatedRouteSnapshot) {
        if (!this._login.isLoggedIn()) {
            this._router.navigate(['/login']);
        }
        const user: any = JSON.parse(localStorage.getItem('data_user'));
        const existe = user.modulos.find((x) => x.ruta === router.routeConfig.path);
        if (router.routeConfig.path === 'modulos' || router.routeConfig.path === 'mi-perfil' || router.routeConfig.path === 'login') {
            return this._login.isLoggedIn();
        }

        if (existe === undefined) {
            this.mensajeResponse.danger('Ok', 'Módulo no autorizado').subscribe();
            this._router.navigate(['/modules']);
        }
        return this._login.isLoggedIn();
    }
}

// @Injectable()
// export class AuthGuard implements CanActivate {
//     constructor(private _router: Router, private _login: LoginService) { }
//     canActivate(router: ActivatedRouteSnapshot): boolean {
//         if (this._login.isLogin()) {
//             return true;
//         }
//         this._router.navigate(['/login']);
//         return false;
//     }
// }
