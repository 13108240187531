import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'config/services';
import { map } from 'rxjs/internal/operators/map';
import { ChecklistDocumentos } from '@models/riesgos-admision/admision/admision-checklist-doumentos.model';
import { SERVICIO_EXPEDIENTES } from 'config/services/riesgos-admision';
@Injectable({
    providedIn: 'root'
})
export class ExpedienteDetalleDocumentosService {
    constructor(private _http: HttpClient) {
    }
    public getFiles(idExpediente, idOperacion) {
        return this._http.get<ChecklistDocumentos>(`${SERVICIO_EXPEDIENTES["v1.1"].porAprobasion.informeFinal.getFiles(idExpediente, idOperacion)}`);
    }
    listarDocumentos(idExpediente: number, idDocumento: number) {
        return this._http.get<ChecklistDocumentos>(`${API_URL}expediente/${idExpediente}/documento/${idDocumento}/checklist`).pipe(
            map((response: any) => {
                return response.data.map(data => {
                    return <ChecklistDocumentos>{
                        fechaDocumento: data.fecha_documento,
                        fechaModifico: data.fecha_modifico,
                        idarchivo: data.idarchivo,
                        nombre: data.nombre,
                        ruta_archivo: data.ruta_archivo,
                        usuarioModifico: data.usuario_modifico
                    };
                });
            })
        );
    }
    listarDocumentosGarantia(idExpediente: number, idDocumento: number, idGarantia: number) {
        return this._http.get<ChecklistDocumentos>(`${API_URL}expediente/${idExpediente}/documento/${idDocumento}/garantia/${idGarantia}/checklist`).pipe(
            map((response: any) => {
                return response.data.map(data => {
                    return <ChecklistDocumentos>{
                        fechaDocumento: data.fecha_documento,
                        fechaModifico: data.fecha_modifico,
                        idarchivo: data.idarchivo,
                        nombre: data.nombre,
                        ruta_archivo: data.ruta_archivo,
                        usuarioModifico: data.usuario_modifico
                    };
                });
            })
        );
    }
    listarDocumentosLegal(body) {
        return this._http.post(`${API_URL}legal/cafgenerado/checklist/mostrardocumento`, body).pipe(
            map((response: any) => {
                return response.data.map(data => {
                    return {
                        fecha_documento: data.fecha_documento,
                        fecha_modifico: data.fecha_documento,
                        idarchivo: data.iddoc,
                        nombre: data.nombre_doc,
                        ruta_archivo: data.ruta_completa,
                        usuario_modifico: data.usuario_modifico
                    };
                });
            })
        );;
    }
    eliminarDocumentos(idarchivo: number) {
        return this._http.delete(`${API_URL}archivo/expediente/${idarchivo}/checklist`);
    }
}
