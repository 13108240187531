import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'config/services';
import { map } from 'rxjs/internal/operators/map';
import { CheckListEstados } from '@util/estados-checklist';
@Injectable({
    providedIn: 'root'
})
export class ExpedienteHistorialDocumentosService {
    @Output() actualizarData: EventEmitter<any> = new EventEmitter();
    constructor(private _http: HttpClient) {
    }
    historial(idExpediente: number) {
        return this._http.get<any>(`${API_URL}expediente/${idExpediente}/comentarios/operaciones/`).pipe(map(resp => {
            let data = resp.data ? resp.data : [];
            let comentario_estado = resp.comentario_estado ? resp.comentario_estado : [];

            // let data_comentareios = CheckListEstados.rechazado == resp.descripcion_estado ? false: true;

            return {
                data: data,
                moneda: resp.moneda,
                monedaOrigen: resp.moneda_origen,
                // data_comentareios: data_comentareios,
                comentario_estado: comentario_estado,
            };
        }));
    }
    historialDocumentos(idexpediente: number, idtipodocumento: number, numfilas: number = 10, pagina: number = 1) {
        let data = {
            idexpediente: idexpediente,
            idtipodocumento: idtipodocumento,
            numfilas: numfilas,
            pagina: pagina
        };
        return this._http.post<any>(`${API_URL}expediente/historial/checklist/`, data);
    }
    actualizarComentarios() {
        this.actualizarData.emit();
    }

}
